import React, { Component } from 'react'
import '../css/Admin_css/admin-customize.css';
import { Redirect, Route,match } from 'react-router-dom';
import axios from 'axios';
import SessionManager from '../Utils/SessionManager';
import MyLoader from '../Spinner/MyLoader';
import myToast from '../Utils/MyToast';
export default class UpdateStudentDetails extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
    this.state={
        loggedIn:false,
        Loading:false,
        FirstName:'',
        LastName:'',
        dob:'',
        gen:'',
        cat:'',
        stRegion:'',
        AdharNo:'',
        nationality:'',
        curntAddress:'',
        perAddress:'',
        mSTUDetails:[]
    }
    this.regSubmit= this.regSubmit.bind(this);
    this.regChange= this.regChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.ongenChange = this.ongenChange.bind(this);
}
componentDidMount()
{
  var mSTDetails=JSON.parse(localStorage.getItem("STDetails"));
  //alert("heloo"+JSON.stringify(mSTDetails));
  if(mSTDetails==null){
    mSTDetails=[];
  } 
  this.state.mSTUDetails=mSTDetails;
  //alert(JSON.stringify(this.state.mSTUDetails));
  this.setStuDetails();
}
setStuDetails=()=>{
  var name=this.state.mSTUDetails[0].stu_name;
  var nm= name.split(" ");
 this.state.FirstName=nm[0];
 this.state.LastName=nm[1];
 this.state.nationality=this.state.mSTUDetails[0].nationality;
 this.state.AdharNo=this.state.mSTUDetails[0].adharcard_no;
 this.state.stRegion=this.state.mSTUDetails[0].religion;
 this.state.curntAddress=this.state.mSTUDetails[0].current_address;
 this.state.perAddress=this.state.mSTUDetails[0].permanent_address;
 this.state.dob=this.state.mSTUDetails[0].birthday;
 this.state.gen=this.state.mSTUDetails[0].gender;
 this.state.cat=this.state.mSTUDetails[0].community;
 this.setState({

 })

}
regChange(e){
  this.setState({
    [e.target.id]:e.target.value
})

}
onValueChange=(event)=> {
  event.preventDefault();
  var upcat=event.target.value;
  this.setState({
  cat:upcat
   });

}

ongenChange=(event)=> {
  event.preventDefault();
  var Gen=event.target.value;
  this.setState({
  gen:Gen
   });
alert(this.state.gen);
}

copytopermAddr = e => {
  var val = e.target.checked;
  if (val==true) {
    this.setState({
      perAddress: this.state.curntAddress
    });
  }else{
    this.setState({
      perAddress: this.state.perAddress
      
    }); 
  }
};
regSubmit(){

 
  if(this.isValidInput())
  {
    this.setState({ 
      Loading:true
     })
  
  const Enroll_no="S000111";
  const Staff_id="STF000211";
  const FUNCTION_NAME='updateStudentPersonalDetails.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  
  axios.get(URL, {
    params: {
      enrollment_no:Enroll_no,
      staff_id:Staff_id,
      student_name:this.state.FirstName + this.state.LastName,
        dob:this.state.dob,
        religion:this.state.stRegion,
        gender:this.state.gen,
        community:this.state.cat,
        aadhar_no:this.state.AdharNo,
        nationality:this.state.nationality,
        curAddress:this.state.curntAddress,
        perAddress:this.state.perAddress
    }

  }).then(res => res.data)
  .then((response) => {
    this.setState({ 
       Loading:false
      })
      let api_status=response.api_status;
      //console.log("responsel>>>>>"+response);


    console.log("response>>>>>"+api_status)
   })
}
}  

isValidInput()
{
   
  if (this.state.FirstName==="")
    {
    alert('Your name have accepted Only Alphabate!');
    return false;
    }
  else if(this.state.LastName==="")
  {
    alert("Enter Last name have accepted Only Alphabate!");
    return false;
  }
  else if(this.state.dob==="")
  {
    alert("DOB have accepted Only Numeric!");
    return false;
  }
  else
   if(this.state.gender==="")
  {
    alert("Must have choose one option!");
    return false;
  }
  else if(this.state.community==="")
  {
    alert("Enter Last name have accepted Only Alphabate!");
    return false;
  }
  else
  // var phoneno = /^\d{10}$/ .value.match(phoneno);
  var rej=/^\d{14}$/;
   if(rej.test(this.state.AdharNo))
  {
    alert("Adharcard have accepted Only 14 digits !");
    return false;
  }
  else if(this.state.LastName==="")
  {
    alert("Enter Last name have accepted Only Alphabate!");
    return false;
  }
  else{
    return true;
  }

}
        render() {

 if(this.state.Loading)
{
   return <MyLoader/>
}
else{
            return (
            <div className="content">
                <div className="content-header">
                  <div className="leftside-content-header">
                    <ul className="breadcrumbs">
                      <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Update Student Details</a></li>
                    </ul>
                  </div>
                </div>
                <div className="row animated fadeInUp">
                  <div className="col-sm-12 col-lg-12">
                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <div className="panel">
                          <div className="panel-content text-center" style={{ minHeight: '400px' }}>
        
                            <div className="panel-body">
                              <div className="pnlSubHedingBorder">
                                <div className="pnlSubHeding heading-with-bg-w">
                                  Basic Information
                                </div>
                              </div>
                              <div style={{ float: 'left', width: '100%' }}>
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">
                                    <label className="col-md-2  col-form-label">  First Name</label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input type="text" id="FirstName" name="first name" value={this.state.FirstName} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Last Name</label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="LastName" name="Last name" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Date of Birth</label>
                                    <div className="col-md-4">
                                      <div className="input-group date" id="stDateofBirth">
                                        <input type="date" id="dob" name="dob" value={this.state.dob} onChange={this.regChange} className="form-control" />
                                        <div className="input-group-addon">
                                          <i className="fa fa-calendar" />
                                        </div>
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Gender</label>
                                    <div className="col-md-4">
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline" id="inlineCssRadio1" value="Male" checked={this.state.gen === "Male"} onChange={this.ongenChange} />
                                        <label htmlFor="inlineCssRadio1">Male</label>
                                      </div>
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline" id="inlineCssRadio2" value="Female" checked={this.state.gen === "Female"} onChange={this.ongenChange} />
                                        <label htmlFor="inlineCssRadio2">Female</label>
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Community</label>
                                    <div className="col-md-4">
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline1" id="SCST" value="SCST" checked={this.state.cat === "SCST"} onChange={this.onValueChange} />
                                        <label htmlFor="inlineCssRadio3">SC/ST</label>
                                      </div>
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline1" id="OBC" value="OBC" checked={this.state.cat === "OBC"} onChange={this.onValueChange} />
                                        <label htmlFor="inlineCssRadio4">OBC</label>
                                      </div>
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline1" id="General" value="General" checked={this.state.cat === "General"} onChange={this.onValueChange} />
                                        <label htmlFor="inlineCssRadio5">General</label>
                                      </div>
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline1" id="Others" value="Others" checked={this.state.cat === "Others"} onChange={this.onValueChange} />
                                        <label htmlFor="inlineCssRadio6">Others</label>
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Religion</label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" className="form-control" value={this.state.stRegion} onChange={this.regChange} id="stRegion"  />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Adhar Number</label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input id="AdharNo" type="text" value={this.state.AdharNo} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Nationality</label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="nationality" name="nationality" value={this.state.nationality} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                              <div style={{ float: 'left', width: '100%' }}>
                              <div className="pnlSubHedingBorder headMarginTop">
                                <div className="pnlSubHeding heading-with-bg-w">
                                  Current Address
                              </div>
                              </div>
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">
                                    <label className="col-md-2  col-form-label">  Current Address </label>
                                    <div className="col-md-10 ">
                                      <div className="input-group">
                                        <input id="curntAddress" value={this.state.curntAddress} onChange={this.regChange} type="text" className="form-control" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pnlSubHedingBorder headMarginTop">
                                <div className="pnlSubHeding heading-with-bg-w">
                                  Permanent Address
                                   </div>
                                   </div>
                                <div style={{ marginTop: '-8px', float: 'left', marginLeft: '80px', paddingTop: '4px' }}> <b>Same As Current Address </b></div>
                                <div style={{ marginTop: '-8px', float: 'left', marginLeft: '8px' }}>
                                  <div className="switcher">
                                    <input type="checkbox" name="switcher_checkbox_1" id="switcher_checkbox_1" onChange={this.regChange} defaultChecked={this.state.checked} onClick={this.copytopermAddr} />
                                    <label htmlFor="switcher_checkbox_1" />
                                  </div>
                                </div>
                              
                              <div style={{ float: 'left', width: '100%' }}>
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">
                                    <label className="col-md-2  col-form-label"> Permanent Address </label>
                                    <div className="col-md-10 ">
                                      <div className="input-group">
                                        <input id="perAddress" type="text" value={this.state.perAddress} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                              <div style={{ float: 'left', width: '100%' }}>
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">

                                    <div className="col-md-12">
                                      <center>
                                        <button className="btn btn-success" onClick={this.regSubmit} style={{marginRight:'1%'}}> Update </button>
                                       
                                      </center>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    );
    }
  }
}
