import React, { Component ,createRef} from 'react'

import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {Link, Redirect } from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';

export default class MdlSelectMultipleStudent extends Component {
    constructor(props){
        super(props)       
        this.slt_board=createRef();
        this.slt_medium=createRef();
        this.slt_class=createRef();
        this.slt_section=createRef();
        
       
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
      
        this.state={   
            loggedIn:true,
            Loading:false, 
            LoadingStudent:false,
            show:true,
            mClassData:[],
            mClassBoard:[],
            mClassMedium:[], 
            mFilteredClassList:[],
            mFilteredSectionList:[],
            CLASS_ID:'',
            SECTION_ID:'',
            StudentList:[],
            SelectedClass:'',
            SelectedStudentCount:''
        }
      
}
componentDidMount(){
  var mSelectedStudent=[];
  localStorage.setItem("SelectedMultipleStudent",JSON.stringify(mSelectedStudent));
  
  this.getClass();
}

HandleCloseSelectStudent = () => {

    this.setState({
      show: !this.state.show
    });
  this.props.action();
    
  }
  
  HandleSelectedStudent = () => {
    

  this.props.action2();
  
  }
  getSelectedStudent = (e,postion) => {
    
    let isChecked = e.target.checked;
    let student_id=this.state.StudentList[postion].student_enrollment_no;

    var mSelectedStudent=JSON.parse(localStorage.getItem("SelectedMultipleStudent"));
    if(mSelectedStudent==null){
      mSelectedStudent=[];
    }
  
    //alert("okk"+student_id+"..."+isChecked);
    if(isChecked)
    {
        let item=this.state.StudentList[postion];
        mSelectedStudent.push(item);
        localStorage.setItem("SelectedMultipleStudent",JSON.stringify(mSelectedStudent));
    
    }
    else{
      
      let item_index=this.getItemPostion(student_id,mSelectedStudent);
      mSelectedStudent.splice(item_index,1);
      localStorage.setItem("SelectedMultipleStudent",JSON.stringify(mSelectedStudent));
    
    }
    this.setState({
      SelectedStudentCount:mSelectedStudent.length,
    })
    
     
  }
  getItemPostion(student_id,mSelectedStudent){
    
    for (let index = 0; index < mSelectedStudent.length; index++) {
      const element = mSelectedStudent[index];
      if(student_id===element.student_enrollment_no)
      {
        return index;
      }
      
    }
    return -1;
  }

  onBoardChange = () => {
    //let board_id=this.slt_board.current.selectedOptions[0].getAttribute('board_id');
    //alert(board_id);
    this.getClassList();
    
  }
  onMediumChange = () => {
    this.getClassList();
  }
  onClassChange = () => {
    this.getClassSectionList();
  }
  onSectionChange = () => {
    this.state.CLASS_ID=this.slt_class.current.selectedOptions[0].getAttribute('class_id');;
    this.state.SECTION_ID=this.slt_section.current.selectedOptions[0].getAttribute('section_id');;
    //alert(this.state.CLASS_ID);
    //alert(this.state.SECTION_ID);
    this.getStudentList();
  
  }
  getClassSectionList(){
    let class_id=this.slt_class.current.selectedOptions[0].getAttribute('class_id');
    
        this.state.mFilteredSectionList=[];
         let section =null;
          for (var i=0;i<this.state.mFilteredClassList.length;i++)
          {
              let item=this.state.mFilteredClassList[i];
              if(item.class_id===class_id)
              {
                  section=item.section;
  
              }
          }
         
          if(section!=='No')
          {
              this.setState({
                mFilteredSectionList:section
              });
              this.state.CLASS_ID=class_id;
              this.state.SECTION_ID=section[0].section_id;
             
              this.getStudentList();
          }
          else {
              this.state.CLASS_ID=class_id;
              this.state.SECTION_ID="No";
              this.setState({       
     
              })
              this.getStudentList();
          }
  
  }
  
  
  getClassList()
  {
    let board_id=this.slt_board.current.selectedOptions[0].getAttribute('board_id');
    let medium_id=this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');
    //alert(board_id);
    //alert(medium_id);
    
    this.state.mFilteredClassSectionList=[];
    this.state.mFilteredClassList=[];
    for (var i=0; i < this.state.mClassData.length; i++) {
      let item=this.state.mClassData[i];
      if(item.board_id===board_id && item.medium_id===medium_id)
      {
      
        this.state.mFilteredClassList.push(item);
  
      }   
    }
    this.setState({       
     
    })
    
    this.getClassSectionList();
    
  }    
  
  getClass(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  var mSession=SessionManager.getCurrentSession();
  const SESSION_ID=mSession[0].session_id;
  //alert(SESSION_ID);
  
  const FUNCTION_NAME='getClass.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{school_code:SCHOOL_CODE,
      session_id:SESSION_ID
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
      //alert(api_status);
      
      if(api_status=="OK")
      {
      
         this.setState({       
          mClassData:response.response.class,
          mClassBoard:response.response.board,
          mClassMedium:response.response.medium
          })
          this.getClassList();
          //console.log("response>>>>>"+this.state.mClassData);
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      } 
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }  
  getStudentList(){
    
    this.setState({
    LoadingStudent:true,
    StudentList:[]
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  var mSession=SessionManager.getCurrentSession();
  const SESSION_ID=mSession[0].session_id;
  
  const FUNCTION_NAME='getCurrentStudent.php?'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  
  axios.get(URL,{
    params:{school_code:SCHOOL_CODE,
      class_id:this.state.CLASS_ID,
      section_id:this.state.SECTION_ID,
      session_id:SESSION_ID
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      LoadingStudent:false
     })
      console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
     // alert(api_status);
      
      if(api_status=="OK")
      {
       
         this.setState({
          
          StudentList:response.student
  
          })
         // alert(JSON.stringify(this.state.StudentList));
             
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }

    render() {
        
        if( this.state.loggedIn==false)
        {
        return <Redirect to='/Login'/>;
        }
       
        else{
        return (               
              <div>
                
              <Modal show={this.state.show} onHide={this.HandleCloseSelectStudent} animation={false} style={{opacity:1} }
       size="lg"
       aria-labelledby="contained-modal-title-vcenter"
       centered
     >
       <Modal.Header closeButton>
         <Modal.Title id="contained-modal-title-vcenter">
           Select Student
         </Modal.Title>
       </Modal.Header>
       <Modal.Body>
       {this.state.Loading ? <MyLoader/> : 
          <div className="row class-li">
 
                  <form className="row" style={{width:'100%'}}>
                     <div className="form-group col-sm-6">
 
                     <select className="form-control" onChange={this.onBoardChange} ref = {this.slt_board}>
                 {this.state.mClassBoard.map((item, index) => ( 
                    <option board_id={item.board_id}>{item.board_name}</option>
                    
                    ))}
                    </select>
 
                     </div>
                     <div className="form-group col-sm-6">
 
                   <select className="form-control" onChange={this.onMediumChange} ref = {this.slt_medium}>
                    {this.state.mClassMedium.map((item, index) => ( 
                    <option medium_id={item.medium_id}>{item.medium_name}</option>
                    ))}
                    </select>
 
                     </div>
                     <div className="form-group col-sm-6">
 
                       <select className="form-control" onChange={this.onClassChange} ref = {this.slt_class}>
                       {this.state.mFilteredClassList.map((item, index) => ( 
                       <option class_id={item.class_id}>{item.class_name}</option>
                       ))}
                       </select>
 
                     </div>
                     <div className="form-group col-sm-6">

                       <select className="form-control"   onChange={this.onSectionChange} ref = {this.slt_section}>
                       {this.state.mFilteredSectionList.map((item, index) => ( 
                       <option section_id={item.section_id}>{item.section_name}</option>
                       ))}
                       </select>
                     </div>
                     
                  </form>
                  <div className="col-sm-12 col-md-12 bg-primary">
                        <div className="text-center">
                          {/* <!-- Button trigger modal --> */}
                          <h2 className="en-adhar text-white"> Selected Students: {this.state.SelectedStudentCount}  &nbsp;&nbsp;&nbsp;&nbsp; &#8649; </h2>
                        </div>
                      </div>
               {this.state.LoadingStudent?<div><MyLoader/></div>:
               
               this.state.StudentList.map((item, index) => ( 
                                            
                <div className="col-sm-6 col-md-6 p-0">
                <div className="row bg_card_blue_outline" style={{margin:'5px 15px 10px 15px',height:'90px'}}>
                 <div className="col-sm-3  bg_card_blue gravity_center" style={{padding:'1px',height:'85px'}}>
                   <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'80px',width:'80px'}}>
                   <div className="circular_image">
                      <img  src={item.student_img_path}  alt=""></img>
                      <p className="student_id gravity_center"> <small> {item.student_enrollment_no} </small></p>
                   </div>  
                   </div>               
                 </div>
                 <div className="col-sm-6" style={{padding:'5px 10px',height:'85px'}}>
                  <h5> {item.stu_name} </h5>
                  <p className="mb-0"> Father: <span>{item.father_name}</span> </p>
                  <p className="mb-0"> Board: <span>{item.class[0].board_name}</span> </p>
                  <p className="mb-0"> Medium: <span>{item.class[0].medium_name}</span> </p>
                 
                 </div>
                 <div className="col-sm-3 padding_0 gravity_center" style={{height:'85px'}}>
                  <div className="text-center bg_card_blue_outline width_fill_parent" style={{margin:'2px'}}>
                    <p className="mb-0"> Roll No:{item.class[0].student_roll_no} </p>
                    

                    <p> <div className="st-list-roll-n" style={{
                              background: '#fff', width: '35px', height: '35px', padding: '2px', borderRadius: '50%',
                              margin: '2px auto', fontSize: '20px'
                             }}> 
                             <input type="checkbox" id="issue_notice_c" class="ch-in" value="" onChange={e=>this.getSelectedStudent(e,index)}/></div>
                    </p>

                    <h6><span> {item.class[0].class_name} </span>  </h6>
                  </div>
                 </div>
              
                </div>
              
                </div>   
                                   
                 ))}
 
                      <div className="col-sm-12 text-center" style={{margin:'15px auto'}} onClick={this.HandleSelectedStudent}>
                            <h3 style={{ background: '#129cd9', color:'#fff', padding:'7px'}}> Done </h3>
                      </div>
                       
        
              </div>
       }
                        
       </Modal.Body>
     </Modal>
             </div>
      
      )
    }
    }
}
