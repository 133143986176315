import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import eduok from '../resources/images/EduOk.png'
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import Iframe from 'react-iframe'
import ReactDOM from 'react-dom'
export default class ExamStudentReportCard extends Component {
 
     
  constructor(props){
            super(props)
        
            const token=localStorage.getItem("token");
            let loggedIn=true;
            if(token==null){
              loggedIn=false;
            }
        
            this.state={
        
                loggedIn:true,
                Loading:false, 
                Total:0,
                mschoolData:[], 
                mStudentdata:[],
                mpaymentPeriod:[],
                mbusPoint:[],
                mStudentdataClass:[],
                mpaymentData:[],
                iFrameHeight:'0px'

            }
            
          
        }
        componentDidMount()
        {
          
          this.getFeeRecieptData();
        }
        getFeeRecieptData(){
          this.setState({
            Loading:true
          })
          
          const SCHOOL_CODE=SessionManager.getSchoolCode();
          const Transaction_id=this.props.match.params.transaction_id;
          const Session_id=this.props.match.params.session_id;
          const Enrollment_no=this.props.match.params.student_id;
          const FUNCTION_NAME='FeeReceipt/getFeeRecieptData.php?';
          URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR_PRINT_DOCS+FUNCTION_NAME;
         // alert(URL);
          console.log("URL>>>>>"+URL);

          axios.get(URL,{
            params:{school_code:SCHOOL_CODE,
              session_id:Session_id,
              enrollment_no:Enrollment_no,
              transaction_id:Transaction_id
            }
          }).then(res => res.data)
          .then((response) => {
           
            this.setState({ 
              Loading:false
             })
              console.log("Response2>>>>>"+response);
              let api_status=response.api_status;
              //alert(JSON.stringify(response));
             
              if(api_status=="OK")
              {
              
                 this.setState({
                    mschoolData:response.data.schoolData,
                    mStudentdata:response.data.Studentdata,
                    mStudentdataClass:response.data.Studentdata[0].class,
                    mpaymentPeriod:response.data.paymentPeriod,
                    mpaymentData:response.data.paymentData,
                    mbusPoint:response.data
                  })
                //alert("mschoolData>>>>>"+JSON.stringify(this.state.mschoolData));
              }
              else{
                let api_remark=response.api_remark;
                myToast.notify_warning(api_remark);
              }
           }).catch((error)=>{
            this.setState({ 
              Loading:false
             })
            console.log("error>>>>>"+error);
            });
        
          }
          autoResize=()=>{
              alert("loaded");
              var iFrame = document.getElementById('iFrame1');
             
             
          }
        
          render() {
            if( this.state.loggedIn==false)
            {
             return <Redirect to='/Login'/>;
            }
            if(this.state.Loading)
            {
               return <MyLoader/>
            }
            else{
            return ( 
              
        
          <div className="content">
              <div className="content-header" style={{background:COLORS.primary_color_shade5}}>
                <div className="leftside-content-header">
                  <ul className="breadcrumbs">
                    <li><i className="fa fa-home" aria-hidden="true" style={{color:COLORS.white}} /><a href="#">Exam Report Card </a></li>
                  </ul>
                </div>
              </div>  
              
          <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>
            
              <div className="row" style={{margin:'10px'}}>
                                           
                <Iframe url="http://beta.pizero.piradius.online/PiZeroEduOkDocs/PrintableDocs/ver1/FeeReceipt/h_fee_receipt.php?&enrollment_no=S001511&session_id=SSN0002&transaction_id=TXN08287&school_code=11"
                        width="100%"
                        height="800px"
                        overflow="visible"
                        id="iFrame1"
                        className="myClassname"
                        display="initial"
                        position="relative"
                        onLoad={() => {
                         
                           //this.autoResize();
                      }} 
                      />
                        
               
              </div> 
          </div>
               
         </div>
  
  )
}    
}
 
}