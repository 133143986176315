import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';

import COLORS from '../resources/colors/COLORS';
import icon_edit from '../resources/icons/icons_edit_pencile_100_white.png'

import ExamPaperSettings from './ExamPaperSettings';
import ExamClassResultStatus from './ExamClassResultStatus';
import ExamDetails from './ExamDetails';
import ExamStudentReportCard from './ExamStudentReportCard';
export default class ExamReportCardList extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
  
        loggedIn:true,
        Loading:false, 
        mExamReportCardList:[],
       

    }
  
  }
  componentDidMount()
  {
      window.scrollTo(0, 0);
      this.getExamReportCardList();
  }
  getExamReportCardList(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getSchoolRepordCardList.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{school_code:SCHOOL_CODE}
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      let api_status=response.api_status;
      // alert(JSON.stringify(response));
      
      if(api_status=="OK")
      {
      
         this.setState({
          
          mExamReportCardList:response.data
          })
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
 
  render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{ 
        
        return (             
        <Router>
        <switch>
          <Route path={'/AdminMain/ExamReportCardList'}  exact render={
            ()=>{
              
         return (
       
          <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Exam Reportcard List</a></li>
              </ul>
            </div>
        
          </div>    
        
          <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>
      
          <div className="row">
          {this.state.mExamReportCardList && this.state.mExamReportCardList.map((item, index) => { 
                                           
            return( 

             <div className="col-sm-6 col-md-6">
              <div style={{margin:'10px'}}>
              
                <div className="bg_card_blue_outline">
                  
                
                  <div className="row">
                  
                    <div className="col-sm-10 col-md-10 gravity_center">
                      <p style={{fontSize:'18px'}}>{item.report_card_name}</p>
                    </div>
                    <div className="col-sm-2 col-md-2 gravity_center">
                       <div className="gravity_center" style={{padding:'0px',margin:'2px',height:'25px',width:'25px'}}>
                          <div className="circular_image_custom" style={{'--myFillColor':COLORS.primary_color_shade3,'--myBorderColor':COLORS.primary_color_shade5,'--myBorder':'1px'}}>
                              <img  src={icon_edit}  alt="" style={{width:'100%'}}></img>
                             
                          </div> 
                        </div>  
                       
                    </div>

                  </div>
                  
                
                  <hr></hr>
                
                   <div className='row viewShow'>
                          <div className="col-sm-12 col-md-12 ">
                            <Link to={'/AdminMain/ExamStudentReportCard/'+item.report_card_id+"/"+item.session_id+"/"+item.report_card_name}>
                              <button className="btn btn-success gravity_center bg_card_blue" style={{width:'98%',height:'30px',fontSize:'16px', margin:'0px 3px 3px 3px'}}>Generate Report Card For Student </button>
                            </Link>                
                          </div>    
                   </div>
                   

                </div>
              </div>

            </div>
              )
            })}
          </div>                             
                                
      </div>


     </div>
           
           )
          }
          } />
            <Route path='/AdminMain/ExamStudentReportCard/:report_card_id/:session_id/:report_card_name'  component={ExamStudentReportCard} />
            
                 
    </switch>       
   </Router>  
    )
  }
 }
 }