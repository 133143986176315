import React, { Component } from 'react'

const COLORS = {

    primary_color_shade0:'#E1F5FE',
    primary_color_shade1:'#B3E5FC',
    primary_color_shade2:'#81D4FA',
    primary_color_shade3:'#4FC3F7',
    primary_color_shade4:'#29B6F6',
    primary_color_shade5:'#03A9F4',
    primary_color_shade6:'#039BE5',
    primary_color_shade7:'#0288D1',
    primary_color_shade8:'#0277BD',
    primary_color_shade9:'#01579B',

    
    //BottomNavigation_highlight_color">@color/amber4</color>
    highlight_color1:'#FE488B',
    highlight_color2:'#FFF307',
    highlight_color3:'#0765D6',

    card_orange:'#E07A2F',
    card_green:'#89BE4C',
    card_blue:'#39AFD5',
    card_deep_purple:'#7e57c2',
    card_voilet:'#702b56',
    card_red:'#ef5350',
    card_magenta:'#CD014D',
    card_cyan_green:'#02BBA1',
    card_amber:'#FFB300',

    green_dark:'#33b536',
    pesto_green:'#00AA8D',
    pesto_green_lighter:'#00BF9A',
    pesto_green_darker:'#008975',
    leaf_green:'#0C7300',
    white:'#FFFFFF',
    whitesmoke:'#F5F5F5',
    black:'#000',
    blue:'#0765D6',
    voilet:'#702b56',
    holiday:'#FFA000',
    transparent:'#FFffffff',

    disalbed:'#F5F5F5',
    background_grey:'#E0E0E0',
    background_grey_dark:'#BDBDBD',
    grey0:'#FAFAFA',
    grey1:'#F5F5F5',
    grey2:'#EEEEEE',
    grey3:'#E0E0E0',
    grey4:'#BDBDBD',
    grey5:'#9E9E9E',
    grey_light:'#dad9de',
    green_light:'#e4f9e4',

    black9:'#191919',
    black8:'#333333',
    black7:'#4c4c4c',
    black6:'#666666',
    black5:'#7f7f7f',
    black4:'#999999',
    black3:'#b2b2b2',
    black2:'#cccccc',
    black1:'#e5e5e5',

    amber0:'#FFF8E1',
    amber1:'#FFECB3',
    amber2:'#FFE082',
    amber3:'#FFD54F',
    amber4:'#FFCA28',
    amber5:'#FFC107',
    amber6:'#FFB300',
    amber7:'#FFA000',
    amber8:'#FF8F00',
    amber9:'#FF6F00',

    green0:'#AAFD86',
    green1:'#71FC36',
    green2:'#45DD03',
    green3:'#38B503',
    green4:'#2C8D02',
    green5:'#257502',
    green6:'#1F6402',
    green7:'#195001',
    green8:'#133C01',
    green9:'#0C2801',

    Deep_Purple0:'#ede7f6',
    Deep_Purple1:'#d1c4e9',
    Deep_Purple2:'#b39ddb',
    Deep_Purple3:'#9575cd',
    Deep_Purple4:'#7e57c2',
    Deep_Purple5:'#673ab7',
    Deep_Purple6:'#5e35b1',
    Deep_Purple7:'#512da8',
    Deep_Purple8:'#4527a0',
    Deep_Purple9:'#311b92',

    radish0:'#FDEDED',
    radish1:'#FBDADC',
    radish2:'#F6B6B8',
    radish3:'#F4A4A6',
    radish4:'#F07F83',
    radish5:'#EE6E73',
    radish6:'#EA484E',
    radish7:'#E5242A',
    radish8:'#C9181D',
    radish9:'#A41318',
    radish10:'#800F13',

   red0:'#ffebee',
   red1:'#ffcdd2',
   red2:'#ef9a9a',
   red3:'#e57373',
   red4:'#ef5350',
   red5:'#f44336',
   red6:'#e53935',
   red7:'#d32f2f',
   red8:'#c62828',
   red9:'#b71c1c',


    navy_blue0:'#97ADD8',
    navy_blue1:'#7995CD',
    navy_blue2:'#5B7EC2',
    navy_blue3:'#4268B3',
    navy_blue4:'#375695',
    navy_blue5:'#2A4272',
    navy_blue6:'#273C68',
    navy_blue7:'#213459',
    navy_blue8:'#1C2B4A',
    navy_blue9:'#16233C',


    light_blue0:'#E1F5FE',
    light_blue1:'#B3E5FC',
    light_blue2:'#81D4FA',
    light_blue3:'#4FC3F7',
    light_blue4:'#29B6F6',
    light_blue5:'#03A9F4',
    light_blue6:'#039BE5',
    light_blue7:'#0288D1',
    light_blue8:'#0277BD',
    light_blue9:'#01579B',

   
    // your colors
    









  }
  
export default COLORS;