import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class LeaveManagement extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
  
        loggedIn:true,
        Loading:false, 
        mLeaveStudent:[]
    }
  
  }
  componentDidMount()
  {
  this.LeaveStudent();
  }
  LeaveStudent(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const Session_Id="SSN0001";
  const FUNCTION_NAME='getStudentLeave.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{school_code:SCHOOL_CODE,session_id:Session_Id}
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
     // alert(api_status);
      
      if(api_status=="OK")
      {
      
         this.setState({
          
          mLeaveStudent:response.leave
          })
          console.log("response>>>>>"+JSON.stringify(this.state.mLeaveStudent));
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{
      return (
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Leave Applications (Student) </a></li>
              </ul>
            </div>
          </div> 
          <div className="row pendding-top-row">
                <div className="col-sm-4 col-md-4">
                <div className="exam-p-setting-sub cur"> <p> Pending </p></div>
                </div>
                <div className="col-sm-4 col-md-4">
                <div className="exam-p-setting-sub cur"> <p> Approved </p></div>
                </div>
                <div className="col-sm-4 col-md-4">
                <div className="exam-p-setting-sub cur"> <p> Rejected </p></div>
                </div>
                </div> 
                {this.state.mLeaveStudent.map((item, index) => (      
              <div className="row">
              <div className="col-sm-12 col-md-12 card-class-w class-list-box" style={{margin:'0px',border:'double #fff',background:'#fff'}}>
              <div className="row">
              <div className="col-sm-10 col-md-10 bor-r-leave">
              <h4 className="bor-b-leave">To: <span className="text-medium text-info"> {item.authority} </span></h4>
              <h5 className="bor-b-leave">Subject:<span className="text-medium text-success"> {item.subject}</span></h5>
              <h5 className="text-medium text-info"><span><strong> From:</strong>{item.leave_start_date}</span> <span className="pull-right"><strong> To:</strong> {item.leave_end_date}</span></h5>
              </div>
              <div className="col-sm-2 col-md-2 text-center">
              <div className="pendding"> <p><i class="fa fa-street-view"></i></p></div>
              <h5> {item.status_name} </h5>
              </div>
              <div className="col-sm-12 col-md-12 bor-t-leave">
                <p> {item.application_content} </p>
              </div>
              </div>
              </div>
              <div className="col-sm-12 col-md-12 bg-info">
                  <h4> Applicant Details </h4>
              <div className="row">
              <div className="col-sm-3 col-md-3 card-class-w class-list-box" style={{margin:'0px',border:'double #fff'}}>
               <div className="replayer"><img src="http://beta.pizero.piradius.online/PiZeroEduOkDocs/images/student/SC_11/SSN0001/CLS001/S00811_gungunraaz.jpg" alt="student list image"/> </div>
               </div>
               <div className="col-sm-9 col-md-9 text-center class-list-box" style={{margin:'0px'}}>
               <h5 className="text-success"> {item.applicant.stu_name} </h5>
               <hr/>
               <h6> Nursery: <span> B2 </span></h6>
               <h6> 17-02-2020 </h6>
                </div>
                </div>
              </div>
                </div>
                ))}
               </div>
  
  )
} 
}
}
