import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import ExamDateSheet from './ExamDateSheet';
import ExamAdmitCard from './ExamAdmitCard';
import ExamHallTicket from './ExamHallTicket';
import ExamGuideline from './ExamGuideline';
import ExamMarkingSystem from './ExamMarkingSystem';
export default class ExamDetails extends Component {
    constructor(props){
        super(props)
      const token=localStorage.getItem("token");
      let loggedIn=true;
      if(token==null){
        loggedIn=false;
      }
    
      this.state={
    
          loggedIn:true,
          Loading:false, 
          EXAM_ID:'',
          SESSION_ID:'',
          EXAM_NAME:'',
          START_DATE:'',
          EXAM_STATUS:'',
              
      }

    }
    componentDidMount()
    {
        this.state.EXAM_ID=this.props.match.params.exam_id;
        this.state.SESSION_ID=this.props.match.params.session_id;
      
        this.state.EXAM_NAME=this.props.match.params.exam_name;
        this.state.START_DATE=this.props.match.params.exam_date;
        this.state.EXAM_STATUS=this.props.match.params.exam_status;

        this.setState({
          
        })

        //alert( this.state.EXAM_ID);
       
    }
    

      render() {
        if( this.state.loggedIn==false)
        {
         return <Redirect to='/Login'/>;
        }
        if(this.state.Loading)
        {
           return <MyLoader/>
        }else{

            return (             
                <Router>
                <switch>
                  <Route path={'/AdminMain/ExamDetails/:exam_id/:session_id/:exam_name/:exam_date/:exam_status'}  exact render={
                    ()=>{
                      
                 return (
                <div className="content">
                    <div className="content-header">
                            <div className="leftside-content-header">
                            <ul className="breadcrumbs">
                                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Exam Details</a></li>
                            </ul>
                            </div>
                    </div>  
                    <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>     
                    <div>Quick Links</div>
                    <div className="row bg_card_blue_outline">
                    <div className="col-sm-2 col-md-2">
                            <Link to={{pathname:'/AdminMain/ExamDateSheet/'+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+this.state.EXAM_NAME+"/"+this.state.START_DATE}}>
                                <div  style={{ margin: '5px 5px', padding: '0px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'90px',width:'90px'}}>
                                          <div className="circular_image_custom gravity_center" style={{'--myFillColor':COLORS.light_blue4,'--myBorderColor':COLORS.light_blue2,'--myBorder':'3px'}}>
                                            <p className="text-center" style={{color:COLORS.white,fontSize:'18px'}}>Date<br/>Sheet</p>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                   
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-2 col-md-2">
                            <Link to={{pathname:'/AdminMain/ExamGuideline/'+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+this.state.EXAM_NAME+"/"+this.state.START_DATE}}>
                            <div  style={{ margin: '5px 5px', padding: '0px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'90px',width:'90px'}}>
                                          <div className="circular_image_custom gravity_center" style={{'--myFillColor':COLORS.light_blue4,'--myBorderColor':COLORS.light_blue2,'--myBorder':'3px'}}>
                                            <p className="text-center" style={{color:COLORS.white,fontSize:'16px'}}>Exam<br/>Guideline</p>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                   
                                </div>
                            </Link>
                        </div>
                          
                         <div className="col-sm-2 col-md-2">
                            <Link to={{pathname:'/AdminMain/ExamHallTicket/'+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+this.state.EXAM_NAME+"/"+this.state.START_DATE+"/"+this.state.EXAM_STATUS}}>
                            <div  style={{ margin: '5px 5px', padding: '0px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'90px',width:'90px'}}>
                                          <div className="circular_image_custom gravity_center" style={{'--myFillColor':COLORS.light_blue4,'--myBorderColor':COLORS.light_blue2,'--myBorder':'3px'}}>
                                            <p className="text-center" style={{color:COLORS.white,fontSize:'18px'}}>Hall<br/>Ticket</p>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                   
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-2 col-md-2">
                            <Link to={{pathname:'/AdminMain/ExamAdmitCard/'+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+this.state.EXAM_NAME+"/"+this.state.START_DATE+"/"+this.state.EXAM_STATUS}}>
                            <div  style={{ margin: '5px 5px', padding: '0px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'90px',width:'90px'}}>
                                          <div className="circular_image_custom gravity_center" style={{'--myFillColor':COLORS.Deep_Purple4,'--myBorderColor':COLORS.Deep_Purple2,'--myBorder':'3px'}}>
                                            <p className="text-center" style={{color:COLORS.white,fontSize:'18px'}}>Admit<br/>Card</p>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                   
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-2 col-md-2">
                            <Link to={{pathname:'/AdminMain/ExamResult'}}>
                            <div  style={{ margin: '5px 5px', padding: '0px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'90px',width:'90px'}}>
                                          <div className="circular_image_custom gravity_center" style={{'--myFillColor':COLORS.Deep_Purple4,'--myBorderColor':COLORS.Deep_Purple2,'--myBorder':'3px'}}>
                                            <p className="text-center" style={{color:COLORS.white,fontSize:'18px'}}>Exam<br/>Result</p>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                   
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-2 col-md-2">
                            <Link to={{pathname:'/AdminMain/ExamMarkingSystem/'+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+this.state.EXAM_NAME+"/"+this.state.START_DATE+"/"+this.state.EXAM_STATUS}}>
                            <div  style={{ margin: '5px 5px', padding: '0px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'90px',width:'90px'}}>
                                          <div className="circular_image_custom gravity_center" style={{'--myFillColor':COLORS.Deep_Purple4,'--myBorderColor':COLORS.Deep_Purple2,'--myBorder':'3px'}}>
                                            <p className="text-center" style={{color:COLORS.white,fontSize:'18px'}}>Report<br/>Card</p>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                   
                                </div>
                            </Link>
                        </div>
                          
                    </div>
                    </div> 
            
               </div>
  
            )
          }
          } />
            <Route path='/AdminMain/ExamDateSheet/:exam_id/:session_id/:exam_name/:exam_date'  component={ExamDateSheet} />
            <Route path='/AdminMain/ExamGuideline/:exam_id/:session_id/:exam_name/:exam_date'  component={ExamGuideline} />
            <Route path='/AdminMain/ExamAdmitCard/:exam_id/:session_id/:exam_name/:exam_date/:exam_status'  component={ExamAdmitCard} />
            <Route path='/AdminMain/ExamHallTicket/:exam_id/:session_id/:exam_name/:exam_date/:exam_status'  component={ExamHallTicket} />
            <Route path='/AdminMain/ExamMarkingSystem/:exam_id/:session_id/:exam_name/:exam_date/:exam_status'  component={ExamMarkingSystem} />
           
                 
    </switch>       
   </Router>  
    )
  }
 }
 }