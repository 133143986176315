import React, { Component } from 'react'
import { Redirect,Link } from 'react-router-dom'
import { Alert } from 'react-bootstrap';
import {Button} from 'react-bootstrap'
import SessionManager from '../Utils/SessionManager';

import MyLoader from '../Spinner/MyLoader';
import axios from 'axios';
import ModalFeeConfirmation from '../dialog/ModalFeeConfirmation';
import COLORS from '../resources/colors/COLORS';
import ModalSettlementConfirmation from '../dialog/ModalSettlementConfirmation';
import myToast from '../Utils/MyToast';
export default class DueFeeCollection extends Component {
    constructor(props) {
        super(props)        
    
        this.state={  
            isNoRecord: false, 
            isNoDue: false, 
            showFeeConfirmation:false,
            showSettlementConfirmation:false,
            mStudentFeeDetails:[],
            mStudentDueFee:[],
            mStudentDetails:[],
            subtotal:0,
            netPayableAmount:0,  
            FEE_ID:'',
            FEE_APPLICABLE_MONTH:'',
            DUE_AMOUNT:0


        }  

        this.HandleCloseModalFeeConfirmation = this.HandleCloseModalFeeConfirmation.bind(this);
        this.submitFeeDetails = this.submitFeeDetails.bind(this);
        this.CloseModalSettlementConfirmation = this.CloseModalSettlementConfirmation.bind(this);
        this.submitSettlementRequest = this.submitSettlementRequest.bind(this);

      }
      
  HandleShowModalFeeConfirmation = () => {
    this.state.netPayableAmount=0;
    this.setState({
      showFeeConfirmation: !this.state.showFeeConfirmation
    });   
  }
  HandleCloseModalFeeConfirmation = () => {
    this.state.netPayableAmount=0;
    this.setState({
      showFeeConfirmation:false
    });  
     
  }
  ShowModalSettlemetConfirmation = (item,item2) => {
     this.state.FEE_ID=item2.fee_id;
     this.state.FEE_APPLICABLE_MONTH=item.month;
     this.state.DUE_AMOUNT=item2.outstanding;
    
    this.state.netPayableAmount=0;
    this.setState({
      showSettlementConfirmation: !this.state.showSettlementConfirmation
    });   
  }
  CloseModalSettlementConfirmation = () => {
    this.state.netPayableAmount=0;
    this.setState({
        showSettlementConfirmation:false
    });  
     
  }
      componentDidMount(){
        if(this.props.FeeDetails!==null)
        {            
            this.state.mStudentFeeDetails=this.props.FeeDetails;
            this.state.mStudentDueFee=this.props.FeeDetails;
            this.state.mStudentDetails=this.props.mStudentDetails;
        }
          //alert(JSON.stringify(this.state.mStudentFeeDetails));
          if(this.state.mStudentFeeDetails.length===0)
          {
              this.setState({
                  isNoRecord:true,
              })
          }
          else{
              this.getDueFee();
          }
          

      }
    getDueFee(){
     for(var i=(this.state.mStudentDueFee.length-1);i>=0;i--)
        {
            var TAG_DUE="Due";
            var TAG_NOT_PAID="Not Paid";
            var FLAG_REMOVE=false;
            var mApplicableFee=[];
            mApplicableFee=this.state.mStudentDueFee[i].applicable_fee;
            //alert(i+JSON.stringify(mApplicableFee));
            for(var j=mApplicableFee.length-1;j>=0;j--)
            {
            
                if(!(mApplicableFee[j].payment_status===TAG_DUE) && !(mApplicableFee[j].payment_status===TAG_NOT_PAID))
                {      
                    mApplicableFee.splice(j, 1);
                    FLAG_REMOVE=true;
                }

            }
            
            if(FLAG_REMOVE)
            {
                
                if(mApplicableFee.length!==0)
                {
                    let newArray = [...this.state.mStudentDueFee];
                    newArray[i]['applicable_fee'] =mApplicableFee;
                    this.setState({mStudentDueFee: newArray});
                }
                else {
                   // this.state.mStudentDueFee = this.state.mStudentDueFee.filter((_, index) => index !== i);
                   this.state.mStudentDueFee.splice(i, 1);
                   let newArray = [...this.state.mStudentDueFee];
                   this.setState({mStudentDueFee: newArray});
                    
                }

            }
                    }
        if(this.state.mStudentDueFee.length===0)
        {
                this.setState({
                    isNoDue:true,
                })
        }
        else{
            this.initDueData(); 
        }
        
    }
    initDueData(){
        for(var i=this.state.mStudentDueFee.length-1;i>=0;i--)
           {
               var mApplicableFee=[];
               this.state.mStudentDueFee[i]['isChecked']="true";
               mApplicableFee=this.state.mStudentDueFee[i].applicable_fee;
    
               for(var j=mApplicableFee.length-1;j>=0;j--)
               {
                   mApplicableFee[j]["isChecked"]="true";
                   mApplicableFee[j]["payable_amount"]=mApplicableFee[j].outstanding;
                   
                
               }
               //let newArray = [...this.state.mStudentDueFee];
               //newArray[i]['applicable_fee'] =mApplicableFee;
               //this.setState({mStudentDueFee: newArray});
               this.state.mStudentDueFee[i]['applicable_fee']=mApplicableFee;             
   
           }  
           this.setState({
                   
          })
    
       }
    
    selectCollectionMonth(pIndex){
        
        if(this.state.mStudentDueFee[pIndex].isChecked==='true')
        {
            var mApplicableFee=[];
            this.state.mStudentDueFee[pIndex]['isChecked']="false";
            mApplicableFee=this.state.mStudentDueFee[pIndex].applicable_fee;
 
            for(var j=mApplicableFee.length-1;j>=0;j--)
            {
                mApplicableFee[j]["isChecked"]="false";
               
            }
            let newArray = [...this.state.mStudentDueFee];
            newArray[pIndex]['applicable_fee'] =mApplicableFee;
            this.setState({mStudentDueFee: newArray});

        }
        else{
            var mApplicableFee=[];
            this.state.mStudentDueFee[pIndex]['isChecked']="true";
            mApplicableFee=this.state.mStudentDueFee[pIndex].applicable_fee;
 
            for(var j=mApplicableFee.length-1;j>=0;j--)
            {
                mApplicableFee[j]["isChecked"]="true";
               
            }
            let newArray = [...this.state.mStudentDueFee];
            newArray[pIndex]['applicable_fee'] =mApplicableFee;
            this.setState({mStudentDueFee: newArray});
        }
        this.setState({
            netPayableAmount:0

        })

        
    }

    selectFeeName(pIndex,cIndex){
       
        var mApplicableFee=[];
        mApplicableFee=this.state.mStudentDueFee[pIndex].applicable_fee;
        if(mApplicableFee[cIndex].isChecked==="true")
        {
            mApplicableFee[cIndex]["isChecked"]="false";
            if(!this.isAnyFeeSelected(mApplicableFee))
            {
                this.state.mStudentDueFee[pIndex]['isChecked']="false";
            }

            let newArray = [...this.state.mStudentDueFee];
            newArray[pIndex]['applicable_fee'] =mApplicableFee;
            this.setState({mStudentDueFee: newArray});

        }
        else{
            mApplicableFee[cIndex]["isChecked"]="true";
            this.state.mStudentDueFee[pIndex]['isChecked']="true";

            let newArray = [...this.state.mStudentDueFee];
            newArray[pIndex]['applicable_fee'] =mApplicableFee;
            this.setState({mStudentDueFee: newArray});
    
        }
        this.setState({
            netPayableAmount:0

        })
        
    
    }
    isAnyFeeSelected(mApplicableFee){

            for(var j=mApplicableFee.length-1;j>=0;j--)
            {
                if(mApplicableFee[j]["isChecked"]==="true")
                {
                    return true;

                }
               
            }
            return false;

    }
    getSubtotal(pIndex)
    {
        
        var amt=0;
        var mApplicableFee=[];
        mApplicableFee=this.state.mStudentDueFee[pIndex].applicable_fee;
        
    
        for(var i=0;i<mApplicableFee.length;i++)
        {

            if(mApplicableFee[i].isChecked==="true")
            {
                amt=amt+Number(mApplicableFee[i].outstanding);
            }
        } 
        return amt;
    
    }
    

    submitFeeDetails = () => {
        
    this.setState({
        showFeeConfirmation:false,
        Loading:true
      }); 
  
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    
    var STUDENT_ID=this.state.mStudentDetails[0].student_enrollment_no;
    var mStaffData=SessionManager.getStaffDetails();
    var STAFF_ID=mStaffData.staff_id;
    var CLASS_ID=this.state.mStudentDetails[0].class[0].class_id;
    var SECTION_ID=this.state.mStudentDetails[0].class[0].section_id;
    const SESSION_ID=this.state.mStudentDetails[0].class[0].session_id;

    var arrTemp = [...this.state.mStudentDueFee];
    for (let index = 0; index < arrTemp.length; index++) {
        let element = arrTemp[index];
        arrTemp[index]['applicable_fee']=JSON.stringify(element.applicable_fee);
        
    }
    const STR_FEE_DETAILS=JSON.stringify(arrTemp);
    var REMARK="";
    var AMOUNT=this.state.netPayableAmount;

   // alert(STR_FEE_DETAILS);
    
    const FUNCTION_NAME='submitFeeDetails.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    
    var params = new URLSearchParams(); 
    params.append('student_id', STUDENT_ID);
    params.append('class_id', CLASS_ID);
    params.append('section_id',SECTION_ID);
    params.append('session_id',SESSION_ID);
    params.append('staff_id',STAFF_ID);
    params.append('fee_details',STR_FEE_DETAILS);
    params.append('remark',REMARK);
    params.append('amount',AMOUNT);
    params.append('school_code',SCHOOL_CODE);

 axios.post(URL,params).then(res => res.data)
.then((response) => {
        let api_status=response.api_status;
        //alert(api_status);       
        if(api_status=="OK")
        {
            alert("Submitted Successfully ");
            this.props.action(true);
                 
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
          this.props.action(false);
         
        } 
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }

    
    submitSettlementRequest = () => {
        
        this.setState({
            showSettlementConfirmation:false
        }); 
      
        const SCHOOL_CODE=SessionManager.getSchoolCode();
        
        var STUDENT_ID=this.state.mStudentDetails[0].student_enrollment_no;
        var mStaffData=SessionManager.getStaffDetails();
        var STAFF_ID=mStaffData.staff_id;
        var CLASS_ID=this.state.mStudentDetails[0].class[0].class_id;
        var SECTION_ID=this.state.mStudentDetails[0].class[0].section_id;
        const SESSION_ID=this.state.mStudentDetails[0].class[0].session_id;
    
        var DUE_PAYMENT_STATUS="2";     
        const FUNCTION_NAME='AddDueSettlementRequest.php'
        URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
        console.log("URL>>>>>"+URL)
        
        this.setState({
          Loading:true
        })
        
        
        axios.get(URL,{
          params:{student_id:STUDENT_ID,
            class_id:CLASS_ID,
            section_id:SECTION_ID,
            session_id:SESSION_ID,
            sender_id:STAFF_ID,
            fee_id:this.state.FEE_ID,
            applicable_month:this.state.FEE_APPLICABLE_MONTH,
            due_amount:this.state.DUE_AMOUNT,
            due_payment_status:DUE_PAYMENT_STATUS,
            school_code:SCHOOL_CODE
          }
        }).then(res => res.data)
        .then((response) => {
            let api_status=response.api_status;
            //alert(JSON.stringify(response));       
            if(api_status=="OK")
            {
                alert("Submitted Successfully ");
                this.props.action2();
                     
            }
            else{
              let api_remark=response.api_remark;
              myToast.notify_warning(api_remark);
              this.props.action2();
             
            } 
         }).catch((error)=>{
          this.setState({ 
            Loading:false
           })
          console.log("error>>>>>"+error);
          });
        }

    render() {
        if(this.state.Loading)
        {
            return <MyLoader/>
        }
        else{

        if(this.state.isNoRecord)
        {
            return(
                <div>
                    <h1>No Fee Record Found!!!</h1>
                </div>
            )
        }
        else if(this.state.isNoDue){
            return(
                <div>
                    <h1>No Fee Due !!!</h1>
                </div>
            )
        }
        else{       
        return (           
            <div className="row">
                
                  <div className="col-sm-12 col-lg-12 text-center">
                      <h3 className="outstanding-bg"><Link to="#" className="a-decoration-none"> Outstanding  Fee </Link>  </h3>
                  </div>
                          
                  <div className="width_match_parent" style={{padding: '0px 20px'}}>
                      <div className="row" style={{boxShadow:'1px 1px 6px #c0b7b7',padding:'8px'}}>
                                               
                         {this.state.mStudentDueFee && 
                           this.state.mStudentDueFee.map((item, index) => {
                             
                             this.state.netPayableAmount=this.state.netPayableAmount+this.getSubtotal(index);
                             var mFeeName=[];
                             mFeeName=item.applicable_fee;
                             
                            return( 

                           <div className="col-sm-12 bg_card_grey_outline" style={{margin:'5px 0px',padding:'0px'}}>

                            <div className="row bg_card_grey_tltr"> 
                                <div className="col-sm-2" onClick={()=>this.selectCollectionMonth(index)} style={{paddingTop:'8px'}}>
                                <input type="checkbox" checked={item.isChecked==='true'?true:false} style={{transform:'scale(2)'}}/>
                                </div>
                                <div className="col-sm-10"><p style={{fontSize:'17px'}}><strong> {item.month2} </strong></p></div>
                            </div>
                            
                            {mFeeName && mFeeName.map((item2, index2) => {
                            
                            return( 
                            <div className="row" style={{border:'1px solid #d7d2d2'}}>
                                <div className="col-sm-2" onClick={()=>this.selectFeeName(index,index2)} style={{paddingTop:'8px'}}>
                                <input type="checkbox" checked={item2.isChecked==='true'?true:false} style={{transform:'scale(2)'}}/>
                                </div>
                                <div className="col-sm-5"><p style={{fontSize:'16px'}}>{item2.fee_name}</p></div>
                                <div className="col-sm-2"><p style={{fontSize:'16px'}}> Rs. <span>{item2.outstanding}</span></p> </div>
                                <div className="col-sm-3"><p style={{fontSize:'16px'}}>{item2.payment_status}</p></div>
                                <div className={item2.payment_status=='Due'? "viewShow col-sm-12":"viewHide col-sm-12"} >
                                    <div className="col-sm-12 gravity_center">                                               
                                    <h4 style={{color:COLORS.card_blue}}>Due Found In</h4>
                                    </div>
                                    <div className="col-sm-4 gravity_center">                                               
                                    <h5 style={{color:COLORS.card_red}}>{item2.fee_name}</h5>
                                    </div>
                                    <div className="col-sm-4 gravity_center">                                               
                                    <h5 style={{color:COLORS.card_red}}>{"Rs:"+item2.outstanding}</h5>
                                    </div>
                                    <div className="col-sm-4 gravity_center">                                               
                                     <Button variant="outline-danger" style={{ padding: '5px', margin:'0px 5px 5px 5px',fontSize:'20px'}} onClick={()=>this.ShowModalSettlemetConfirmation(item,item2)}>Settlement Request</Button>
                                    </div>
                                </div>
                            </div>
                                )
                            })
                            }
                            <div className="row bg_card_grey " > 
                                  
                                  <div className="col-sm-12 gravity_center_right">
                                    <p style={{fontSize:'17px'}}><strong>Subtotal: &nbsp; <span>Rs. {this.getSubtotal(index)}</span></strong></p>
                                  </div>
                            </div>
                              
                            </div>
                                  
                          );
                     })}
                          
                      </div>
                  </div>
                  <div className="col-sm-12 col-lg-12 text-center">
                  <Link to={{pathname:'/AdminMain/FeeOtherAmount', data: {mDueData:this.state.mStudentDueFee,OtherAmount:this.state.netPayableAmount,StudentDetails:this.state.mStudentDetails}}}>
                   <Button variant="secondary" style={{ padding: '5px', margin:'0px',width:'99%',fontSize:'20px'}} >Other Amount</Button>
                 </Link>

                  </div>
                  <div className="col-sm-12 col-lg-12 text-center mt-3">
                  <Button style={{ padding: '5px', margin:'0px',width:'99%',fontSize:'20px'}} onClick={this.HandleShowModalFeeConfirmation}>Collect Now(Rs:{this.state.netPayableAmount})</Button>
                  </div>
                   {this.state.showFeeConfirmation===true? <ModalFeeConfirmation action={this.HandleCloseModalFeeConfirmation} action2={this.submitFeeDetails} netPayableAmount={this.state.netPayableAmount}></ModalFeeConfirmation>:''}
                   {this.state.showSettlementConfirmation===true? <ModalSettlementConfirmation action={this.CloseModalSettlementConfirmation} action2={this.submitSettlementRequest} netPayableAmount={this.state.DUE_AMOUNT}></ModalSettlementConfirmation>:''}
          
            </div>
           )
        }
     }
    }
}
