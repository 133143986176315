import React, {  Component ,useRef, createRef} from 'react'
import '../css/Admin_css/admin-customize.css';
import { BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import axios from 'axios'
import SessionManager from '../Utils/SessionManager'
import MyLoader from '../Spinner/MyLoader';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import StRegSuccessfull from './StRegSuccessfull';
import myToast from '../Utils/MyToast';

export default class StudentRegistration extends Component {
  constructor(props){
    super(props)
    this.slt_board=createRef();
    this.slt_medium=createRef();
    this.slt_Class=createRef();
    this.slt_Section=createRef();
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
    this.state={
        loggedIn:false,
        Loading:false,
        mClassBoard:[],
        mClassMedium:[],
        mClassData:[], 
        AddmisType:'',
        AddmissionNumber:'1',
        FirstName:'',
        LastName:'',
        dob:'',
        date:'',
        gender:'',       
        community:'',
        stRegion:'',
        AdharNo:'',
        nationality:'',
        board_d:'',
        medium_id:'',
       
        curntVillage:'',
        crntPost:'',
        crntDistrict:'',
        crntState:'',
        crntPincode:'',
        perVillage:'',
        perPost:'',
        perDistrict:'',
        perState:'',
        perPincode:'',
        FatherName:'',
        fatherOccupation:'',
        motherName:"",
        motherOccupation:'',
        contact1:'',
        contactName_1:'',
        relation_1:'',
        contact2:'',
        contactName_2:'',
        relation_2:'',
        email:'',
        mFilteredClassSectionList:[],
        mFilteredSectionList:[],
        CLASS_ID:'',
        SECTION_ID:''
            
    }
    this.regSubmit= this.regSubmit.bind(this);
    this.regChange= this.regChange.bind(this);
}

handleChangeAdmissionType = event => {
  console.log("adm>>>>>>>>>>>>",event.target.value);
  this.setState({
    
    AddmisType:event.target.value,
  })

  /* alert(this.state.AddmisType);
 */ 
};
handleChangeGender = event => {
  this.setState({
    
    gender:event.target.value,
  })
};


onBoardChange = (event) => { 
  this.getClassList();
};

onMediumChange=(event)=>{
  this.getClassList();
}

OnClassChange=(event)=>{
    this.getClassSectionList();
  }
OnSectionChange=(event)=>{
  this.state.CLASS_ID=this.slt_Class.current.selectedOptions[0].getAttribute('class_id');
  this.state.SECTION_ID=this.slt_Section.current.selectedOptions[0].getAttribute('section_id');
 
}
getClassSectionList(){
  let class_id=this.slt_Class.current.selectedOptions[0].getAttribute('class_id');
  
      this.state.mFilteredSectionList=[];
       let section =null;
        for (var i=0;i<this.state.mFilteredClassSectionList.length;i++)
        {
            let item=this.state.mFilteredClassSectionList[i];
            if(item.class_id===class_id)
            {
                section=item.section;

            }
        }
       
        if(section!=='No')
        {
            this.setState({
              mFilteredSectionList:section
            });
            this.state.CLASS_ID=class_id;
            this.state.SECTION_ID=section[0].section_id;
           
           
        }
        else {
            this.state.CLASS_ID=class_id;
            this.state.SECTION_ID="No";
            this.setState({       
   
            })
            
        }

}

getClassList()
{
  let board_id=this.slt_board.current.selectedOptions[0].getAttribute('board_id');
  let medium_id=this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');
  //alert(board_id);
  //alert(medium_id);
  //filtering class of selected board and medium
  
  this.state.mFilteredClassSectionList=[];
  for (var i=0; i < this.state.mClassData.length; i++) {
    let item=this.state.mClassData[i];
    if(item.board_id===board_id && item.medium_id===medium_id)
    {
    
      this.state.mFilteredClassSectionList.push(item);

    }   
  }

  this.setState({       
   
  })
  this.getClassSectionList();


}
componentDidMount()
{
  this.getClass();
  this.getAdmissionNo();
}


getClass(){

  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  var mSession=SessionManager.getCurrentSession();
   const SESSION_ID=mSession[0].session_id;

  
  const FUNCTION_NAME='getClass.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{
      school_code:SCHOOL_CODE,
      session_id:SESSION_ID
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
     // alert(api_status);
      
      if(api_status=="OK")
      {
      
         this.setState({       
          mClassData:response.response.class,
          mClassBoard:response.response.board,
          mClassMedium:response.response.medium
          })
          this.getClassList();
          //console.log("response>>>>>"+this.state.mClassData);
      }
      else{
        let api_remark=response.api_remark;
        //myToast.notify_warning(api_remark);
      } 
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }


getAdmissionNo(){

    this.setState({
      Loading:true
    })
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    const FUNCTION_NAME='getLastAdmissionNo.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    axios.get(URL,{
      params:{
        school_code:SCHOOL_CODE,
        
      }
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        Loading:false
       })
        
        let api_status=response.api_status;
        //alert(JSON.stringify(response));
        
        if(api_status=="OK")
        {
        
          if(response.data.length!==0)
          {
            
            var sr_admission_no=response.data[0].sr_admission_no;
            
            var temp =  sr_admission_no.match(/[a-z]+|[^a-z]+/gi);
            
            if(temp.length===1)
            {
              this.state.AddmissionNumber=parseInt(temp[0])+1;
            }
            else{
              if(isFinite(temp[0]))
              {
                this.state.AddmissionNumber=(parseInt(temp[0])+1)+temp[1];
              }
              else{
                this.state.AddmissionNumber=temp[0]+(parseInt(temp[1])+1);
              }
              
            }
          }
          else{
              this.state.AddmissionNumber=1;
          }
          //alert(this.state.AddmissionNumber);
           this.setState({       
            
            })
            
        }
        else{
          let api_remark=response.api_remark;
          //myToast.notify_warning(api_remark);
        } 
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }  

regChange(e){

  this.setState({
    [e.target.id]:e.target.value
})
}
copytopermAddr = e => {
  let val = e.target.checked;
  if (val) {
    this.setState({
      perVillage: this.state.curntVillage,
      perPost: this.state.crntPost,
      perDistrict: this.state.crntDistrict,
      perState: this.state.crntState,
      perPincode: this.state.crntPincode
    });
  }else{
    this.setState({
      perVillage: this.state.perVillage,
      perPost: this.state.perPost,
      perDistrict: this.state.perDistrict,
      perState: this.state.perState,
      perPincode: this.state.perPincode
    }); 
  }
};
regSubmit(){
  //alert(this.state.AddmisType);
  if(this.isValidInput())
  {

    
  this.state.board_id=this.slt_board.current.selectedOptions[0].getAttribute('board_id');
  this.state.medium_id=this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');
  var bb=this.state.AddmisTypeN + this.state.AddmisTypeR;
  
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  var mSession=SessionManager.getCurrentSession();
  const SESSION_ID=mSession[0].session_id;
  var mStaffData=SessionManager.getStaffDetails();
  var STAFF_ID=mStaffData.staff_id;
   //alert(bb);
  const ACADEMIC_MODE="ACDM01";
  const FUNCTION_NAME='registerStudent.php?'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL, {
    params: {
        school_code:SCHOOL_CODE,
        session_id:SESSION_ID,
        student_name:this.state.FirstName +" "+this.state.LastName,
        dob:this.state.dob,
        admDate:this.state.date,
        admission_type:this.state.AddmisType,
        admission_no:this.state.AddmissionNumber,
        academic_mode :ACADEMIC_MODE,
        religion:this.state.stRegion,
        gender:this.state.gender,
        community:this.state.community,
        aadhar_no:this.state.AdharNo,
        nationality:this.state.nationality,
        board_id:this.state.board_id,
        medium_id:this.state.medium_id,
        class_id:this.state.CLASS_ID,
        section_id:this.state.SECTION_ID,
        curAddress:"Village : "+this.state.curntVillage+" Post : " + this.state.crntPost+" District : " + this.state.crntDistrict+" State : " + this.state.crntState+" Pincode : " + this.state.crntPincode,
        perAddress:"Village : "+this.state.perVillage+" Post : " + this.state.perPost+" District : " + this.state.perDistrict+" State : " + this.state.perState+" Pincode : " + this.state.perPincode,
       
        father_name:this.state.FatherName,
        father_occupation:this.state.fatherOccupation,
        father_qualification:this.state.father_qualification,
        father_aadhar:this.state.father_aadhar,

        mother_name:this.state.motherName,
        mother_occupation:this.state.motherOccupation,
        mother_qualification:this.state.mother_qualification,
        mother_aadhar:this.state.mother_aadhar,

        contact1:this.state.contact1,
        contact_person_name1:this.state.contactName_1,
        contact_person1:this.state.relation_1,

        contact2:this.state.contact2,
        contact_person_name2:this.state.contactName_2,
        contact_person2:this.state.relation_2,
        email:this.state.email,
        caste:this.state.caste,
        staff_id:STAFF_ID,
    }

  }).then(res => res.data)
  .then((response) => {
    this.setState({ 
       Loading:false
      })
      let api_status=response.api_status;
      //console.log("responsel>>>>>"+response);
      if(api_status=="OK")
      {
        var enrollment_no=response.enrollment_no;
        alert("successfully Registered !" );
        window.location.reload();
        window.location="/AdminMain/StudentRegSuccess/"+enrollment_no+"/"+SESSION_ID;
 
      }
      else{
        alert("Problem in Registration !" );
      }
     
   })
} 
}  

isValidInput()
{
  var option=document.getElementsByName('radio_css_inlinead');
  
if (!(option[0].checked || option[1].checked)) {
    alert("Please Select  Addmission Type !");
    return false;
}
  else
  if (this.state.FirstName==="")
  {
   alert('First Name is Required!');
   return false;
  }
  
  else if(this.state.dob==="")
  {
    alert("DOB  accepts Only Numeric!");
    return false;
  }
  else
    var option=document.getElementsByName('radio_css_inline');

if (!(option[0].checked || option[1].checked)) {
    alert("Please Select Your Gender");
    return false;
}
else
var option=document.getElementsByName('radio_css_inline1');

if (!(option[0].checked || option[1].checked || option[2].checked || option[3].checked)) {
alert("Please Select Your Community");
return false;
}
  
  else if(this.state.nationality==="")
  {
    alert("Nationality accepts Only Alphabate!");
    return false;
  }
  else if(this.state.stRegion==="")
  {
    alert("Religion  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.board==="")
  {
    alert("Board  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.medium==="")
  {
    alert("Medium  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.class==="")
  {
    alert("Enter your Class Name");
    return false;
  }
  else if(this.state.curntVillage==="")
  {
    alert("Current village  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.crntPost==="")
  {
    alert("Current Post  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.crntDistrict==="")
  {
    alert("Current District  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.crntState==="")
  {
    alert("Current state  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.crntPincode==="")
  {
    alert("Current Pin code  accepts Only Numeric!");
    return false;
  }
  else if(this.state.perVillage==="")
  {
    alert("Permanent village  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.perPost==="")
  {
    alert("Permanent Post  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.perDistrict==="")
  {
    alert("Permanent District  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.perState==="")
  {
    alert("Permanent state  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.perPincode==="")
  {
    alert("Permanent Pin code  accepts Only Numeric!");
    return false;
  }
  else if(this.state.FatherName==="")
  {
    alert("Father Name  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.fatherOccupation==="")
  {
    alert("Father Occupation  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.motherName==="")
  {
    alert("Mother Name  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.motherOccupation==="")
  {
    alert("Mother Occupation  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.fatherOccupation==="")
  {
    alert("Father Occupation  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.fatherOccupation==="")
  {
    alert("Father Occupation  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.fatherOccupation==="")
  {
    alert("Father Occupation  accepts Only Alphabate!");
    return false;
  }
  else if(this.state.fatherOccupation==="")
  {
    alert("Father Occupation  accepts Only Alphabate!");
    return false;
  }
  else{
    return true;
  }

}
render() {

 if(this.state.Loading)
{
   return <MyLoader/>
}
else{
  return (
    <Router>
      <switch>
        <Route path={'/AdminMain/StudentRegistration'}  exact render={
          ()=>{
        return(
            <div className="content">
                <div className="content-header">
                  <div className="leftside-content-header">
                    <ul className="breadcrumbs">
                      <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Student Registration</a></li>
                    </ul>
                  </div>
                </div>
                <div className="row animated fadeInUp">
                  <div className="col-sm-12 col-lg-12">
                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <div className="panel">
                          <div className="panel-content text-center" style={{ minHeight: '400px' }}>
        
                             {/* content body */}
                            <div className="panel-body">
                            <div style={{ float: 'left', width: '100%' }}>
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">
                                   
                  <div className="col-sm-3 bg-white">
                   <div>
                    <p className="text-center posi-rel"> <img src="https://clevelandpolicefoundation.org/wp-content/uploads/2012/09/female-placeholder.png" className="img-phto" alt="student list image"/>
                    </p>
                    <div className="photo-row-bottom posi-rel">
                      <span><input type="file" name="upload photo"/></span>
                      <span className="up-img">Upload Photo</span>
                    </div>
                    </div>
                 </div>
                   <div className="col-md-9 bg-st-img-left">
                   <div className="row">
                      
                        <label className="col-md-4">  ADMISSION TYPE: </label>
                                    <div className="col-md-8">
                                    <RadioGroup aria-label="position" name="radio_css_inlinead" value={this.state.AddmisType} onChange={this.handleChangeAdmissionType} row>
                                          <FormControlLabel
                                            value="New"
                                            control={<Radio color="primary" />}
                                            label="New Admission"
                                            labelPlacement="end"
                                          />
                                          <FormControlLabel
                                            value="Re"
                                            control={<Radio color="primary" />}
                                            label="Re Admission"
                                            labelPlacement="end"
                                          />
                                    </RadioGroup>
                                    </div>
                        
                                   
                                    <label className="col-md-6  col-form-label bg-reg-ad-no">  Admission Number(Optional): </label>
                                    <div className="col-md-6 bg-reg-ad-no ">
                                      <div className="input-group">
                                        <input type="text" id="AddmissionNumber" name="Addmission Number" value={this.state.AddmissionNumber} onChange={this.regChange} className="form-control" />
                                      </div>
                                </div>
                                </div>
                                </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pnlSubHedingBorder">
                                <div className="pnlSubHeding heading-with-bg-w">
                                  Basic Information
                                </div>
                              </div>
                              <div style={{ float: 'left', width: '100%' }}>
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group">
                  
                                   <div className="row">
                                   <label className="col-md-2 col-form-label">First Name</label>
                                  <div className="col-md-4 ">
                                  <div className="input-group">
                                      <input type="text" required id="FirstName" variant="outlined" placeholder="First Name" value={this.state.FirstName} onChange={this.regChange} className="form-control" />
                                    </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">LastName</label>
                                    <div className="col-md-4">
                                    <div className="input-group">
                                        <input type="text" required id="LastName" name="Last name" variant="outlined" placeholder="Last Name" value={this.state.LastName} onChange={this.regChange} className="form-control" />
                                    </div>
                                    </div>
                                  </div>
                                 <br/>
                                 <br/>
                                 
                                    <div className="row">
                                    <label className="col-md-2 col-form-label">Admission Date</label>
                                    <div className="col-md-4">
                                      <div className="input-group date" id="stAdmissionDate">
                                        <input type="date" id="date" name="date" value={this.state.date} onChange={this.regChange} className="form-control" />
                                        </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Date of Birth</label>
                                    <div className="col-md-4">
                                      <div className="input-group date" id="stDateofBirth">
                                        <input type="date" id="dob" name="dob" value={this.state.dob} onChange={this.regChange} className="form-control" />
                                        
                                      </div>
                                    </div>
                                    </div>

                                    <div className="row">
                                    <label className="col-md-2 col-form-label">Gender</label>
                                    <div className="col-md-4">
                                    <RadioGroup aria-label="position" name="radio_css_inline" id="inlineCssRadio" value={this.state.gender} onChange={this.handleChangeGender} row>
                                          <FormControlLabel
                                            value="Male"
                                            control={<Radio color="primary" />}
                                            label="Male"
                                            labelPlacement="end"
                                          />
                                          <FormControlLabel
                                            value="Female"
                                            control={<Radio color="primary" />}
                                            label="Female"
                                            labelPlacement="end"
                                          />
                                    </RadioGroup>
                                     
                                    </div>


                                    <label className="col-md-2 col-form-label">Community</label>
                                    <div className="col-md-4">
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline1" id="SCST" value="SCST" checked={this.state.SCST === "SCST"} onChange={this.regChange} />
                                        <label htmlFor="inlineCssRadio3">SC/ST</label>
                                      </div>
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline1" id="OBC" value="OBC" checked={this.state.OBC === "OBC"} onChange={this.regChange} />
                                        <label htmlFor="inlineCssRadio4">OBC</label>
                                      </div>
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline1" id="General" value="General" checked={this.state.General === "General"} onChange={this.regChange} />
                                        <label htmlFor="inlineCssRadio5">General</label>
                                      </div>
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline1" id="Others" value="Others" checked={this.state.Others === "Others"} onChange={this.regChange} />
                                        <label htmlFor="inlineCssRadio6">Others</label>
                                      </div>
                                    </div>
                                    </div>

                                    <div className="row">
                                    <label className="col-md-2 col-form-label">Religion</label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" className="form-control" value={this.state.stRegion} onChange={this.regChange} id="stRegion"  />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Adhar Number</label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input id="AdharNo" type="text" value={this.state.AdharNo} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Nationality</label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="nationality" name="nationality" value={this.state.nationality} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                              <div style={{ float: 'left', width: '100%' }}>
                              <div className="pnlSubHedingBorder headMarginTop">
                                <div className="pnlSubHeding heading-with-bg-w">
                                  Class Information
                                 </div>
                              </div>
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">
                                    <label className="col-md-2  col-form-label">  Board </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                      <select className="form-ii" onChange={this.onBoardChange} ref = {this.slt_board}>
                                       {this.state.mClassBoard.map((item, index) => ( 
                                      <option board_id={item.board_id}>{item.board_name}</option>
                                     ))}
                                      </select>
                                     </div>
                                    </div>
                                    <label className="col-md-2  col-form-label">   Medium </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                      <select className="form-ii" onChange={this.onMediumChange} ref = {this.slt_medium}>
                                      {this.state.mClassMedium.map((item, index) => ( 
                                      <option medium_id={item.medium_id}>{item.medium_name}</option>
                                      ))}
                                      </select> 
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label">   Class </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                      <select className="form-ii" onChange={this.OnClassChange} ref ={this.slt_Class}>
                                      {this.state.mFilteredClassSectionList.map((item, index) => ( 
                                      <option class_id={item.class_id} position_no={index}> {item.class_name} </option>
                  
                                      ))}
                                      </select>
                                        </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> Section </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                      <select className="form-ii" onChange={this.OnSectionChange} ref ={this.slt_Section}>
                                      {this.state.mFilteredSectionList.map((item, index) => ( 
                                      <option section_id={item.section_id}>{item.section_name} </option>
                  
                                      ))}
                                      </select>
                                       </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                              <div style={{ float: 'left', width: '100%' }}>
                              <div className="pnlSubHedingBorder headMarginTop">
                                <div className="pnlSubHeding heading-with-bg-w">
                                  Current Address
                              </div>
                              </div>
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">
                                    <label className="col-md-2  col-form-label">  Village/Town </label>
                                    <div className="col-md-10 ">
                                      <div className="input-group">
                                        <input id="curntVillage" value={this.state.curntVillage} onChange={this.regChange} type="text" className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label">  Post </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="crntPost" type="text" value={this.state.crntPost} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> District </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="crntDistrict" type="text" value={this.state.crntDistrict} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> State </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="crntState" type="text" value={this.state.crntState} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> Pin Code </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="crntPincode" type="text" value={this.state.crntPincode} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pnlSubHedingBorder headMarginTop">
                                <div className="pnlSubHeding heading-with-bg-w">
                                  Permanent Address
                                   </div>
                                   </div>
                                <div style={{ marginTop: '-8px', float: 'left', marginLeft: '80px', paddingTop: '4px' }}> <b>Same As Current Address </b></div>
                                <div style={{ marginTop: '-8px', float: 'left', marginLeft: '8px' }}>
                                  <div className="switcher">
                                    <input type="checkbox" name="switcher_checkbox_1" id="switcher_checkbox_1" onChange={this.regChange} defaultChecked={this.state.checked} onClick={this.copytopermAddr} />
                                    <label htmlFor="switcher_checkbox_1" />
                                  </div>
                                </div>
                              
                              <div style={{ float: 'left', width: '100%' }}>
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">
                                    <label className="col-md-2  col-form-label">  Village/Town </label>
                                    <div className="col-md-10 ">
                                      <div className="input-group">
                                        <input id="perVillage" type="text" value={this.state.perVillage} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label">  Post </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="perPost" type="text" value={this.state.perPost} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> District </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="perDistrict" type="text" value={this.state.perDistrict} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> State </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="perState" type="text" value={this.state.perState} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> Pin Code </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="perPincode" type="text" value={this.state.perPincode} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                              <div style={{ float: 'left', width: '100%' }}>
                              <div className="pnlSubHedingBorder headMarginTop">
                                <div className="pnlSubHeding heading-with-bg-w">
                                  Family Information
                                </div>
                              </div>
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">
                                    <label className="col-md-2  col-form-label"> Father Name </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="FatherName" type="text" value={this.state.FatherName} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label">Father Occupation </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="fatherOccupation" type="text" value={this.state.fatherOccupation} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label">Mother Name </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="motherName" type="text" value={this.state.motherName} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label">Mother Occupation </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="motherOccupation" type="text" value={this.state.motherOccupation} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                              <div style={{ float: 'left', width: '100%' }}>
                              <div className="pnlSubHedingBorder headMarginTop">
                                <div className="pnlSubHeding heading-with-bg-w">
                                  Contact Details
                                </div>
                              </div>
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">
                                    <label className="col-md-2  col-form-label"> Contact Number 1 </label>
                                    <div className="col-md-2 ">
                                      <div className="input-group">
                                        <input id="contact1" type="text" value={this.state.contact1} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-1  col-form-label"> Name </label>
                                    <div className="col-md-3 ">
                                      <div className="input-group">
                                        <input id="contactName_1" type="text" value={this.state.contactName_1} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-1  col-form-label">Relation </label>
                                    <div className="col-md-3 ">
                                      <div className="input-group">
                                        <input id="relation_1" type="text" value={this.state.relation_1} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> Contact Number 2 </label>
                                    <div className="col-md-2 ">
                                      <div className="input-group">
                                        <input id="contact2" type="text" value={this.state.contact2} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-1  col-form-label"> Name </label>
                                    <div className="col-md-3 ">
                                      <div className="input-group">
                                        <input id="contactName_2" type="text" value={this.state.contactName_2} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-1  col-form-label">Relation </label>
                                    <div className="col-md-3 ">
                                      <div className="input-group">
                                        <input id="relation_2" type="text" value={this.state.ralation_2} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label">Email Address </label>
                                    <div className="col-md-10 ">
                                      <div className="input-group">
                                        <input id="email" type="text" value={this.state.email} onChange={this.regChange} className="form-control" style={{marginLeft:'1%'}} />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <center>
                                        <button className="btn btn-success" onClick={this.regSubmit} style={{margin:'3% 0%', fontSize:'21px', width:'100%'}}>Submit</button>
                                       
                                      </center>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                         
                         
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
   
     )
    }
   } />

      <Route path='/AdminMain/StudentRegSuccess/:Enrollment_no/:session_id'  component={StRegSuccessfull} />
             
</switch>

</Router>
)
    }
  }
}
