import React, { Component, useRef, createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import cat from '../resources/images/cat.png';
import vender from '../resources/images/vender.png';
import Product from '../resources/images/Product.png';
import publication from '../resources/images/publication.png';
import fee_icon from '../resources/icons/icons_fee_80_white.png';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router, Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import AccessoriesCategory from './AccessoriesCategory';
import AccessoriesList from './AccessoriesList';
import PublicationList from './PublicationList';
import MyVendor from './MyVendor';
import myToast from '../Utils/MyToast';

export default class Accessories extends Component {
 
    constructor(props){
        super(props)
        
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
      
        this.state={
      
            loggedIn:true,
            Loading:false, 
            mAccessoriesDashBoard:[]
        }
       
       
      
      }
      componentDidMount()
      {
      this.getAccessoriesDashBoard();
      }
      getAccessoriesDashBoard(){
      
      this.setState({
        Loading:true
      })
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      var mSession=SessionManager.getCurrentSession();
      const SESSION_ID=mSession[0].session_id;
      const Lim="30";
      const Offset="0";
      const FUNCTION_NAME='getAccessoriesDashBoard.php'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      console.log("URL>>>>>"+URL)  
      axios.get(URL,{
        params:{session_id:SESSION_ID,school_code:SCHOOL_CODE}
      }).then(res => res.data)
      .then((response) => {
        this.setState({ 
          Loading:false
         })
          console.log("Response2>>>>>"+response);
          let api_status=response.api_status;
         // alert(api_status);
          
          if(api_status=="OK")
          {
          
             this.setState({
              
                mAccessoriesDashBoard:response.dashboard
              })
              console.log("response>>>>>"+JSON.stringify(this.state.mAccessoriesDashBoard));
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
      
        //console.log("response>>>>>"+api_status)
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        console.log("error>>>>>"+error);
        });
      }
        render() {
          if( this.state.loggedIn==false)
          {
           return <Redirect to='/Login'/>;
          }
          if(this.state.Loading)
          {
             return <MyLoader/>
          }else{
            
            return (
              
              <Router>
              <switch>
                <Route path={'/AdminMain/Accessories'}  exact render={
                  ()=>{
                    
               return (
                  <div className="content" style={{background:COLORS.whitesmoke}}>
                <div className="content-header" style={{ background: COLORS.primary_color_shade6 }}>
                    <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                            <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Accessories </a></li>
                        </ul>
                    </div>
                </div>
               
                <div className="bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px',padding:'0px'}}>
                  <div className="bg_primery_t10_b30" style={{height:'150px'}}>
                      
                  
                  </div>


                  <section style={{marginTop:'-80px',marginLeft:'30px',marginRight:'30px'}}>
                    <div className="row class-li">
                    <div className="col-sm-4 col-md-4">
                            <Link to={{pathname:'/AdminMain/MyVendor'}}>
                                <div  style={{ margin: '5px 5px', padding: '0px',borderRadius:'10px',background:COLORS.white }}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'70px',width:'70px'}}>
                                          <div className="circular_image_custom" style={{'--myFillColor':COLORS.Deep_Purple4,'--myBorderColor':COLORS.Deep_Purple2,'--myBorder':'3px'}}>
                                              <img  src={vender}  alt="" style={{height:'100%',width:'100%'}}></img>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                    <div className="gravity_center" style={{ height:'60px' }}>
                                        <div>
                                           <p className="text-center" style={{fontSize:'24px'}}> Vendor: </p>
                                        </div>
                                       <div>
                                          <p className="text-center" style={{fontSize:'24px'}}>{this.state.mAccessoriesDashBoard.total_vendor} </p>
                                       </div>
                                       
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4 col-md-4">
                            <Link to={{pathname:'/AdminMain/AccessoriesCategory'}}>
                                <div  style={{ margin: '5px 5px', padding: '0px',borderRadius:'10px',background:COLORS.white }}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'70px',width:'70px'}}>
                                          <div className="circular_image_primery">
                                              <img  src={cat}  alt="" style={{height:'100%',width:'100%'}}></img>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                    <div className="gravity_center" style={{ height:'60px' }}>
                                        <div>
                                           <p className="text-center" style={{fontSize:'24px'}}> Category: </p>
                                        </div>
                                       <div>
                                          <p className="text-center" style={{fontSize:'24px'}}>{this.state.mAccessoriesDashBoard.total_category} </p>
                                       </div>
                                       
                                    </div>
                                </div>
                            </Link>
                        </div>
                          
                         <div className="col-sm-4 col-md-4">
                            <Link to={{pathname:'/AdminMain/AccessoriesList'}}>
                                <div  style={{ margin: '5px 5px', padding: '0px',borderRadius:'10px',background:COLORS.white }}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'70px',width:'70px'}}>
                                          <div className="circular_image_custom" style={{'--myFillColor':COLORS.navy_blue4,'--myBorderColor':COLORS.navy_blue2,'--myBorder':'3px'}}>
                                              <img  src={Product}  alt="" style={{height:'100%',width:'100%'}}></img>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                    <div className="gravity_center" style={{ height:'60px' }}>
                                        <div>
                                           <p className="text-center" style={{fontSize:'24px'}}> Product: </p>
                                        </div>
                                       <div>
                                          <p className="text-center" style={{fontSize:'24px'}}>{this.state.mAccessoriesDashBoard.total_product} </p>
                                       </div>
                                       
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4 col-md-4">
                            <Link to={{pathname:'/AdminMain/AccessoriesList'}}>
                                <div  style={{ margin: '5px 5px', padding: '0px',borderRadius:'10px',background:COLORS.white }}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'70px',width:'70px'}}>
                                          <div className="circular_image_custom" style={{'--myFillColor':COLORS.green4,'--myBorderColor':COLORS.green2,'--myBorder':'3px'}}>
                                              <img  src={Product}  alt="" style={{height:'100%',width:'100%'}}></img>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                    <div className="gravity_center" style={{ height:'60px' }}>
                                        <div>
                                           <p className="text-center" style={{fontSize:'24px'}}> In Stock: </p>
                                        </div>
                                       <div>
                                          <p className="text-center" style={{fontSize:'24px'}}>{this.state.mAccessoriesDashBoard.total_product} </p>
                                       </div>
                                       
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4 col-md-4">
                            <Link to={{pathname:'/AdminMain/AccessoriesList'}}>
                                <div  style={{ margin: '5px 5px', padding: '0px',borderRadius:'10px',background:COLORS.white }}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'70px',width:'70px'}}>
                                          <div className="circular_image_custom" style={{'--myFillColor':COLORS.grey5,'--myBorderColor':COLORS.grey3,'--myBorder':'3px'}}>
                                              <img  src={Product}  alt="" style={{height:'100%',width:'100%'}}></img>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                    <div className="gravity_center" style={{ height:'60px' }}>
                                        <div>
                                           <p className="text-center" style={{fontSize:'24px'}}> Out Of Stock: </p>
                                        </div>
                                       <div>
                                          <p className="text-center" style={{fontSize:'24px'}}>{this.state.mAccessoriesDashBoard.total_product} </p>
                                       </div>
                                       
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4 col-md-4">
                            <Link to={{pathname:'/AdminMain/AccessoriesList'}}>
                                <div  style={{ margin: '5px 5px', padding: '0px',borderRadius:'10px',background:COLORS.white }}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',margin:'2px',height:'70px',width:'70px'}}>
                                          <div className="circular_image_custom" style={{'--myFillColor':COLORS.amber4,'--myBorderColor':COLORS.amber2,'--myBorder':'3px'}}>
                                              <img  src={publication}  alt="" style={{height:'100%',width:'100%'}}></img>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                    <div className="gravity_center" style={{ height:'60px' }}>
                                        <div>
                                           <p className="text-center" style={{fontSize:'24px'}}> Publication: </p>
                                        </div>
                                       <div>
                                          <p className="text-center" style={{fontSize:'24px'}}>{this.state.mAccessoriesDashBoard.total_publication} </p>
                                       </div>
                                       
                                    </div>
                                </div>
                            </Link>
                        </div>
                          
                    </div>
                
                </section>

                  
                    <div className="row bg_card_blue_outline"  style={{margin:'20px 35px 5px 35px'}}>
                    <div className="col-sm-6 col-md-6">
                            <Link to={{pathname:'/AdminMain/ StudentProvided'}}>
                                <div className="bg_card_green" style={{ margin: '30px 5px 5px 5px', padding: '0px',height:'120px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',marginTop:'-30px',height:'70px',width:'70px'}}>
                                          <div className="circular_image_custom" style={{'--myFillColor':COLORS.green5,'--myBorderColor':COLORS.green2,'--myBorder':'3px'}}>
                                              <img  src={fee_icon}  alt="" style={{height:'100%',width:'100%'}}></img>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                    <div className="gravity_center" style={{ height:'60px' }}>
                                        <div>
                                           <p className="text-center" style={{fontSize:'20px',color:COLORS.white}}> Received Amount Rs: </p>
                                        </div>
                                       <div>
                                          <p className="text-center" style={{fontSize:'20px',color:COLORS.white}}>{} </p>
                                       </div>
                                       
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-6 col-md-6">
                            <Link to={{pathname:'/AdminMain/StudentNotProvided)'}}>
                                <div  className="bg_card_red" style={{ margin: '30px 5px 5px 5px', padding: '0px',height:'120px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',marginTop:'-30px',height:'70px',width:'70px'}}>
                                          <div className="circular_image_custom" style={{'--myFillColor':COLORS.red5,'--myBorderColor':COLORS.red2,'--myBorder':'3px'}}>
                                              <img  src={fee_icon}  alt="" style={{height:'100%',width:'100%'}}></img>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                    <div className="gravity_center" style={{ height:'60px' }}>
                                        <div>
                                           <p className="text-center" style={{fontSize:'20px',color:COLORS.white}}>Due Amount Rs: </p>
                                        </div>
                                       <div>
                                          <p className="text-center" style={{fontSize:'20px',color:COLORS.white}}>{} </p>
                                       </div>
                                       
                                    </div>
                                </div>
                            </Link>
                            </div>
                                              
                           </div>


                            
                    <div className="row bg_card_blue_outline"  style={{margin:'20px 35px 5px 35px'}}>
                    <div className="col-sm-6 col-md-6">
                            <Link to={{pathname:'/AdminMain/ StudentProvided'}}>
                                <div className="bg_card_blue" style={{ margin: '30px 5px 5px 5px', padding: '0px',height:'120px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',marginTop:'-30px',height:'70px',width:'70px'}}>
                                          <div className="circular_image_custom" style={{'--myFillColor':COLORS.primary_color_shade5,'--myBorderColor':COLORS.primary_color_shade2,'--myBorder':'3px'}}>
                                              <img  src={Product}  alt="" style={{height:'100%',width:'100%'}}></img>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                    <div className="gravity_center" style={{ height:'60px' }}>
                                        <div>
                                           <p className="text-center" style={{fontSize:'20px',color:COLORS.white}}> Student (Provided): </p>
                                        </div>
                                       <div>
                                          <p className="text-center" style={{fontSize:'20px',color:COLORS.white}}>{this.state.mAccessoriesDashBoard.accessories_provided_student} </p>
                                       </div>
                                       
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-6 col-md-6">
                            <Link to={{pathname:'/AdminMain/StudentNotProvided)'}}>
                                <div  className="bg_card_orange" style={{ margin: '30px 5px 5px 5px', padding: '0px',height:'120px'}}>
                                    <div className="gravity_center">
                                       
                                        <div  style={{padding:'3px',marginTop:'-30px',height:'70px',width:'70px'}}>
                                          <div className="circular_image_custom" style={{'--myFillColor':COLORS.amber7,'--myBorderColor':COLORS.amber2,'--myBorder':'3px'}}>
                                              <img  src={Product}  alt="" style={{height:'100%',width:'100%'}}></img>
                                            
                                          </div> 
                                      </div> 
                                    </div>
                                    <div className="gravity_center" style={{ height:'60px' }}>
                                        <div>
                                           <p className="text-center" style={{fontSize:'20px',color:COLORS.white}}> Student (Not Provided): </p>
                                        </div>
                                       <div>
                                          <p className="text-center" style={{fontSize:'20px',color:COLORS.white}}>{this.state.mAccessoriesDashBoard.accessories_not_provided_student} </p>
                                       </div>
                                       
                                    </div>
                                </div>
                            </Link>
                            </div>
                            
                          
                             <div className="col-sm-12 col-md-12" >
                                <div  style={{padding:'0px',margin:'5px 5px 5px 5px'}}>
                              <Link to={'/AdminMain/AddClassFee'}>
                                 <button className="btn btn-primary" style={{width:'100%',fontSize:'18px', margin:'5px 0px 5px 0px'}}> Issue Accessories </button>
                              </Link> 
                              </div>               
                            </div>    
                         
                           </div>
                           
                    
                           <div className="row" style={{margin:'5px 30px 5px'}}>
                             <div className="col-sm-12 col-md-12" style={{padding:'0px'}}>
                            <Link to={'/AdminMain/AddClassFee'}>
                              <button className="btn btn-success" style={{width:'100%',fontSize:'20px', margin:'15px 0px 15px 0px'}}> Add Product </button>
                            </Link>                
                          </div>    
                          </div>
                </div>
               
              </div>
            
                  )
                }
                } />
                  <Route path='/AdminMain/AccessoriesCategory'  component={AccessoriesCategory} />
                  <Route path='/AdminMain/AccessoriesList'  component={AccessoriesList} />
                  <Route path='/AdminMain/PublicationList'  component={PublicationList} />  
                  <Route path='/AdminMain/MyVendor'  component={MyVendor} />     
                       
          </switch>       
         </Router>  
          )
      }
      }
}