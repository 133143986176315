import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import { withRouter } from "react-router-dom";
import ModalAddFee from '../dialog/ModalAddFee';
import myToast from '../Utils/MyToast';
 class FeeNameList extends Component {
    constructor(props) {
        super(props)   
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }     
        this.state={  
            isOpen: false, 
            loggedIn:true,
            Loading:false,
            showAddFee:false,
            mFeeName:[]
            
        }
        
      this.HandleCloseModalAddFee = this.HandleCloseModalAddFee.bind(this);
      this.HandleAddFee = this.HandleAddFee.bind(this);
        
      }
      HandleCloseModalAddFee= () => {
        
        this.setState({
          showAddFee:false
        });  
         
      }
      HandleAddFee= () => {
        
        this.setState({
          showAddFee:false
        });  
       this.getApplicableFeeAndMode();
         
      }

      
      
      AddNewFee=()=>{

        this.setState({
          showAddFee:true
        })
      }

      componentDidMount() {
        window.scrollTo(0, 0)
        this.getApplicableFeeAndMode()
      }
      getApplicableFeeAndMode(){
    
        this.setState({
          Loading:true
        })
        
        const SCHOOL_CODE=SessionManager.getSchoolCode();
        const FUNCTION_NAME='getApplicableFeeAndMode.php'
        URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
        //console.log("URL>>>>>"+URL)
        axios.get(URL,{
          params:{
                  school_code:SCHOOL_CODE,
                }
        }).then(res => res.data)
        .then((response) => {
         
          this.setState({ 
            Loading:false
           })
           // console.log("Response2>>>>>"+response);
            let api_status=response.api_status;
            //alert(api_status);
            
            if(api_status=="OK")
            {
            
               this.setState({               
                mFeeName:response.response.applicable_fee,
              
              })
              
            }
            else{
              let api_remark=response.api_remark;
              myToast.notify_warning(api_remark);
            }
        
          //console.log("response>>>>>"+api_status)
         }).catch((error)=>{
          this.setState({ 
            Loading:false
           })
          console.log("error>>>>>"+error);
          });
        }

      
    render() {
      
    if(this.state.loggedIn==false)
    {
    return <Redirect to='/Login'/>;
    }
    else if(this.state.Loading)
    {
            return <MyLoader/>
    }
   else{       
     
        return (
          
        <Router>
        <switch>
          <Route path={'/AdminMain/FeeNameList'}  exact render={
            ()=>{
          return(

            <div className="content" style={{background:COLORS.white}}>
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Fee Name List </a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="bg_page_grey_rounded box_shadow gravity_center" style={{'--mMargin':'150px'}}>
                    <div  style={{width:'50vw'}}>
                          <div className="row" style={{padding:'0px 20px 0px 20px'}}>
                            <h3>Applicable Fee:</h3>
                          </div>
                          
                          <div className="row">
                          {this.state.mFeeName && this.state.mFeeName.map((item,index)=>{
                                      return(                                            
                                        <div className="col-sm-6 col-md-6 " >
                                        
                                        <div className="row box_shadow bg_smokewhite gravity_center" style={{padding:2,height:'60px',margin:10}}>
                                        <h4> {item.fee_name} </h4>
                                        </div>
                                        
          
                                    </div>
                                     
                                        )
                                    })}
                              
                          </div>
                          
                          <div className="row">
                          <div className="col-sm-12 col-md-12 gravity_center " >
                           
                              <button className="btn btn-success" onClick={()=>this.AddNewFee()} style={{fontSize:'24px', margin:'3px 2px 5px 2px',width:'50vw',background:COLORS.primary_color_shade5}}> Add New Fee </button>
                                           
                          </div>  
                          {this.state.showAddFee===true? <ModalAddFee  action={this.HandleCloseModalAddFee} action_submit={this.HandleAddFee}></ModalAddFee>:''}  
                          </div>
                      </div>

                     
                        </div>
                        </div>
           )
          }
        } />
                     
        </switch>       
       </Router>  
        )
    }
    }
}
export default withRouter(FeeNameList);
