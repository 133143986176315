import React, { Component } from 'react'
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class Transport extends Component {
    render() {
        return (
            <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Coming Soon </a></li>
              </ul>
            </div>
          </div> 
              <div className="row class-li">
              <div className="col-sm-9 success-m">
                <h2 className="text-center text-white bg-success sp-10"> Coming Soon !</h2>
               <div className="row sp-10 s-mt-100">
               <div className="col-sm-12 col-md-12">
                   <div className="myprofile-img-div im"><img src="https://www.joelnelsongroup.com/wp-content/uploads/2015/08/Coming-Soon.jpg" alt="check image" /></div>
             
                </div>
               
             <div className="col-sm-12 col-md-12">
             <hr/>
             <p className="text-center"><small> &copy; 2020 Eduok | Design & Developed By: Eduok </small></p>
             </div>
               </div>
              </div>
                </div>
               </div>
        )
    }
}
