import React, { Component} from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import SelectClass from '../ChildComponent/SelectClass';
import COLORS from '../resources/colors/COLORS';
import AddClassFee from './AddClassFee';
import myToast from '../Utils/MyToast';

export default class ClassFee extends Component {
  constructor(props) {
    super(props)        
   
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={  
        isOpen: false, 
        loggedIn:true,
        Loading:false,
        SESSION_ID:"",
        CLASS_ID:"",
        SECTION_ID:"",
        mClassFee:[],
        SELECTED_CLASS_POSITION:''
        
    }
    
    this.CallBackClassSelect= this.CallBackClassSelect.bind(this);
    
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  CallBackClassSelect = (class_id,section_id,session_id,selected_position) => {
  
    this.state.CLASS_ID=class_id;
    this.state.SECTION_ID=section_id;
    this.state.SESSION_ID=session_id;
    this.state.SELECTED_CLASS_POSITION=selected_position;
    //alert("Back"+class_id+"/"+section_id+"/"+session_id);
    this.getClassFee();
  }
 
  getClassFee(){
    this.state.mClassFee=[];
    
    this.setState({
      Loading:true
    })
    
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    const FUNCTION_NAME='getClassApplicableFee.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
        var params = new URLSearchParams();
      params.append('session_id', this.state.SESSION_ID);
      params.append('class_id', this.state.CLASS_ID);
      params.append('school_code',SCHOOL_CODE);
   axios.post(URL,params).then(res => res.data)
  .then((response) => {
    this.setState({ 
       Loading:false
      })
       // console.log("Response2>>>>>"+response);
        let api_status=response.api_status;
        //alert(api_status);      
        if(api_status=="OK")
        {       
           this.setState({            
            mClassFee:response.data,
            })
           
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
        //alert(JSON.stringify(this.state.mClassFee));
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }
    getMonth(index)
    {
      var Arr=[];
      Arr=index.split('-');
      var month=Arr[1];
      if (month==1){
        return("Jan");
    }else if (month==2){
      return("Feb");
    }else if (month==3){
        return("Mar");
    }else if (month==4){
        return("Apr");
    }else if (month==5){
        return("May");
    }else if (month==6){
        return("Jun");
    }else if (month==7){
        return("Jul");
    }else if (month==8){
        return("Aug");
    }else if (month==9){
        return("Sep");
    }else if (month==10){
        return("Oct");
    }else if (month==11){
        return("Nov");
    }else if (month==12){
        return("Dec");
    }
        
    }
   
  render() {
    if(this.state.loggedIn==false)
    {
    return <Redirect to='/Login'/>;
    }
    else{       
      return (
        <Router>
          <switch>
            <Route path={'/AdminMain/ClassFee'}  exact render={
              ()=>{
            return(

              <div className="content" style={{background:COLORS.white}}>
                    <div className="content-header">
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Class Fee </a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>

                       
                        <SelectClass action={this.CallBackClassSelect} selected_position='0'></SelectClass>
                         
                        
                          <section>
                          <hr/>
                          {this.state.Loading?<MyLoader/>:
                          <div className="row">
                            {this.state.mClassFee && this.state.mClassFee.map((item,index)=>{

                                var mApplicableMonth=[];
                                var strmApplicableMonth=item.applicable_month;
                                mApplicableMonth=strmApplicableMonth.split(',');

                              return(
                                <div className="col-sm-6 col-md-6" >
                                <div className="bg_card_blue_outline  box_shadow_primary" style={{margin:'5px 2px 10px 3px'}}>
                                  <div className="gravity_center">                      
                                    <p style={{fontSize:'18px'}}><strong>{item.fee_name}: </strong>{"Rs "+item.fee_value}</p> 
                                  </div>                       
                                  <div className="gravity_center">
                                      <p><strong> Reciept Mode: </strong>{item.mode} </p> 
                                  </div> 
                                  <div className="gravity_center">
                                    <h5> Applicable Month </h5>
                                    
                                  </div>  
                                  <hr/>
                                  <div className="row" style={{padding:'0px 15px 5px 15px'}}>
                                    {mApplicableMonth && mApplicableMonth.map((item2,index2)=>{
                                      return(                                            
                                          <div className="col-sm-3 col-md-3">
                                          <div className="bg_circle_outline_primery gravity_center" style={{'--mWidth':'70px','--mHeight':'70px','--mBorder':'2px',marginBottom:'8px'}}>
                                            <p style={{fontSize:'20px',color:COLORS.black8}}>{this.getMonth(item2)} </p>
                                            <p className="bg_round_green fee_collection_month" style={{fontSize:'15px',color:COLORS.white}}>{item2}</p>
                                          </div>
                                          </div>
                                      )
                                    })}
                                </div>
                                <div className="row" style={{padding:'0px 10px 0px 10px'}}>
                                    <div className="col-sm-12 col-md-12 text-center">
                                          <button className="btn btn-info" style={{width:'100%',fontSize:'18px', margin:'10px 10px 10px 0px'}}> <i class="fa fa-edit"></i> &nbsp;   Edit </button>
                                
                                    </div>
                                </div>
                                
                                </div>
                              </div> 
                          
                              )
                            })}
                            
                          </div>                           
                           }
                         </section>

                         
                          <div className="row">
                          <div className="col-sm-12 col-md-12">
                            <Link to={'/AdminMain/AddClassFee'}>
                              <button className="btn btn-success" style={{width:'100%',fontSize:'24px', margin:'30px 0px 15px 0px'}}> Add Class Fee </button>
                            </Link>                
                          </div>    
                          </div>
                        </div>
                        </div>
                
            )
          }
        } />   
         <Route path='/AdminMain/AddClassFee'  component={AddClassFee} />                             
        </switch>
        
       </Router>
        )
       } 
     }
    
  }
