import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import { BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import StudentRegistration from './StudentRegistration';
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import plus from '../resources/icons/icons_plus_100_white.png'
import batchshift from '../resources/icons/icons_batch_shifting_100.png'
import query from '../resources/icons/icons_query_100_white.png'
import fee from '../resources/icons/icons_fee_80_white.png'
import admission_link from '../resources/icons/icons_admission_80_white.png'
import BatchShifting from './BatchShifting';
import myToast from '../Utils/MyToast';
import AdmissionQuery from './AdmissionQuery';
import AdmissionLink from './AdmissionLink';
import RegistrationFee from './RegistrationFee';
export default class AdmissionDashboard extends Component {
  constructor(props){
    super(props)
  const token=localStorage.getItem("token");
  let loggedIn=true;
  if(token==null){
    loggedIn=false;
  }

  this.state={

      loggedIn:true,
      Loading:false, 
      mgetAdmissionDashboard:''  
  }

}
componentDidMount()
{
this.getAdmissionDashboard();
}
getAdmissionDashboard(){

this.setState({
  Loading:true
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
var mSession=SessionManager.getCurrentSession();
const SESSION_ID=mSession[0].session_id;
const FUNCTION_NAME='getAdmissionDashboard.php'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
console.log("URL>>>>>"+URL)
// var params = new URLSearchParams();
  //  params.append('school_code', SCHOOL_CODE);

axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE,
    session_id:SESSION_ID
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
   // alert(api_status);
    
    if(api_status=="OK")
    {
    
       this.setState({
        
        mgetAdmissionDashboard:response.dashboard
        })
        console.log("response>>>>>"+this.state.mgetAdmissionDashboard);
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}

  render() {
    if( this.state.loggedIn==false)
    {
     return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }else{

return (
  <Router> 
  <switch>
  <Route path='/AdminMain/AdmissionDashboard'  exact render={
          ()=>{
            
            return(
      <div className="content" style={{background:COLORS.white}}>
      <div className="content-header">
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li><i className="fa fa-home" aria-hidden="true" /><a href="#">AdmissionDashboard</a></li>
          </ul>
        </div>
        <div className="col-sm-12 col-lg-12 text-center -bg mar-t" style={{background:COLORS.primary_color_shade6}}>
          <h3> Total Students:{this.state.mgetAdmissionDashboard.total_student} </h3>
          </div>
      </div>
      
                   
      <div className="animated fadeInUp bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px',marginTop:'20px'}}>
       
          <div className="row class-li">
            <div className="col-sm-4 col-md-4" style={{padding:'5px'}}>
            <Link to="/AdminMain/StudentRegistration">
            <div className="row class-list-box" >
             <div className="col-sm-4" style={{background: COLORS.primary_color_shade6,padding:'1em'}}>
               <div>
                    <img src={plus} className="admission-dashboard-img" alt="student list image"/>
                </div>
            </div>
             <div className="col-sm-8 pt-45">
             <h4 className="text-center"> Student Registration </h4>
             </div>
            </div>
            </Link>
            </div>
          
            <div className="col-sm-4 col-md-4" style={{padding:'5px'}}>
            <Link to="/AdminMain/BatchShifting">
            <div className="row class-list-box">
             <div className="col-sm-4" style={{background: COLORS.primary_color_shade6,padding:'1em'}}>
               <div>
                    <img src={batchshift} className="admission-dashboard-img" alt="student list image"/>
                </div>
            </div>
             <div className="col-sm-8 pt-45">
             <h4 className="text-center"> Batch Shifting </h4>
             </div>
            </div>
            </Link>
            </div>
            <div className="col-sm-4 col-md-4" style={{padding:'5px'}}>
            <Link to="/AdminMain/AdmissionQuery">
            <div className="row class-list-box">
             <div className="col-sm-4" style={{background: COLORS.primary_color_shade6,padding:'1em'}}>
               <div>
                    <img src={query} className="admission-dashboard-img" alt="student list image"/>
                </div>
            </div>
             <div className="col-sm-8 pt-45">
             <h4 className="text-center"> Admission Query </h4>
             </div>
            </div>
            </Link>
            </div>

            <div className="col-sm-4 col-md-4" style={{padding:'5px'}}>
            <Link to="/AdminMain/RegistrationFee">
            <div className="row class-list-box">
             <div className="col-sm-4" style={{background: COLORS.primary_color_shade6,padding:'1em'}}>
               <div>
                    <img src={fee} className="admission-dashboard-img" alt="student list image"/>
                </div>
            </div>
             <div className="col-sm-8 pt-45">
             <h4 className="text-center"> Registration Fee </h4>
             </div>
            </div>
            </Link>
            </div>
            <div className="col-sm-4 col-md-4" style={{padding:'5px'}}>
            <Link to="/AdminMain/AdmissionLink">
            <div className="row class-list-box">
             <div className="col-sm-4" style={{background:COLORS.primary_color_shade6 ,padding:'1em'}}>
               <div>
                    <img src={admission_link} className="admission-dashboard-img" alt="student list image"/>
                </div>
            </div>
             <div className="col-sm-8 pt-45">
             <h4 className="text-center"> Online Admission Link </h4>
             </div>
            </div>
            </Link>
            </div>

            </div>


            </div>
           </div>
          )
        }
      } />
    
     <Route path='/AdminMain/StudentRegistration'  component={StudentRegistration} />
     <Route path='/AdminMain/BatchShifting'  component={BatchShifting} />
     <Route path='/AdminMain/AdmissionQuery'  component={AdmissionQuery} />
     <Route path='/AdminMain/AdmissionLink'  component={AdmissionLink} />
     <Route path='/AdminMain/RegistrationFee'  component={RegistrationFee} />
             
       
      </switch>
        
       </Router>
      )
      
  }
 
}
}
