import React, { Component ,useRef, createRef} from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import eduok from '../resources/images/EduOk.png'
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class HomeWork extends Component { 
  constructor(props){
    super(props)
    this.slt_board=createRef();
    this.slt_medium=createRef();
    
   
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={   
        loggedIn:true,
        Loading:false, 
        mClassData:[],
        mClassBoard:[],
        mClassMedium:[], 
        mFilteredClassSectionList:[]  
    }
  
  }
  onBoardChange = (event) => {
    //this.setState({ value: event.target.value });
    //let board_id=event.target.selectedOptions[0].getAttribute('board_id');
   
    this.getClassList();
  
  
  };
  onMediumChange=(event)=>{
    this.getClassList();
  }
  getClassList()
  {
  
    let board_id=this.slt_board.current.selectedOptions[0].getAttribute('board_id');
    let medium_id=this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');
    //alert(board_id);
    //alert(medium_id);
    //filtering class of selected board and medium
    var mFilteredClassList=[];
    this.state.mFilteredClassSectionList=[];
    for (var i=0; i < this.state.mClassData.length; i++) {
      let item=this.state.mClassData[i];
      if(item.board_id===board_id && item.medium_id===medium_id)
      {
      
        mFilteredClassList.push(item);
  
      }   
    }
    //console.log("ClassList>>>>>>",mFilteredClassList); 
    //getting section of classes
    for (var i=0; i <mFilteredClassList.length; i++) {
      let item=mFilteredClassList[i];
      let SECTION=item.section;
              if(SECTION==="No")
              {
              
                this.state.mFilteredClassSectionList.push(item);
              }
              else {
    
                var tempSection=[];
                tempSection=item.section;
                for (var j=0;j<tempSection.length;j++)
                {
                    let item2=tempSection[j];
                    item2["board_id"]=board_id;
                    item2["board_name"]=item.board_name;
                    item2["medium_id"]=medium_id;
                    item2["medium_name"]=item.medium_name;
                    //console.log("item2>>>>>>",item2);                
                    this.state.mFilteredClassSectionList.push(item2);
                    
                }
              }
      
    }
    this.setState({       
     
      })
  }
  
  componentDidMount()
  {
   
    this.getClass();
  }
  getClass(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  var mSession=SessionManager.getCurrentSession();
  const SESSION_ID=mSession[0].session_id;
  //alert(SESSION_ID);
  
  const FUNCTION_NAME='getClass.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{school_code:SCHOOL_CODE,
      session_id:SESSION_ID
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
     // alert(api_status);
      
      if(api_status=="OK")
      {
      
         this.setState({       
          mClassData:response.response.class,
          mClassBoard:response.response.board,
          mClassMedium:response.response.medium
          })
          this.getClassList();
          //console.log("response>>>>>"+this.state.mClassData);
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      } 
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
  handleDropdownChange(e){
    alert("hhh");
// console.log("ggggggggggggggg>>>>>>>>>>");
}
    render() {
    if( this.state.loggedIn==false)
    {
     return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }else{

  return (
  <div className="content">
      <div className="content-header" style={{background:COLORS.primary_color_shade5}}>
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li><i className="fa fa-home" aria-hidden="true" style={{color:COLORS.white}} /><a href="#"> Home Work </a></li>
          </ul>
        </div>
      </div>   
      <div className="row" style={{padding:'0px 15px'}}>
            <div className="col-sm-6 col-md-6" style={{background:COLORS.card_orange}}>
            <div className="">
            <select className="select-field" onChange={this.handleDropdownChange}>
            {this.state.mClassBoard.map((item, index) => ( 
               <option>{item.board_name}</option>
               
               ))}
            </select>
            </div>
            </div>
             <div className="col-sm-6 col-md-6" style={{background:COLORS.card_orange}}>
              <div className="">
               <select className="select-field">
               {this.state.mClassMedium.map((item, index) => ( 
               <option>{item.medium_name}</option>
               ))}
             
               </select>
              </div>
            </div>

           </div>
           <section className="exam-section-1" style={{background:COLORS.card_orange}}>
          <div className="row class-li">
          <div className="col-sm-1 col-md-1">
           <div className="exam-p-setting-cls bg-yellow"> <p> Nursery B1 </p></div>
            </div>
            <div className="col-sm-1 col-md-1">
            <div className="exam-p-setting-cls"> <p> UKG B1 </p></div>
            </div>
            <div className="col-sm-1 col-md-1">
            <div className="exam-p-setting-cls"> <p> LKG B1 </p></div>
            </div>
            <div className="col-sm-1 col-md-1">
            <div className="exam-p-setting-cls"> <p> I B1 </p></div>
            </div>
            <div className="col-sm-1 col-md-1">
            <div className="exam-p-setting-cls"> <p> II B1 </p></div>
            </div>
            <div className="col-sm-1 col-md-1">
            <div className="exam-p-setting-cls"> <p> III B1 </p></div>
            </div>
            <div className="col-sm-1 col-md-1">
            <div className="exam-p-setting-cls"> <p> IV B1 </p></div>
            </div>
            <div className="col-sm-1 col-md-1">
            <div className="exam-p-setting-cls"> <p> V B1 </p></div>
            </div>
            <div className="col-sm-1 col-md-1">
            <div className="exam-p-setting-cls"> <p> VI B1 </p></div>
            </div>
            <div className="col-sm-1 col-md-1">
            <div className="exam-p-setting-cls"> <p> VII B1 </p></div>
            </div>
            <div className="col-sm-1 col-md-1">
            <div className="exam-p-setting-cls"> <p> VIII B1 </p></div>
            </div>
            <div className="col-sm-1 col-md-1">
            <div className="exam-p-setting-cls"> <p> IX B1 </p></div>
            </div>
            </div>
            </section> 
            <div className="row" style={{padding:'0px 15px'}}>
             <div className="col-sm-12 col-lg-12 p-0">
             <div class="month">      
  <ul>
    <li class="prev">&#10094;</li>
    <li class="next">&#10095;</li>
    <li>
      August<br/>
      <span style={{fontSize:'18px'}}>2020</span>
    </li>
  </ul>
</div>

<ul class="weekdays">
  <li>Mo</li>
  <li>Tu</li>
  <li>We</li>
  <li>Th</li>
  <li>Fr</li>
  <li>Sa</li>
  <li>Su</li>
</ul>

<ul class="days">  
  <li>1</li>
  <li>2</li>
  <li>3</li>
  <li>4</li>
  <li>5</li>
  <li>6</li>
  <li>7</li>
  <li>8</li>
  <li>9</li>
  <li><span class="active">10</span></li>
  <li>11</li>
  <li>12</li>
  <li>13</li>
  <li>14</li>
  <li>15</li>
  <li>16</li>
  <li>17</li>
  <li>18</li>
  <li>19</li>
  <li>20</li>
  <li>21</li>
  <li>22</li>
  <li>23</li>
  <li>24</li>
  <li>25</li>
  <li>26</li>
  <li>27</li>
  <li>28</li>
  <li>29</li>
  <li>30</li>
  <li>31</li>
</ul> 
            </div> 
          <div className="col-sm-12 col-lg-12" style={{background:COLORS.primary_color_shade5}}>
          <h4 style={{textAlign:'center',color:'#fff',marginBottom:'0px',padding:'10px'}}> 2020-07-24 </h4>
          </div>  
          <div className="col-sm-8 col-lg-8 bg-white">
         <h3 className="attend-status"> Color: Hints <label className="switch bb-switch" data-toggle="collapse" data-target="#demo">
  <input type="checkbox"/>
  <span className="slider round"></span>
</label> </h3>
<div className="panel-group">
  <div className="panel panel-default">
    <div id="demo" className="panel-collapse collapse">
      <div className="panel-body">
          <div className="row">
          <div className="col-sm-3 col-lg-3 ">
              <p><span className="butt-p1"></span> <span> Attendance Taken </span></p>
          </div>
          <div className="col-sm-3 col-lg-3 ">
              <p><span className="butt-p1"></span> <span> Not Taken </span></p>
          </div>
          <div className="col-sm-3 col-lg-3 ">
              <p><span className="butt-p1"></span> <span> Sunday </span></p>
          </div>
          <div className="col-sm-3 col-lg-3 ">
              <p><span className="butt-p1"></span> <span> Holiday </span></p>
          </div>
          </div>
      </div>
      
    </div>
  </div>
</div>
</div>
<div className="col-sm-4 col-lg-4 bg-white">
<button className="btn mt-3" style={{width:'100%',fontSize:'16px',border:'1px solid green'}}> Sunday </button>
            
</div>
               </div>
            <section className="bg-white mt-4">
            <div className="row">
            <div className="col-sm-12 col-md-12">
              <h4 className="p-4"> Home Work Status </h4>
            </div>
            <div className="col-sm-3 col-md-3">
             <div className="row class-list-box" style={{border:'1px solid #fea223', borderRadius:'10px'}}>
             <div className="col-sm-12 p-0 text-center">
              <h5 style={{color:COLORS.card_orange}}> Computer(Not Assigned) </h5>
              <p> <span> Not Assigned </span> </p>
              <button className="btn btn-success" style={{width:'100%',fontSize:'16px',background:COLORS.card_orange,border:COLORS.green5}}> Assign Homework </button>
            
             </div>
            </div>
            </div> 
            <div className="col-sm-3 col-md-3">
             <div className="row class-list-box" style={{border:'1px solid #fea223', borderRadius:'10px'}}>
             <div className="col-sm-12 p-0 text-center">
              <h5 style={{color:COLORS.card_orange}}> Computer(Not Assigned) </h5>
              <p> <span> Not Assigned </span> </p>
              <button className="btn btn-success" style={{width:'100%',fontSize:'16px',background:COLORS.card_orange,border:COLORS.green5}}> Assign Homework </button>
            
             </div>
            </div>
            </div> 
            <div className="col-sm-3 col-md-3">
             <div className="row class-list-box" style={{border:'1px solid #fea223', borderRadius:'10px'}}>
             <div className="col-sm-12 p-0 text-center">
              <h5 style={{color:COLORS.card_orange}}> Computer(Not Assigned) </h5>
              <p> <span> Not Assigned </span> </p>
              <button className="btn btn-success" style={{width:'100%',fontSize:'16px',background:COLORS.card_orange,border:COLORS.green5}}> Assign Homework </button>
            
             </div>
            </div>
            </div> 
            <div className="col-sm-3 col-md-3">
             <div className="row class-list-box" style={{border:'1px solid #fea223', borderRadius:'10px'}}>
             <div className="col-sm-12 p-0 text-center">
              <h5 style={{color:COLORS.card_orange}}> Computer(Not Assigned) </h5>
              <p> <span> Not Assigned </span> </p>
              <button className="btn btn-success" style={{width:'100%',fontSize:'16px',background:COLORS.card_orange,border:COLORS.green5}}> Assign Homework </button>
            
             </div>
            </div>
            </div>    
            </div>
            </section>
           </div>
        )
    }
}
}
