import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import Table from 'react-bootstrap/Table'

import ExamStudentReportCard from './ExamStudentReportCard';
export default class ExamMarkingSystem extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
    
        loggedIn:true,
        Loading:false, 
        LoadingStudent:false,
        mExamMarkingSystem:[],
        mExamParticipatingClass:[],
        mMarkingSystem:[],
        mMarkingSystemValue:[],
        mStudentList:[],
        EXAM_ID:'',
        SESSION_ID:'',
        EXAM_NAME:'',
        START_DATE:'',
        EXAM_STATUS:'',
        EXAM_ID:'',
        CLASS_ID:'',
        SECTION_ID:'',
        CLASS_SELECTED_POSITION:0
       

    }
    
  
  }
  componentDidMount()
  {
  
    this.state.EXAM_ID=this.props.match.params.exam_id;
    this.state.SESSION_ID=this.props.match.params.session_id;   
    this.state.EXAM_NAME=this.props.match.params.exam_name;
    this.state.START_DATE=this.props.match.params.exam_date;
    this.state.EXAM_STATUS=this.props.match.params.exam_status;

    window.scrollTo(0, 0); 
    this.getMarkingSystemData();  
  }

  getMarkingSystemData(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getExamMarkingSystem.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
  
  axios.get(URL,{
    params:{
      school_code:SCHOOL_CODE,
      session_id:this.state.SESSION_ID,
      exam_id:this.state.EXAM_ID,
      report_card_type:'MT',
    
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      let api_status=response.api_status;
      // alert(JSON.stringify(response));
      
      if(api_status=="OK")
      {
      
         this.setState({         
            mExamMarkingSystem:response.data
           
          })
          this.getParticipatingClass();
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
  getParticipatingClass()
  {
      
    this.setState({         
      mExamParticipatingClass:this.state.mExamMarkingSystem[0]['participating_class'],
    })
    this.setClassList(0);
  }
  selectClass=(position)=>{  
    this.setClassList(position);
    
  }
  setClassList(selected_position){      
    if(this.state.mExamParticipatingClass.length!==0)
    {
        this.state.CLASS_ID=this.state.mExamParticipatingClass[selected_position].class_id;
        this.state.SECTION_ID=this.state.mExamParticipatingClass[selected_position].section_id;

        if(this.state.SECTION_ID=="null")
        {
            this.state.SECTION_ID="No";
        }
        this.state.CLASS_SELECTED_POSITION=selected_position;

    }
 
    this.state.mMarkingSystem=this.state.mExamParticipatingClass[selected_position].marking_system;
    this.state.mMarkingSystemValue=this.state.mMarkingSystem[0].MarkingSystemValue;
    this.getStudentlist();
   
  }
  getStudentlist(){
    this.setState({
      LoadingStudent:true
    })
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    const FUNCTION_NAME='getClassStudentMarks.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    // var params = new URLSearchParams();
      //  params.append('school_code', SCHOOL_CODE);
    
    axios.get(URL,{
      params:{
        school_code:SCHOOL_CODE,
        session_id:this.state.SESSION_ID,
        exam_id:this.state.EXAM_ID,
        class_id:this.state.CLASS_ID,
        section_id:this.state.SECTION_ID,
      
      }
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        LoadingStudent:false
       })
        let api_status=response.api_status;
        //alert(JSON.stringify(response));
        
        if(api_status=="OK")
        {
        
           this.setState({         
              mStudentList:response.data
             
            })
            
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
  }

  
    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{ 
        
        return (             
        <Router>
        <switch>
          <Route path={'/AdminMain/ExamMarkingSystem/:exam_id/:session_id/:exam_name/:exam_date/:exam_status'}  exact render={
            ()=>{
              
         return (
       
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Exam Marking System</a></li>
              </ul>
            </div>
        
          </div>    
        
          <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>
      
        
        
          <div className="row gravity_center" style={{margin:'0px 15px 0px 15px'}}>
                <div className="col-sm-4 col-md-4 bg_grid_white_2">
                  <p  style={{padding:'2px',fontSize:'18px'}}><strong> Academic Session:</strong>{this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['session_name']:''}  </p> 
                </div>
                <div className="col-sm-4 col-md-4 bg_grid_white_2">
                  <p  style={{padding:'2px',fontSize:'18px'}}><strong> Exam:</strong>{this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['exam_name']:''} </p> 
                </div>
                <div className="col-sm-4 col-md-4 bg_grid_white_2">
                  <p  style={{padding:'2px',fontSize:'18px'}}><strong> Exam Date:</strong>{this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['start_date']:''}  </p> 
                </div>
                               
          </div>
          <div className="row gravity_center" style={{margin:'2px 15px 2px 15px'}}>
               
               <div className="bg_grid_white_2">
                 <p  style={{padding:'2px',fontSize:'18px'}}><strong> ReportCard Caption:</strong> {this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['report_card_name']:''} </p> 
               </div>
                
         </div>
          <div style={{padding:'0px 15px 0px 15px'}}><h5>Participating Class:</h5></div>
                    <div className="row bg_card_primary" style={{padding:'5px',margin:'0px 15px 0px 15px'}}>
                    {this.state.mExamParticipatingClass.map((item, index) => (
                    <div className="col-sm-1 col-md-1" style={{padding:'0px 2px 5px 2px'}}>
                        <div className={this.state.CLASS_SELECTED_POSITION===index?"bg_class_select gravity_center":"bg_class_deselect gravity_center"} onClick={e=>this.selectClass(index)} style={{cursor:'pointer'}}> 
                           <p style={{fontSize:14}}> {item.section_id==='No'?item.class_name:item.class_name+':'+item.section_name} </p>
                        </div>
                    </div>
                    ))}
                      </div>
       

        
              <div style={{padding:'0px 15px 0px 15px'}}><h5>Marking System:</h5></div>
                 <div className="row">
                            
                  <div className="col-sm-12 col-md-12" >
                  <div className="bg_card_blue_outline  box_shadow_primary" style={{margin:'5px 2px 10px 3px'}}>
                    <div className="row">
                     <div className="col-sm-8 col-md-8" >
                                                
                    <div className="gravity_center">
                        <p><strong> Marking System: </strong> </p> 
                    </div> 
                      </div>
                    </div>
                  
                    
                    <hr/>
                    <div className="row" style={{padding:'0px 15px 5px 15px'}}>
                      
                    <div className="col-sm-8 col-md-8" >                                          
                          <table className="striped highlight " style={{width:'100%',border:'1'}}>
                          <thead>
                            <tr>
                              <th>Sr.</th>
                              <th>From</th>
                              <th>To</th>
                              <th>Marking</th>
                              <th>Performance Descriptor</th>
                              <th>Result</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.mMarkingSystemValue && this.state.mMarkingSystemValue.map((item2,index2)=>{
                          return(   
                            <tr>
                              <td>{index2+1}</td>
                              <td>{item2.from_value}</td>
                              <td>{item2.to_value}</td>
                              <td>{item2.marking}</td>
                              <td>{item2.performance_descriptors}</td>
                              <td>{item2.result}</td>
                              
                            </tr>
                          )
                        })}

                          </tbody>
                        </table>
                        
                      
                  </div>
                  <div className="col-sm-4 col-md-4 gravity_center_vertical padding_0" > 
                
              
                  </div>                
                  </div>
                  <hr/>             
                  
                  </div>
                </div> 
                                                                           
                </div>   
                <div style={{padding:'0px 15px 0px 15px'}}><h5>Student List:</h5></div>

                <div className="row bg_card_blue_outline  box_shadow_primary"  style={{margin:'0px 15px 0px 15px'}}>
                {this.state.LoadingStudent?<div><MyLoader/></div>:
              
               
                                                                 
                  <div className="col-sm-12 col-md-12 p-0">
                  
                      <Table striped bordered hover style={{width:'100%'}}>
                          <thead>
                            <tr>
                              <th>Sr.</th>
                              <th>Student Name</th>
                              <th>Father Name</th>
                              <th>Admission No</th>
                              <th>Class</th>
                              <th>Roll Number</th>
                              <th>Action</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.mStudentList && this.state.mStudentList.map((item2,index2)=>{
                          return(   
                            <tr>
                              <td>{index2+1}</td>
                              <td>{item2.stu_name}</td>
                              <td>{item2.father_name}</td>
                              <td>{item2.sr_admission_no}</td>
                              <td>{item2.class[0].class_name}</td>
                              <td>{item2.class[0].student_roll_no}</td>
                              <td>
                                <Link to={"/AdminMain/ExamStudentReportCard/"+this.state.mExamMarkingSystem[0].report_card_id+"/"+this.state.mExamMarkingSystem[0].session_id+"/"+this.state.mExamMarkingSystem[0].report_card_name}>
                                    <div className="gravity_center width_match_parent margin_5 padding_5" style={{width:'120px'}}>
                                      <button className="bg_card_primary btn btn-success">Get Report Card</button>
                                    </div>
                                </Link>
                              </td>
                              
                            </tr>
                          )
                        })}

                          </tbody>
                        </Table>
                  </div>  
                           
                
                }

            </div>
            </div>
           
            
         
         
     </div>


           
           )
          }
          } />
             <Route path='/AdminMain/ExamStudentReportCard/:report_card_id/:session_id/:report_card_name'  component={ExamStudentReportCard} />
            
                 
    </switch>       
   </Router>  
    )
  }
 }
 }