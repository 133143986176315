import React, { Component ,createRef} from 'react'

import '../css/Admin_css/admin-customize.css';
import StudentInformation from '../ChildComponent/StudentInformation';
import PaymentHistory from './PaymentHistory';
import PaymentDetails from './PaymentDetails';
import TestModal from '../dialog/TestModal';

import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {Button} from 'react-bootstrap'
import DueFeeCollection from '../ChildComponent/DueFeeCollection';
import PaymentStatus from './PaymentStatus';

import { BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import FeeCard from './FeeCard';
import MdlSelectSingleStudent from '../dialog/MdlSelectSingleStudent';
import ModalFeeConfirmation from '../dialog/ModalFeeConfirmation';
import FeeOtherAmount from './FeeOtherAmount';
import myToast from '../Utils/MyToast';

export default class AdvancedPayment extends Component {
  constructor(props) {
    super(props)        
    this.slt_board=createRef();
    this.slt_medium=createRef();
    
   
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={  
        isOpen: false, 
        loggedIn:true,
        Loading:false, 
        showStudentInfo:false,
        
        showDueFee:false,
        isfeeSubmit:false,
        status:false,
        mClassData:[],
        mClassBoard:[],
        mClassMedium:[], 
        mFilteredClassSectionList:[],  
        mFilteredClassList:[],
        mStudentDetails:[],
        mStudentClass:[],
        mStudentFeeDetails:[],
        
        ENROLLMENT_NO:'',
        ADHAAR_NO:'',
        SESSION_ID:'',
        SESSION_NAME:''
        
    }
    
    this.onStudentIdChange= this.onStudentIdChange.bind(this);
    this.onAdhaarChange= this.onAdhaarChange.bind(this);
    this.handlerFeeSubmit = this.handlerFeeSubmit.bind(this);
    this.handlerSettlementRequest = this.handlerSettlementRequest.bind(this);
    this.HandleSelectedStudent = this.HandleSelectedStudent.bind(this);
    this.HandleClose = this.HandleClose.bind(this);
    
    
  }
  componentDidUpdate(){    
    window.onpopstate = e => {
      // alert("k");
       this.getStudentFeeDetails();
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  onStudentIdChange(e){
    this.setState({
      ENROLLMENT_NO: e.target.value
    })
}

onAdhaarChange(e){
    this.setState({
      ADHAAR_NO: e.target.value
    })
}
    // This method will be sent to the child component
handlerFeeSubmit(status) {
  
  this.state.ENROLLMENT_NO=this.state.mStudentDetails[0].student_enrollment_no;
  this.state.SESSION_ID=this.state.mStudentDetails[0].class[0].session_id;
  
       this.setState({
          isfeeSubmit: true,
          status: status
      }); 
     
  }
  handlerSettlementRequest() {

       this.getStudentFeeDetails();
       
}
  


  HandleShow = () => {

    this.setState({
      isOpen: !this.state.isOpen
    });   
  }
  
  HandleClose = () => {

    this.setState({
      isOpen:false
    });  
     
  }
  

  HandleSelectedStudent = (student_id,selected_item) => {
    this.state.ENROLLMENT_NO=student_id;
    this.state.SESSION_ID=selected_item.class[0].session_id;
    this.state.SESSION_NAME=selected_item.class[0].session_name;
    this.setState({
      
      isOpen:false
    });
    this.getStudentFeeDetails();
    
  }
  

getStudentFeeDetails = () => {
  
this.setState({
  Loading:true,
  mStudentDetails:[],
  mStudentFeeDetails:[],
  showStudentInfo:false,
  showDueFee:false,
  isfeeSubmit:false

})

const SCHOOL_CODE=SessionManager.getSchoolCode();
const FUNCTION_NAME='getStudentFeeDetails.php?';
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
console.log("URL>>>>>"+URL);

axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE,
    session_id:this.state.SESSION_ID,
    enrollment_no:this.state.ENROLLMENT_NO,
    aadhar_no:this.state.ADHAAR_NO
  },
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
    //alert(JSON.stringify(response));
    
    if(api_status=="OK")
    {
  
       this.setState({       
        mStudentDetails:response.student,
        showStudentInfo:true,
        mStudentFeeDetails:response.student[0].fee_details,
        showDueFee:true,
          
        })
      //console.log("mStudentFeeDetails>>>>>"+JSON.stringify(this.state.mStudentFeeDetails));
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    } 
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
  
}

getStudentFeeDetailsByFetch=()=>{
  
  this.setState({
    Loading:true,
    mStudentDetails:[],
    mStudentFeeDetails:[],
    showStudentInfo:false,
    showDueFee:false
  
  })
  
const SCHOOL_CODE=SessionManager.getSchoolCode();
var mSession=SessionManager.getCurrentSession();
const SESSION_ID=mSession[0].session_id;
const student_id=this.state.ENROLLMENT_NO;
const FUNCTION_NAME='getStudentFeeDetails.php?';
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME+"school_code="+SCHOOL_CODE+"&session_id="+SESSION_ID+"&enrollment_no="+student_id+"&aadhar_no="+"null";
console.log("URL>>>>>"+URL);
const options = {
  method: 'GET',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8'
  }

  /* body: JSON.stringify({
    school_code:SCHOOL_CODE,
    session_id:SESSION_ID,
    enrollment_no:this.state.ENROLLMENT_NO,
    aadhar_no:this.state.ADHAAR_NO
  }) */
};

fetch(URL, options)
  .then(res => res.json())
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      //console.log("Response2>>>>>"+response);
      //alert(JSON.stringify(response));
      
      let api_status=response.api_status;
      
      if(api_status=="OK")
      {
    
         this.setState({       
          mStudentDetails:response.student,
          showStudentInfo:true,
          mStudentFeeDetails:response.student[0].fee_details,
          showDueFee:true,
            
          })
        //console.log("mStudentFeeDetails>>>>>"+JSON.stringify(this.state.mStudentFeeDetails));
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      } 
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
}
  render() {

    
    if( this.state.loggedIn==false)
    {
    return <Redirect to='/Login'/>;
    }
    else if(this.state.Loading)
    {
    return <MyLoader/>
    }
    else if(this.state.isfeeSubmit)
    {
        return <PaymentStatus student_id={this.state.ENROLLMENT_NO} session_id={this.state.SESSION_ID}></PaymentStatus>
    }
    else{
    return (

      
      <Router>
        <switch>
          <Route path='/AdminMain/AdvancedPayment'  exact render={
            ()=>{
          return(
            <div className="content">
               <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Advanced Payment</a></li>
                </ul>
              </div>  
            </div>
            
                         
            <div className="animated fadeInUp" style={{margin:'0 auto',width:'84%',background:'#fff',padding:'25px'}}>
            
            <div className="row">
                <div className="col-sm-12 col-lg-12 text-center" data-toggle="modal" data-target="#exampleModal" onClick={this.HandleShow} style={{padding:'4px',color:'#fff',borderRadius:'11px',background:'red'}}>
                    <h3 className="arrow"> Select Students Manually</h3>
              </div>
            </div>
            <div className="row">
                 <div className="col-sm-12 col-md-12">
                    <div className="text-center">
                                     {/* <!-- Button trigger modal --> */} 
                    <h2 className="en-adhar" style={{color:'#bebbb7',paddingTop:'10px'}}> Search By Enrollment No/Adhar No  </h2>
                    </div>
                  </div>
            </div>
        
          <div className="row">
              <div className="col-sm-5 col-md-5">
                <div className="row">
                <input type="text" name="Enrollment" className="col-xs-9" placeholder="Enrollment No." value={this.state.ENROLLMENT_NO} onChange={this.onStudentIdChange}/> <span className="search-text col-xs-3" onClick={this.getStudentFeeDetails} style={{border:'none',cursor:'pointer',background:'#007bff'}}> SEARCH </span>
                </div>
              </div>
              <div className="col-sm-2 col-md-2">
                <h3 className="text-center or-cust"> OR </h3>
              </div>
              <div className="col-sm-5 col-md-5">
                <div className="row">
                <input type="text" name="Enrollment" className="col-xs-9" placeholder="Aadhaar No." value={this.state.ADHAAR_NO} onChange={this.onAdhaarChange}/> <span className="search-text col-xs-3" style={{border:'none',cursor:'pointer',background:'#007bff'}}> SEARCH </span>
                </div>
              </div> 
          </div>
  
            {this.state.showStudentInfo===true? <StudentInformation showStudentInfo={this.state.showStudentInfo} mStudentDetails={this.state.mStudentDetails}></StudentInformation>:''} 
            
            {this.state.showDueFee===true? <DueFeeCollection showDueFee={this.state.showDueFee} FeeDetails={this.state.mStudentFeeDetails} mStudentDetails={this.state.mStudentDetails} action={this.handlerFeeSubmit} action2={this.handlerSettlementRequest} ></DueFeeCollection>:''} 
            
            {this.state.isOpen===true? <MdlSelectSingleStudent action={this.HandleClose} action2={this.HandleSelectedStudent}></MdlSelectSingleStudent>:''}
           
  
                   </div>
                 </div>
              )
            }
           } />
    
            <Route path='/AdminMain/PaymentStatus'  component={PaymentStatus} />       
              
            <Route path='/AdminMain/PaymentHistory/:student_id/:session_id'  component={PaymentHistory} />       
            <Route path='/AdminMain/FeeCard'  component={FeeCard} />       
            <Route path='/AdminMain/FeeOtherAmount'  component={FeeOtherAmount} />       
      
        </switch>
        
       </Router>

      
      )
    }


      
  }
 
}

