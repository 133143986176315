import React, { Component,createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import OldDueCollection from './OldDueCollection';
import myToast from '../Utils/MyToast';
export default class OldDueList extends Component {
    constructor(props){
        super(props)
        this.slt_board=createRef();
        this.slt_medium=createRef();
      const token=localStorage.getItem("token");
      let loggedIn=true;
      if(token==null){
        loggedIn=false;
      }
    
      this.state={ 
    
          loggedIn:true,
          Loading:false,
          LoadingStudent:false,
          classBoard:[],
          classMedium:[],
          ClassSection:[],
          mFilteredClassList:[],
          mFilteredClassSectionList:[],
          mClassData:[], 
          CLASS_SELECTED_POSITION:0,
          mOldDueList:[], 
          mFilteredOldDueList:[], 
           
      }
    }
    componentDidMount()
    {
       this.getClass();
    }
    selectClass=(position)=>{
      
      this.state.CLASS_SELECTED_POSITION=position;    
      this.getOldDueList();
    }

    getClass(){
    
    this.setState({
      Loading:true
    })
    
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    var mSession=SessionManager.getCurrentSession();
    const SESSION_ID=mSession[0].session_id;
    const FUNCTION_NAME='getClass.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    // var params = new URLSearchParams();
      //  params.append('school_code', SCHOOL_CODE);
    
    axios.get(URL,{
      params:{school_code:SCHOOL_CODE,
        session_id:SESSION_ID}
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        Loading:false
       })
        console.log("Response2>>>>>"+response);
        let api_status=response.api_status;
       // alert(api_status);
        
        if(api_status=="OK")
        {
        
           this.setState({
            
            mClassData:response.response.class,
            classBoard:response.response.board,
            classMedium:response.response.medium,
            ClassSection:response.response.class[0].section
            })
            this.getClassList();
            console.log("response>>>>>"+this.state.mClassData);
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }
   
    onBoardChange = () => {
      this.state.CLASS_SELECTED_POSITION=0;
      this.getClassList();
      
    }
    onMediumChange = () => {
      this.state.CLASS_SELECTED_POSITION=0;
      this.getClassList();
      
    }
    getClassList()
    {
      let board_id=this.slt_board.current.selectedOptions[0].getAttribute('board_id');
      let medium_id=this.slt_medium.current.selectedOptions[0].getAttribute('medium_id');
     // alert(board_id);
     // alert(medium_id);
      
      this.state.mFilteredClassSectionList=[];
      var mFilteredClassList=[];
      for (var i=0; i < this.state.mClassData.length; i++) {
        let item=this.state.mClassData[i];
        if(item.board_id===board_id && item.medium_id===medium_id)
        {
      
          mFilteredClassList.push(item);
    
        }   
      }
      for (var i=0; i <mFilteredClassList.length; i++) {
        let item=mFilteredClassList[i];
        let SECTION=item.section;
                if(SECTION==="No")
                {
                  item["section_id"]="No";
                  this.state.mFilteredClassSectionList.push(item);
                }
                else {
      
                  var tempSection=[];
                  tempSection=item.section;
                  for (var j=0;j<tempSection.length;j++)
                  {
                      let item2=tempSection[j];
                      item2["board_id"]=board_id;
                      item2["board_name"]=item.board_name;
                      item2["medium_id"]=medium_id;
                      item2["medium_name"]=item.medium_name;               
                      this.state.mFilteredClassSectionList.push(item2);
                      
                  }
                }
               
      } 
      if(this.state.mFilteredClassSectionList.length!==0)
      {
        
        this.getOldDueList();
      }
      else{        
         this.setState({
          mOldDueList:[]
         })
      }
    }
    getOldDueList(){
      this.setState({
        LoadingStudent:true
      })
      this.state.mOldDueList=[];
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      var CLASS_ID=this.state.mFilteredClassSectionList[this.state.CLASS_SELECTED_POSITION].class_id;
      var SECTION_ID=this.state.mFilteredClassSectionList[this.state.CLASS_SELECTED_POSITION].section_id;
      var SESSION_ID=this.state.mFilteredClassSectionList[this.state.CLASS_SELECTED_POSITION].session_id;
      const FUNCTION_NAME='getClassStudentOldDueList.php'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      console.log("URL>>>>>"+URL)
      axios.get(URL,{
        params:{
          school_code:SCHOOL_CODE,
          class_id:CLASS_ID,
          section_id:SECTION_ID,
          session_id:SESSION_ID
        }
      }).then(res => res.data)
      .then((response) => {
       
        this.setState({ 
          LoadingStudent:false
         })
          let api_status=response.api_status;       
          if(api_status=="OK")
          {
          
              this.setState({              
                mOldDueList:response.data
              })
              
            this.getFilteredList();
                         
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
            this.getFilteredList();
                        
          }
      
        //console.log("response>>>>>"+api_status)
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        console.log("error>>>>>"+error);
        });
    }
getFilteredList()
{
      this.state.mFilteredOldDueList=[];
      this.state.total_amount=0;
      for (var i=0;i<this.state.mOldDueList.length;i++)
      {      
        if(this.state.mOldDueList[i].old_due!==null)
        {
           this.state.mFilteredOldDueList.push(this.state.mOldDueList[i]);
           
        }
        
      }

      this.setState({

      })
} 
getTotalAmount(){
  var total_due=0;
  var total_paid=0;
  for (let index = 0; index < this.state.mFilteredOldDueList.length; index++) {
      total_due=total_due+this.getTotalDue(index);
      total_paid=total_paid+this.getTotalPaid(index);
  }
  var tag=this.props.match.params.tag;
  if(tag=="1")
  {
    return "Total Due: INR "+total_due;
  }
  else if(tag=="2")
  {
    return "Total Recieved: INR "+total_paid;
  }
  else if(tag=="3")
  {
    return "Current Old Due: INR "+(total_due-total_paid);
  }
}
getTotalDue(index){
  var amt=0;
  var mOldDue=[];
 
  mOldDue=this.state.mFilteredOldDueList[index].old_due;
  for (let i = 0; i <mOldDue.length; i++) {
    
     amt=amt+Number(mOldDue[i].amount);
     
  }
  return amt;

}
getTotalPaid(index){
  var amt=0;
  var mOldDue=[];
 
  mOldDue=this.state.mFilteredOldDueList[index].old_due;
  for (let i = 0; i <mOldDue.length; i++) {
    
     amt=amt+Number(mOldDue[i].amount_paid);
     
  }
  return amt;

}
saveData= (item) =>{
  //alert("saved");
  localStorage.setItem("selected_student",JSON.stringify(item));

}
      render() {
        if( this.state.loggedIn==false)
        {
         return <Redirect to='/Login'/>;
        }
        if(this.state.Loading)
        {
           return <MyLoader/>
        }else{

       return (
         
      <Router>
      <switch>   
      <Route path='/AdminMain/OldDueList/:tag'   render={
        ()=>{

          return(

          <div className="content">
          <div className="content-header" style={{background:COLORS.primary_color_shade5}}>
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" style={{color:COLORS.white}} /><a href="#"> Old Due List </a></li>
              </ul>
            </div>
          </div>   
          <div className="row m-0">
                <div className="col-sm-6 col-md-6" style={{background:COLORS.primary_color_shade3}}>
                <div className="">
                <h4 className="selectMultiplst-h4" style={{background:COLORS.primary_color_shade3}}>Select Boards:</h4>
                <select className="select-field" onChange={this.onBoardChange} ref = {this.slt_board}>
                {this.state.classBoard.map((item, index) => ( 
                <option board_id={item.board_id}>{item.board_name}</option>
                   
                   ))}
                </select>
                </div>
                </div>
                 <div className="col-sm-6 col-md-6" style={{background:COLORS.primary_color_shade3}}>
                  <div className="">
                   <h4 className="selectMultiplst-h4" style={{background:COLORS.primary_color_shade3}}>Select Medium:</h4>
                   <select className="select-field" onChange={this.onMediumChange} ref = {this.slt_medium}>
                   {this.state.classMedium.map((item, index) => ( 
                   <option medium_id={item.medium_id}>{item.medium_name}</option>
                   ))}
                 
                   </select>
                  </div>
                </div>

               </div> 
               <section className="exam-section-1" style={{background:COLORS.primary_color_shade3}}>
              <div className="row class-li">
              {this.state.mFilteredClassSectionList.map((item, index) => (
              <div className="col-sm-1 col-md-1">
               <div className={this.state.CLASS_SELECTED_POSITION===index?"bg_class_select gravity_center":"bg_class_deselect col-centered"} onClick={e=>this.selectClass(index)} style={{cursor:'pointer'}}> <p> {item.section==='No'?'Class:'+item.class_name:item.class_name+':'+item.section_name} </p></div>
                </div>
              ))}
                </div>
                </section> 

                
                <div className="row">
                <div className="col-sm-12 col-md-12">
              <p className="du-old">{this.getTotalAmount()}</p>                                    
                </div>    
                </div>
                <section className="">
                <div className="row">
                {this.state.LoadingStudent?<div><MyLoader/></div>:
              
              this.state.mFilteredOldDueList.map((item, index) => (  
            
                <div className="col-sm-4 col-md-4 text-center rld" onClick={()=>this.saveData(item)}> 
                <Link to={{pathname:'/AdminMain/OldDueCollection'}}>
                 <div className="row" >
                 <div className="col-sm-4 pr-0">
                   <div className="profil-im"><img src={item.student_img_path} alt="student due" className="oldduelist-img-border"/></div>
                 </div>
                 
                 <div className="col-sm-8 profil-d">
                  <h5> {item.stu_name}<span> {item.class.length!==0?item.class[0].class_name:'Class Name'} </span><span>:{item.class.length!==0?item.class[0].section_name:'Section Name'}</span> </h5>
                  <p> Father: <span>{item.father_name}</span> </p>
                  <p className="weight-d7"> Total Old Due: <span>{"INR "+this.getTotalDue(index)} </span> </p>                 
                  <p className="weight-d7"> Total Paid: <span>{"INR "+this.getTotalPaid(index)} </span> </p>                  
                  <p className="weight-d7"> Current Old Due: <span>{"INR "+(this.getTotalDue(index)-this.getTotalPaid(index))} </span> </p>
                  
                 </div>
                </div>
                </Link>
                </div> 
              ))}
               
                </div>
                </section>           
                <div className="row">
                <div className="col-sm-12 col-md-12">
               <button className="btn btn-success" style={{width:'100%',fontSize:'24px', margin:'30px 0px 15px 0px',background:COLORS.primary_color_shade5,border:COLORS.primary_color_shade5}}> Add More </button>
                                    
                </div>    
                </div>
               </div>
     
        )
         }
        } />                       
   
            <Route path='/AdminMain/OldDueCollection'  component={OldDueCollection} />        
          </switch>
     
        </Router>

     )
} 
}
}
