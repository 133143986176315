import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import UpdateStudentDetails from './UpdateStudentDetails';
import Acknowledgement from './Acknowledgement';
import myToast from '../Utils/MyToast';
export default class StudentDetails extends Component {
  constructor(props){
    super(props)
  const token=localStorage.getItem("token");
  let loggedIn=true;
  if(token==null){
    loggedIn=false;
  }

  this.state={

      loggedIn:true,
      Loading:false, 
      mStudentDetails:[],
      mclass:[]  
  }

}
componentDidMount()
{
this.StudentDetails();
}
ClstuDetails=()=>{
   var arr=[];
  arr=this.state.mStudentDetails;
  localStorage.setItem("STDetails",JSON.stringify(arr));
  window.location.reload();
  window.location="/AdminMain/UpdateStudentDetails";
}
StudentDetails(){

this.setState({
  Loading:true
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
var mSession=SessionManager.getCurrentSession();
const SESSION_ID=mSession[0].session_id;
const STUDENT_ID=this.props.match.params.student_id;

const Adhar_ID="";
const FUNCTION_NAME='searchStudentDetails.php'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
console.log("URL>>>>>"+URL)
axios.get(URL,{
  params:{school_code:SCHOOL_CODE,enrollment_no:STUDENT_ID,session_id:SESSION_ID,aadhar_no:Adhar_ID}
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
   // alert(api_status);
    
    if(api_status=="OK")
    {
    
       this.setState({
        
        mStudentDetails:response.student,
        mclass:response.student[0].class
        })
        console.log("response>>>>>"+this.state.mStudentDetails);
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}
  render() {
    if( this.state.loggedIn==false)
    {
     return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }else{
    
      return (
        <Router>
        <switch>   
        <Route path='/AdminMain/StudentDetails/'   render={
          ()=>{
  
            return(
       <div className="content content-customize-area-student-details">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Student Details </a></li>
              </ul>
            </div>
          </div>     

          {this.state.mStudentDetails.map((item, index) => ( 
            <div className="m-0-100">
              <div className="row">
                 <div className="col-sm-12" style={{height:'120px'}}>
                   <div style={{position:'absolute',top:'-130px',left:'290px'}}>
                    <p className="photo-st-21"> <img src={item.student_img_path} onError={(e)=>{e.target.onerror = null; e.target.src="https://www.bkgymswim.com.au/wp-content/uploads/2017/08/image_large-600x600.png"}} alt="" style={{width:'100%'}}/>
                    </p>
                    {/* <div className="row photo-row-bottom">
                      <span className="col-sm-5">Upload Photo</span>
                      <span className="col-sm-7"><input type="file" name="upload photo"/></span>
                    </div> */}
                    <img src="https://www.searchpng.com/wp-content/uploads/2019/02/Instagram-Camera-Icon-PNG-715x715.png" alt="" className="cam"/>
                    </div>
                 </div>
                 <div className="col-sm-12 border-l-r mb-3">
                  <h1> {item.stu_name} </h1>
                  <p><strong> Father: </strong> <span> {item.father_name}</span><strong> Board: </strong><span>{item.class[0].board_name}</span> <strong> Medium: </strong><span>{item.class[0].medium_name}</span><span> <strong> Roll No.: </strong></span><span>{item.class[0].student_roll_no}</span><span> <strong> Class: </strong></span> <span> {item.class[0].class_name} </span> </p>
                 </div>
                </div>

                { /* Add student Details section */ }

                <div className="row" style={{background:'#007bff80'}}>
                <div className="col-sm-12 col-lg-12 text-center">
                <div className="bg-info-1">
                <div onClick={this.ClstuDetails}>
                <h3 className="text-white text-center">
                    Basic Details &nbsp; <button style={{width:'40px',height:'40px',borderRadius:'50%', border:'1px solid #fea223'}}>
                    <img src="https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/512/Edit-validated-icon.png" style={{width:'100%'}}/>
                 </button>  </h3>
                 </div>
                </div> 
              </div>
               </div>
               
               <div className="row" style={{background:'#5bc0de1a',paddingBottom:'20px'}}>
                <div className="col-sm-12 col-lg-12 stu-details">
                  <br></br>
                <table border="1">
                <tbody>
          <tr><td>Student Name</td><td>{item.stu_name}</td></tr>
          <tr><td>Adhar No.</td><td>{item.adharcard_no}</td></tr>
          <tr><td>Gender</td><td>{item.gender}</td></tr>
          <tr><td>DOB</td><td>{item.birthday}</td></tr>
          <tr><td>Religion</td><td>{item.religion}</td></tr>
          <tr><td>Nationality</td><td>{item.nationality}</td></tr>
          <tr><td>Community</td><td>{item.community}</td></tr>
          </tbody>
                </table>
                 </div>
               </div>

               {/* Add current address section */}

              <div className="row text-white" style={{background:'#007bff80'}}>
                <div className="col-sm-12 col-lg-12 text-center">
                <h3> Current Address </h3>
              </div>
               </div>
               <div className="row row-p-20" style={{background:'#5bc0de1a'}}>
               <div className="col-sm-12">
               <div className="class-list-box-c"><span><b>Address:</b>{item.current_address}</span></div>

               </div>
               </div>

                {/* Add Permanet Address section */}

               <div className="row text-white" style={{background:'#007bff80'}}>
                <div className="col-sm-12 col-lg-12 text-center">
                <h3> Permanent Address </h3>
              </div>
               </div>

               <div className="row row-p-20" style={{background:'#5bc0de1a'}}>
               <div className="col-sm-12">
               <div className="class-list-box-c"><span><b>Address:</b>{item.permanent_address}</span></div>

               </div>


               </div>

              {/* Add Contact Details Section  */}

              <div className="row text-white" style={{background:'#007bff80'}}>
                <div className="col-sm-12 col-lg-12 text-center">
                <h3> Contact Details </h3>
              </div>
               </div>
               <div className="row row-p-20 contact-details" style={{background:'#fffcf5'}}>

<div className="col-sm-3">
<div className=""><span> Call: </span></div>

</div>
<div className="col-sm-3">
<div className=""><span><i className="fa fa-phone"></i> &nbsp; {item.contact_details[0].contact_number} </span></div>

</div>
<div className="col-sm-3">
<div className=""><span> {item.contact_details[0].contact_person_name} ({item.contact_details[0].contact_person_relation})</span></div>

</div>
<div className="col-sm-3">
<div className=""><span>Email: &nbsp; {item.email_id} </span></div>
</div>
</div>

   {/* Add Family Details Section  */}

 <div className="row text-white" style={{background:'#007bff80'}}>

                <div className="col-sm-12 col-lg-12 text-center">
                <h3> Family Details </h3>
              </div>
               </div>
               <div className="row row-p-20 contact-details" style={{background:'#fffcf5'}}>

<div className="col-sm-6">
<div className="text-center class-list-box">
<p className="family-p"> Father </p>
  <div className="family-details-poto">
  <img src={item.father_img_path} alt=""/>
  </div>
  <p className="family-p-bottom"> {item.father_name} ({item.father_occupation})</p>
  </div>

</div>
<div className="col-sm-6">
<div className="text-center class-list-box">
<p className="family-p">Mother</p>
<div className="family-details-poto">
<img src={item.mother_img_path} alt=""/>
  </div>
  <p className="family-p-bottom"> {item.family_details[0].mother_name} ({item.family_details[0].mother_occupation}) </p>
  </div>
</div>
</div>
<div className="row">
                <div className="col-sm-12 col-md-12">
                <Link to={{pathname:'/AdminMain/Acknowledgement/'+item.student_enrollment_no+"/"+item.class[0].session_id}}>
               <button className="btn btn-primary" style={{width:'100%',fontSize:'24px', margin:'30px 0px 15px 0px'}}> Acknowledgement </button>
                </Link>                    
                </div>    
                </div>
</div>
  ))}
               </div>
                  )
                }
               } />                       
                 <Route path='/AdminMain/Acknowledgement/:Enrollment_no/:Session_id'  component={Acknowledgement} /> 
                 <Route path='/AdminMain/UpdateStudentDetails'  component={UpdateStudentDetails} />        
                 </switch>
                 
                </Router>

)
} 
}

}
