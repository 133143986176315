import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import Iframe from 'react-iframe'
import MdlSelectMultipleStudent from '../dialog/MdlSelectMultipleStudent';
import SelectClass from '../ChildComponent/SelectClass';
export default class ReportViewClass extends Component {
 
     
  constructor(props){
            super(props)
        
            const token=localStorage.getItem("token");
            let loggedIn=true;
            if(token==null){
              loggedIn=false;
            }
        
            this.state={
        
                loggedIn:true,
                Loading:false,  
                showPreview:false,
                iFrameHeight:'0px',
                FILE_URL:'',
                FILE_NAME:'',
                FILE_DIRECTORY:'',
                SESSION_ID:"",
                CLASS_ID:"",
                SECTION_ID:"",
                SELECTED_CLASS_POSITION:0
               

            }

            this.CallBackClassSelect= this.CallBackClassSelect.bind(this);
          
        }

        componentDidMount()
        {
          window.scrollTo(0, 0);
          this.state.FILE_DIRECTORY=this.props.match.params.file_directory;
          this.state.FILE_NAME=this.props.match.params.file_name;

        

       // alert( this.state.FILE_DIRECTORY);
    
        }
      
      
        CallBackClassSelect = (class_id,section_id,session_id,selected_position) => {
  
          this.state.CLASS_ID=class_id;
          this.state.SECTION_ID=section_id;
          this.state.SESSION_ID=session_id;
          this.state.SELECTED_CLASS_POSITION=selected_position;
          //alert("Back"+class_id+"/"+section_id+"/"+session_id);
          
          this.setFileUrL();
          
        }
      
  setFileUrL(){
  
    const SCHOOL_CODE=SessionManager.getSchoolCode();

    this.state.FILE_URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR_PRINTABLE+this.state.FILE_DIRECTORY+"/"+this.state.FILE_NAME+'?&class_id='+this.state.CLASS_ID+'&section_id='+this.state.SECTION_ID+'&session_id='+this.state.SESSION_ID+'&school_code='+SCHOOL_CODE;
    //console.log("FILE_URL",this.state.FILE_URL);
    // alert(this.state.FILE_URL);
    this.setState({
     showPreview:true  
    });
  }        
          autoResize=()=>{
              //alert("loaded");
              var iFrame = document.getElementById('iFrame1');
             
              iFrame.width  = iFrame.contentWindow.document.body.scrollWidth;
              iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
             
             
          }
        
  
       
          render() {
            if( this.state.loggedIn==false)
            {
             return <Redirect to='/Login'/>;
            }
            if(this.state.Loading)
            {
               return <MyLoader/>
            }
            else{
            return ( 
              
        
          <div className="content">
              <div className="content-header" style={{background:COLORS.primary_color_shade5}}>
                <div className="leftside-content-header">
                  <ul className="breadcrumbs">
                    <li><i className="fa fa-home" aria-hidden="true" style={{color:COLORS.white}} /><a href="#">Report Management </a></li>
                  </ul>
                </div>
              </div>  
              
          <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'10px'}}>
         
          <SelectClass action={this.CallBackClassSelect} selected_position='0'></SelectClass>
                         
            <div className="row" style={{margin:'0px 10px 0px 10px'}}>
                <h5 className="">Preview</h5>
            </div>
            
            
             
              <div className="row"  style={{margin:'0px 10px 0px 10px'}}> 
              {this.state.showPreview ? <div><MyLoader/></div> 
                      
                      :''}   
                <Iframe url={this.state.FILE_URL}
                        width={this.state.showPreview ?'0%':'100%'}
                        height={this.state.showPreview ?'0px':'800px'}
                        overflow="auto"
                        id="iFrame1"
                        className=""
                    
                        display="initial"
                        position="relative"
                    
                      
                        onLoad={() => {
                          //this.autoResize();
                
                          this.setState({
                            showPreview:false
                          })
                      }} 
                      />
                    
              
              </div> 
             
          </div>
            
         </div>
  
  )
}    
}
 
}