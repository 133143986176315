import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class ExamPaperSettings extends Component {
    constructor(props){
        super(props)
      const token=localStorage.getItem("token");
      let loggedIn=true;
      if(token==null){
        loggedIn=false;
      }
    
      this.state={
    
          loggedIn:true,
          Loading:false, 
          getClass:[],
          classBoard:[],
          classMedium:[],
          ClassSection:[]    
      }
    //  this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }
    componentDidMount()
    {
    this.getClass();
    }
    getClass(){
    
    this.setState({
      Loading:true
    })
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    
    const FUNCTION_NAME='getClass.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    // var params = new URLSearchParams();
      //  params.append('school_code', SCHOOL_CODE);
    
    axios.get(URL,{
      params:{school_code:SCHOOL_CODE}
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        Loading:false
       })
        console.log("Response2>>>>>"+response);
        let api_status=response.api_status;
       // alert(api_status);
        
        if(api_status=="OK")
        {
        
           this.setState({
            
            getClass:response.response.class,
            classBoard:response.response.board,
            classMedium:response.response.medium,
            ClassSection:response.response.class[0].section
            })
            console.log("response>>>>>"+this.state.getClass);
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }
    handleDropdownChange(e){
        alert("hhh");
   // console.log("ggggggggggggggg>>>>>>>>>>");
    }

      render() {
        if( this.state.loggedIn==false)
        {
         return <Redirect to='/Login'/>;
        }
        if(this.state.Loading)
        {
           return <MyLoader/>
        }else{

    return (
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Exam Paper Settings </a></li>
              </ul>
            </div>
          </div>   
          <div className="row">
                <div className="col-sm-6 col-md-6">
                <div className="">
                <h4 className="selectMultiplst-h4">Select Boards:</h4>
                <select className="select-field" onChange={this.handleDropdownChange}>
                {this.state.classBoard.map((item, index) => ( 
                   <option>{item.board_name}</option>
                   
                   ))}
                </select>
                </div>
                </div>
                 <div className="col-sm-6 col-md-6">
                  <div className="">
                      <h4 className="selectMultiplst-h4">Select Medium:</h4>
                   <select className="select-field">
                   {this.state.classMedium.map((item, index) => ( 
                   <option>{item.medium_name}</option>
                   ))}
                 
                   </select>
                  </div>
                </div>

               </div>
               <section className="exam-section-1">
              <div className="row class-li">
              <div className="col-sm-1 col-md-1">
               <div className="exam-p-setting-cls"> <p> Nursery B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> UKG B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> LKG B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> I B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> II B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> III B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> IV B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> V B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> VI B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> VII B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> VIII B1 </p></div>
                </div>
                <div className="col-sm-1 col-md-1">
                <div className="exam-p-setting-cls"> <p> IX B1 </p></div>
                </div>
                </div>
                </section>
                <section className="exam-section-2">
                <div className="row">
                <div className="col-sm-12 col-md-12">
                 <h4> Select Subject </h4>   
                </div>
                <div className="col-sm-2 col-md-2">
                <div className="exam-p-setting-sub"> <p> ENGLISH </p></div>
                </div>
                <div className="col-sm-2 col-md-2">
                <div className="exam-p-setting-sub"> <p> HINDI </p></div>
                </div>
                <div className="col-sm-2 col-md-2">
                <div className="exam-p-setting-sub"> <p> MATHMETICS </p></div>
                </div>
                <div className="col-sm-2 col-md-2">
                <div className="exam-p-setting-sub"> <p> G.K. </p></div>
                </div>
                <div className="col-sm-2 col-md-2">
                <div className="exam-p-setting-sub"> <p> URDU </p></div>
                </div>
                <div className="col-sm-2 col-md-2">
                <div className="exam-p-setting-sub"> <p> DROWING </p></div>
                </div>
                <div className="col-sm-2 col-md-2">
                <div className="exam-p-setting-sub"> <p> CONVERSATION </p></div>
                </div>
                <div className="col-sm-2 col-md-2">
                <div className="exam-p-setting-sub"> <p> GAMES </p></div>
                </div>
                </div>    
                </section>
                <section className="exam-section-3">
                <div className="row">
                <div className="col-sm-12 col-md-12">
                 <h4> Subject Parts </h4>   
                </div>
                <div className="col-sm-12 col-md-12">
                 <p className="text-justify">
                 <span> ENGLISH Grammer, </span>
                 <span> ENGLISH Grammer, </span>
                 <span> ENGLISH Grammer, </span>
                 <span> ENGLISH Grammer, </span>
                 <span> ENGLISH Grammer, </span>
                 <span> ENGLISH Grammer </span>
                 </p>   
                </div>
                </div>
                </section>
                <section className="exam-section-4">
                <div className="row">
                <div className="col-sm-12 col-md-12">
                 <h4> Subject Paper </h4>   
                </div>
                <div className="col-sm-12 col-md-12 text-center">
                    <h6> Paper Name: <span> ENGLISH </span></h6>
                 <p>
                  Marking Type:<span> Mark system(Max Mark-100)</span>
                 </p> 
                 <hr/>
                 <h6 className="text-info"> Details </h6>  
                 <p>
                 <span>1- English Grammer (50), </span>
                 <span>2- English Dictation (50) </span>
                 </p> 
                </div>
                </div>
                <div className="row">
                <div className="col-sm-12 col-md-12 text-center">
                    <hr/>
               <button className="btn btn-info" style={{width:'20%',fontSize:'24px', margin:'30px 0px 15px 0px'}}> Create New Paper </button>
                                    
                </div>    
                </div>
                </section>
                <div className="row">
                <div className="col-sm-12 col-md-12">
               <button className="btn btn-success" style={{width:'100%',fontSize:'24px', margin:'30px 0px 15px 0px'}}> Exam Schedule </button>
                                    
                </div>    
                </div>
               </div>
  
  )
} 
}
}
