import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import SessionManager from '../Utils/SessionManager';
import axios from 'axios'
import '../css/css.css';
import '../css/Admin_css/admin-customize.css';

import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';

export default class NavMenuAdmin extends Component {
    constructor(props){
        super(props)
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
        
        this.state={
            loggedIn:true,
            Loading:false,
            redirect:false,
            path:'',
            staff_name:'',
            staff_role:'',
            login_type:'',
            staff_image_path:'',
            mModules:[],
            mSchoolDetails:[]
        }
        this.onLogoutClick=this.onLogoutClick.bind(this);
        this.changePage=this.changePage.bind(this);
    }    
    
    onLogoutClick(){
        localStorage.removeItem("token");
        this.setState({
          loggedIn:true,
         
        })
      }
changePage=(mPath)=> {
 // alert(mPath);
  window.location.href = mPath; 
 
  //window.location.reload();
 
} 
    
componentDidMount()
{
  var mStaffData=SessionManager.getStaffDetails();
  this.state.staff_name=mStaffData.staff_name;
  this.state.staff_role=mStaffData.staff_role;
  this.state.staff_image_path=mStaffData.staff_image_path;
  this.state.login_type=mStaffData.login_type;
      
        if(this.state.login_type==process.env.REACT_APP_LOGIN_TYPE_ADMIN)
        {
          if(this.state.mModules.length===0)
          {
            this.getAdminModules();
          }
          
        }
        else if(this.state.login_type==process.env.REACT_APP_LOGIN_TYPE_CLERK)
        {                
          if(this.state.mModules.length===0)
          {
            this.getAllActiveModulesOfClerk();
          }
          
        }
        else{

        }
        
        if(this.state.mSchoolDetails.length===0)
        {
          this.getSchoolDetails();
        }

}
getAdminModules(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  var SYSTEM_CODE=process.env.REACT_APP_SYSTEM_CODE;
  const FUNCTION_NAME='getAllAdminModules.php?'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL, {
    params: {
      school_code:SCHOOL_CODE,  
      system_code:SYSTEM_CODE,  
    }

  }).then(res => res.data)
  .then((response) => {
    this.setState({ 
       Loading:false
      })
      //alert(JSON.stringify(response));
      let api_status=response.api_status;
      if(api_status=="OK")
      {
      
         this.setState({
          
          mModules:response.data
          })
          console.log("response>>>>>"+this.state.adminModules)
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }

    console.log("response>>>>>"+api_status)
   })
}

getAllActiveModulesOfClerk(){
  this.setState({
    Loading:true
  })
  const FUNCTION_NAME='getAllActiveModulesOfClerk.php?'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL, {
    params: {
    //  school_code:SCHOOL_CODE,
    
    }

  }).then(res => res.data)
  .then((response) => {
    this.setState({ 
       Loading:false
      })
      let api_status=response.api_status;
      if(api_status=="OK")
      {
      
        
         this.setState({
          
          mModules:response.data
          })
         // console.log("response_AllActiveModulesOfClerk>>>>>"+this.state.AllActiveModulesOfClerk)
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
   })
}

getSchoolDetails(){
  
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getSchoolDetails.php?'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL, {
    params: {
      school_code:SCHOOL_CODE,
    
    }

  }).then(res => res.data)
  .then((response) => {
    
      let api_status=response.api_status;
      //alert(JSON.stringify(response));
      if(api_status=="OK")
      {
      
         this.setState({
          
          mSchoolDetails:response.data
          })
          //console.log("response>>>>>"+this.state.adminModules)
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
   })
}

    render() {

        if( this.state.loggedIn==false)
        {
         return <Redirect to='/'/>;
         
        }
        
        
        else{
         

        return (
         <div className="wrap">
           <div className="page-header" style={{background:COLORS.primary_color_shade7}}>
          <section>
            <div className="row">
            <div className="col-md-3">
            <div className="leftside-header">
            <div className="logo">
               <Link to='/AdminMain'className="logo-text" style={{textDecoration:'none'}}> Home</Link>
            </div>
            <div id="menu-toggle" className="visible-xs toggle-left-sidebar" data-toggle-class="left-sidebar-open" data-target="html">
              <i className="fa fa-bars" aria-label="Toggle sidebar" />
            </div>
          </div>
           </div>  
           <div className="col-md-6 text-center">
             <br/>
        <h1 className="">{this.state.mSchoolDetails.length!==0?this.state.mSchoolDetails[0].school_name:''}</h1>
           </div> 
           <div className="col-md-3">
           <div className="rightside-header">
            <div className="header-middle" />
            
            <div className="header-section" id="user-headerbox">
              <div className="user-header-wrap">
                <div className="user-photo">
                  <img src={this.state.staff_image_path} alt="img" />
                </div>
                <div className="user-info">
                  <span className="user-name">{this.state.staff_name}</span>
                  <span className="user-profile">{this.state.staff_role}</span>
                </div>
                <i className="fa fa-plus icon-open" aria-hidden="true" />
                <i className="fa fa-minus icon-close" aria-hidden="true" />
              </div>
              <div className="user-options dropdown-box">
                <div className="drop-content basic">
                  <ul>
                    <li><a href="#"><i className="fa fa-user" aria-hidden="true" /> Profile</a></li>
                    <li ><a  title="Logout" onClick={this.onLogoutClick}><i className="fa fa-sign-out" aria-hidden="true"  /> 
                    Logout
                    { <button id="logOut" className="btn btn-default" onClick={this.onLogoutClick}>Logout</button>}
                    </a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="header-separator" />
           
          </div>
           </div>  
            </div>
          </section>
        </div>
           <div className="page-body">
           <div className="left-sidebar">
            {/* left sidebar HEADER */}
           
            {/* NAVIGATION }
            {/* ========================================================= */}
            <div id="left-nav" className="nano" style={{background:COLORS.primary_color_shade7}}>
              <div className="nano-content">
                <nav>
                  <ul className="nav" id="main-nav">
                    {/*HOME*/}
                    <li className="active-item">
                    <Link to='/AdminMain'style={{background:COLORS.primary_color_shade8}}  onClick={() => { this.changePage('/AdminMain') }}><i className="fa fa-home" aria-hidden="true" />
                    <span>Dashboard</span></Link>
                       
                    
                    </li>
                    {this.state.mModules.map((item, index) => (
                             <li className="active-item">
                             <Link to={'/AdminMain/'+item.component_name} style={{background:COLORS.primary_color_shade7}} onClick={() => { this.changePage('/AdminMain/'+item.component_name) }}><img src={item.icon_img} alt={"icon"} className="admin-menu-img-size-1" />
                             <span>{item.module_name}</span></Link>
                                
                             
                             </li>
                     ))}
                   
                   </ul>
                </nav>
              </div>
            </div>
          </div>
          

          <a href="#" className="scroll-to-top"><i className="fa fa-angle-double-up" /></a>
        </div>
        

        </div>  
       
      
        )
      }



    }
}
