import React, { Component } from 'react'
import { BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import FeeCollection from './FeeCollection';
import m1i from '../resources/images/m1i.png'
import m2i from '../resources/images/m2i.png'
import m3i from '../resources/images/m3i.png'
import '../css/Admin_css/admin-customize.css';
import CurrentDue from './CurrentDue';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import axios from 'axios';
import TotalRecieved from './TotalRecieved';
import myToast from '../Utils/MyToast';
export default class FeeDashBoard extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
    this.state={   
        loggedIn:true,
        Loading:false, 
        mFeeDashData:"", 
        todays_collection:0,
        mDate:'',
        mFeeDataByDate:[],
    }
  
  }
  
componentDidMount()
{
 
   this.getFeeDashBoard();
   var today = new Date();
   let day = today.getDate();
   let month = today.getMonth() + 1;
   let year = today.getFullYear();
  
   var mMonth=month>9?month:"0"+month;
   var mDay=day>9?day:"0"+day;
   var mDate=year+"-"+mMonth+"-"+mDay;
   this.state.mDate=mDate;
   
   //alert(mDate);

  this.getCollectionByDate();
}
getFeeDashBoard(){

  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  var mSession=SessionManager.getCurrentSession();
  const SESSION_ID=mSession[0].session_id;
  //alert(SESSION_ID);
  
  const FUNCTION_NAME='getFeeDashboard.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL,{
      params:{school_code:SCHOOL_CODE,
      session_id:SESSION_ID
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      console.log("Response2>>>>>"+response);
      let api_status=response.api_status;
     // alert(api_status);
      
      if(api_status=="OK")
      {
      
         this.setState({       
             mFeeDashData:response.dashboard,            
         })
         ///console.log("mFeeDashData>>>>>"+this.state.mFeeDashData);
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      } 
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
       console.log("error>>>>>"+error);
    });
  }
  getCollectionByDate(){

  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  
  const FUNCTION_NAME='getFeeCollectionByDate.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL,{
      params:{
        school_code:SCHOOL_CODE,
        date:this.state.mDate
    }
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
      //alert("Response2>>>>>"+JSON.stringify(response));
      let api_status=response.api_status;
      
      if(api_status=="OK")
      {
      
         this.setState({       
          mFeeDataByDate:response.data, 
                  
         })
        
         this.getCollectionAmount();
      }
      else{
        let api_remark=response.api_remark;
        //myToast.notify_warning(api_remark);
      } 
  
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
       console.log("error>>>>>"+error);
    });
  }
  getCollectionAmount(){
        var total=0;
        for (var i=0;i<this.state.mFeeDataByDate.length;i++)
        {
                total=total+Number(this.state.mFeeDataByDate[i].paid_amount);
                
        }
        this.setState({
          todays_collection:total
        })
        
  }

  render() {
    if( this.state.loggedIn==false)
    {
       return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }
    else{
    return (
      <Router>
        <switch>
          <Route path={'/AdminMain/FeeDashBoard'}  exact render={
            ()=>{
          return(
            
               <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">FeeDashBoard</a></li>
              </ul>
            </div> 
          </div>

                <div className="row fee-dash-b">
                <div className="col-sm-12 col-md-12 fee-d-date-s">
                  <h3> <span className="left-arrow"> &#x27A4; </span> Today Collection <span className="right-arrow"> &#x27A4; </span> </h3>
                </div>
                <div className="col-sm-4 col-lg-4">  
                </div>
                <div className="col-xl-4 col-md-4 mb-4 mt-4">
  <div className="card border-left-primary shadow h-100 py-2">
    <div className="card-body">
      <div className="row no-gutters align-items-center">
        <div className="col mr-2">
          <div className="text-lg font-weight-bold text-primary text-uppercase mb-1">Rs:{this.state.todays_collection}</div>
         
        </div>
        <div className="col-auto">
          <i className="fa-2x text-gray-300"><img src={m3i}/></i>
        </div>
      </div>
      
      <div className="row bg-primary" style={{margin:'10px -16px -23px -17px'}}>
      <div className="col-xl-12 col-md-12 m-0 p-0 lin">
       <h3> <Link to="./" class="btn bg-primary btn-block border-bottom text-white" style={{fontSize:'20px',textDecoration:'none'}}> <span className="cld-e"> Collection Details </span> </Link> </h3>
       <h3> <Link to={{pathname:'/AdminMain/FeeCollection'}} class="btn bg-primary  btn-block text-white" style={{fontSize:'24px',textDecoration:'none',width:'165px',margin:'0 auto'}}> <span className="cld"> Collect Fee </span>  </Link>  </h3>
    </div>
      </div>
    </div>
  </div>
</div>
                <div className="col-sm-4 col-lg-4">  
                </div>
               </div>

              <div className="row fee-d-button-bg-s">
              <div className="col-sm-d10 col-md-d10 ">
               <div className="exam-p-setting-cls bg-primary"> <p> Custom </p></div>
                </div>
                <div className="col-sm-d10 col-md-d10 ">
                <div className="exam-p-setting-cls"> <p> Today </p></div>
                </div>
                <div className="col-sm-d10 col-md-d10 ">
                <div className="exam-p-setting-cls"> <p> Yesturday </p></div>
                </div>
                <div className="col-sm-d10 col-md-d10 ">
                <div className="exam-p-setting-cls"> <p> Last 7 Days </p></div>
                </div>
                <div className="col-sm-d10 col-md-d10 ">
                <div className="exam-p-setting-cls"> <p> Last 30 Days </p></div>
                </div>
                <div className="col-sm-d10 col-md-d10 ">
                <div className="exam-p-setting-cls"> <p> This Month </p></div>
                </div>
                <div className="col-sm-d10 col-md-d10 ">
                <div className="exam-p-setting-cls"> <p> Privious Months </p></div>
                </div>
                <div className="col-sm-d10 col-md-d10 ">
                <div className="exam-p-setting-cls"> <p> This Week </p></div>
                </div>
                <div className="col-sm-d10 col-md-d10 ">
                <div className="exam-p-setting-cls"> <p>Last Week</p></div>
                </div>
                </div>
                <div className="row bg-white">
                <div className="col-sm-12 col-md-12">
                <div className="row p-10">

<div className="col-xl-6 col-md-6 mb-4">
  <div className="card border-left-success shadow h-100 py-2">
    <div className="card-body">
    <Link to={{pathname:'/AdminMain/CurrentDue'}}>
      <div className="row no-gutters align-items-center">
        <div className="col mr-2">
          <div className="text-lg font-weight-bold text-danger text-uppercase mb-1"> Current Due </div>
          <div className="h5 mb-0 font-weight-bold text-gray-800">Rs:{this.state.mFeeDashData.current_due}</div>
        </div>
        <div className="col-auto">
          <i className="fa-2x text-gray-300"> <img src={m2i}></img></i>
        </div>
      </div>
    </Link>
    </div>
  </div>
</div>
<div className="col-xl-6 col-md-6 mb-4">
  <div className="card border-left-primary shadow h-100 py-2">
    <div className="card-body">
    <Link to={{pathname:'/AdminMain/TotalRecieved'}}>
      <div className="row no-gutters align-items-center">
        <div className="col mr-2">
          <div className="text-lg font-weight-bold text-success text-uppercase mb-1"> Total Recieved </div>
          <div className="h5 mb-0 font-weight-bold text-gray-800">Rs:{this.state.mFeeDashData.total_recieved}</div>
        </div>
        <div className="col-auto">
          <i className="fa-2x text-gray-300"><img src={m1i}/></i>
        </div>
      </div>
      </Link>
    </div>
  </div>
</div>
</div>
</div>
</div>
</div>
            )
          }
        } />
    
              <Route path='/AdminMain/FeeCollection'  component={FeeCollection} />
              <Route path='/AdminMain/CurrentDue'  component={CurrentDue} />
              <Route path='/AdminMain/TotalRecieved'  component={TotalRecieved} />
                     
        </switch>
        
       </Router>
      )
    }
  } 
 
}

