import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class Aknowlagement extends Component {
  printDiv(){
    let pp=document.getElementById('divcontentakno').innerHTML;
       window.print(pp);
     }
     constructor(props){
      super(props)
  
      const token=localStorage.getItem("token");
      let loggedIn=true;
      if(token==null){
        loggedIn=false;
      }
  
      this.state={
  
          loggedIn:true,
          Loading:false, 
          mAknowlagement:[],
          mStudentdata:[]    
      }
    
  }
componentDidMount()
{
this.getAknowlagement();
}
getAknowlagement(){

this.setState({
  Loading:true
})

const SCHOOL_CODE=SessionManager.getSchoolCode();
const Enrollment_no=this.props.match.params.Enrollment_no;
const Session_id=this.props.match.params.Session_id;
// alert("jai shri ram >>>>> "+Session_id);
const FUNCTION_NAME='/StudentAdmDoc/getAcknowledgementData.php'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR_PRINT_DOCS+FUNCTION_NAME;
console.log("URL>>>>>"+URL);
axios.get(URL,{
params:{
  school_code:SCHOOL_CODE,
  session_id:Session_id,
  enrollment_no:Enrollment_no
}
}).then(res => res.data)
.then((response) => {
this.setState({ 
  Loading:false
 })
    //console.log("Response2>>>>>"+response);
  // alert("res"+JSON.stringify(response));
    let api_status=response.api_status;
   
    if(api_status=="OK")
    {
       this.setState({
          mAknowlagement:response.data.schoolData,
          mStudentdata:response.data.Studentdata
        })
       // alert("response>>>>> Dharmendra "+JSON.stringify(this.state.mAknowlagement));
        
    }
      else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}
render() {
  if( this.state.loggedIn==false)
  {
   return <Redirect to='/Login'/>;
  }
  if(this.state.Loading)
  {
     return <MyLoader/>
  }
  else{

  return ( 
       <div className="content content-customize-area-student-details">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Aknowlagement </a></li>
              </ul>
            </div>
          </div>
            <div id="divcontentakno" className="m-0-100 dharam" style={{padding:'5px 15px'}}>
              <div className="row">
                 <div className="col-sm-12" style={{borderBottom:'3px double #007bff'}}>
                    <div className="row">
                      <div className="col-sm-3 pb-1">
                       <p className="photo-st-21 m-0" style={{width:'120px',height:'120px'}}> <img src={this.state.mAknowlagement.length!==0?this.state.mAknowlagement[0].school_logo_path:'Image Path'} alt=""/> </p> 
                      </div>
                      <div className="col-sm-6 pt-5">
                      <h1> {this.state.mAknowlagement.length!==0?this.state.mAknowlagement[0].school_name:'School Name'}</h1>
                     <p><strong> Address: </strong> <span>{this.state.mAknowlagement.length!==0?this.state.mAknowlagement[0].school_address:'School Address'} </span> </p>
                      </div>
                      <div className="col-sm-3 pb-1">
                       <p className="photo-st-21 m-0" style={{width:'120px',height:'120px',float:'right'}}> <img src="{this.state.mStudentdata.length!==0?this.state.mStudentdata[0].school_name:'School Name'}" alt=""/> </p> 
                      </div>
                    </div>
                 </div>
                 <div className="col-sm-12">
                 <div className="row">
                      <div className="col-sm-7 pt-5">
                      <h3> {this.state.mStudentdata.length!==0?this.state.mStudentdata[0].stu_name:'Student Name'} S/O {this.state.mStudentdata.length!==0?this.state.mStudentdata[0].father_name:'Father Name'}</h3>
                      <p><strong> Board: </strong><span>{this.state.mStudentdata.length!==0?this.state.mStudentdata[0].class[0].board_name:'Board Name'}</span> <strong> Medium: </strong><span>{this.state.mStudentdata.length!==0?this.state.mStudentdata[0].class[0].medium_name:'Medium Name'}</span><span> <strong> Roll No.: </strong></span><span>{this.state.mStudentdata.length!==0?this.state.mStudentdata[0].class[0].student_roll_no:'Roll No'}</span><span> <strong> Class: </strong></span> <span> {this.state.mStudentdata.length!==0?this.state.mStudentdata[0].class[0].class_name:'Class Name'} </span> </p>
                       </div>
                       <div className="col-sm-5 p-2">
                       <div className="" style={{width:'120px',height:'120px',border:'2px solid #d1e7ff',float:'right'}}> <img src="{this.state.mStudentdata.length!==0?this.state.mStudentdata[0].student_img_path:'Student Path'}" alt=""/> </div> 
                      </div>
                    </div>
                  </div>
                </div>

                { /* Add student Details section */ }

                <div className="row basic-de">
                <div className="col-sm-12 col-lg-12 text-center">
                <div className="bg-info-1">
                <h3 className="text-white text-center"> Basic Details  </h3>
                </div> 
              </div>
               </div>
               
               <div className="row" style={{background:'#5bc0de1a',paddingBottom:'20px'}}>
                <div className="col-sm-12 col-lg-12 stu-details">
                  <br></br>
                <table border="1">
                <tbody>
          <tr><td>Enrollment No/ User Id</td><td> {this.state.mStudentdata.length!==0?this.state.mStudentdata[0].class[0].student_enrollment_no:'Enrollment No'}</td></tr>
          <tr><td>Password</td><td>*********</td></tr>
          <tr><td>Current Session</td><td>{this.state.mStudentdata.length!==0?this.state.mStudentdata[0].curr_session:'Current Session'} </td></tr>
          <tr><td>Gender</td><td>{this.state.mStudentdata.length!==0?this.state.mStudentdata[0].gender:'Gender'}</td></tr>
          <tr><td>Date of Birth</td><td>{this.state.mStudentdata.length!==0?this.state.mStudentdata[0].birthday:'Birth Day'}</td></tr>
          <tr><td>Community</td><td>{this.state.mStudentdata.length!==0?this.state.mStudentdata[0].community:'Community'}</td></tr>
          <tr><td>Aadhaar No:</td><td>{this.state.mStudentdata.length!==0?this.state.mStudentdata[0].adharcard_no:'Aadhaar No'}</td></tr>
          <tr><td>Nationality</td><td>{this.state.mStudentdata.length!==0?this.state.mStudentdata[0].nationality:'Nationality'}</td></tr>
          <tr><td>CURRENT ADDRESS</td><td> {this.state.mStudentdata.length!==0?this.state.mStudentdata[0].current_address:'Current Address'}</td></tr>
          <tr><td>PERMANENT ADDRESS</td><td>{this.state.mStudentdata.length!==0?this.state.mStudentdata[0].permanent_address:'Permanent Address'}</td></tr>
          </tbody>
                </table>
                 </div>
                 <div className="col-sm-12 col-lg-12 stu-details">
                  <hr/>
                  <p><strong>Note:</strong> Please check above details full fill by Gourgian!</p> 
                  <p>1- Please confirm Basic Details </p>
                  <p>2- Please confirm Basic Details </p>
                  <p>3- Please confirm Basic Details </p>
                 </div>
                 <div className="col-sm-12 col-lg-12" style={{borderTop:'2px solid #000',paddingTop:'20px',marginTop:'200px'}}>
                 <h3 className="text-justify" style={{color:'#7c7c80'}}>Official Contact Details:</h3>
                 <p className="mb-0"> <strong>Official Contact1:</strong><span> +91-000000000000, </span><strong>Official Contact2:</strong><span> +91-000000000000, </span> <strong>Official Email:</strong><span> test@gmail.com </span> </p>
                 <p><strong>Website:</strong><span> www.test.com </span></p>
                 <p> <button class="btn btn-primary pull-right prin" onClick={this.printDiv} style={{padding:'4px 15px'}}> Print </button></p>
                
                 </div>
               </div>
              </div>
              
               </div>             
      )
      
    }    
  }
 
}
