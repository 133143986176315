import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import ModalFeeConfirmation from '../dialog/ModalFeeConfirmation';
import StudentInformation from '../ChildComponent/StudentInformation';
import PaymentStatus from './PaymentStatus';
import myToast from '../Utils/MyToast';
export default class FeeOtherAmount extends Component {
    constructor(props){
        super(props)
      const token=localStorage.getItem("token");
      let loggedIn=true;
      if(token==null){
        loggedIn=false;
      }
    
      this.state={
          showFeeConfirmation:false,
          loggedIn:true,
          Loading:false, 
          isFeeSubmited:false,
          mStudentDueFee:[],
          mStudentDetails:[],
          OtherAmount:0,
          adjustable_amt:0,
          netPayableAmount:0,
          count_render:1,
          ENROLLMENT_NO:'',
          ADHAAR_NO:'',
          SESSION_ID:''
            
      }
      this.onOtherAmountChange= this.onOtherAmountChange.bind(this);
      this.HandleCloseModalFeeConfirmation = this.HandleCloseModalFeeConfirmation.bind(this);
      this.submitFeeDetails = this.submitFeeDetails.bind(this);


    }
       
  HandleShowModalFeeConfirmation = () => {
    //alert(this.state.netPayableAmount);
    this.state.netPayableAmount=0;
    this.setState({
      showFeeConfirmation: !this.state.showFeeConfirmation
    });   
  }
  HandleCloseModalFeeConfirmation = () => {
    this.state.netPayableAmount=0;
    this.setState({
      showFeeConfirmation:false
    });  
     
  }
    
    onOtherAmountChange(e){
    
      this.setState({
        OtherAmount: e.target.value,
      })
      this.state.netPayableAmount=0;
      localStorage.setItem("OtherAmount",e.target.value);
      
  }
    componentDidUpdate(){    
      window.onpopstate = e => {
        // on back press
         localStorage.setItem("mStudentDue","");        
      }
    }
    
    componentDidMount()
    {
      window.scrollTo(0, 0);

      if(this.props.location.data)
      {             
        this.state.mStudentDueFee=this.props.location.data.mDueData;
        this.state.OtherAmount=this.props.location.data.OtherAmount;  
        this.state.mStudentDetails=this.props.location.data.StudentDetails;  
        
        localStorage.setItem("mStudentDue",JSON.stringify(this.state.mStudentDueFee));
        localStorage.setItem("StudentDetails",JSON.stringify(this.state.mStudentDetails));
        localStorage.setItem("OtherAmount",this.state.OtherAmount);
        this.setState({

        })

        //  alert("1"+this.state.OtherAmount);
       
      }
      else{  
        
        this.state.mStudentDueFee=JSON.parse(localStorage.getItem("mStudentDue"));
        this.state.mStudentDetails=JSON.parse(localStorage.getItem("StudentDetails"));
        this.state.OtherAmount=Number(localStorage.getItem("OtherAmount"));  
        
        //alert("2"+this.state.OtherAmount);
        this.setState({
        })
        
      }


    }
    getSubtotal(pIndex)
    {
        
        var amt=0;
        var mApplicableFee=[];
        mApplicableFee=this.state.mStudentDueFee[pIndex].applicable_fee;  
        
        for(var i=0;i<mApplicableFee.length;i++)
        {
          amt=amt+Number(mApplicableFee[i].outstanding);
        } 
        
        return amt;
    
    }
    
    submitFeeDetails = () => {
        
      this.setState({
          showFeeConfirmation:false,
          Loading:true
        }); 
    
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      
      var STUDENT_ID=this.state.mStudentDetails[0].student_enrollment_no;
      var mStaffData=SessionManager.getStaffDetails();
      var STAFF_ID=mStaffData.staff_id;
      var CLASS_ID=this.state.mStudentDetails[0].class[0].class_id;
      var SECTION_ID=this.state.mStudentDetails[0].class[0].section_id;
      const SESSION_ID=this.state.mStudentDetails[0].class[0].session_id;

      this.state.ENROLLMENT_NO=STUDENT_ID;
      this.state.SESSION_ID=SESSION_ID;
  
      var arrTemp = [...this.state.mStudentDueFee];
      for (let index = 0; index < arrTemp.length; index++) {
          let element = arrTemp[index];
          arrTemp[index]['applicable_fee']=JSON.stringify(element.applicable_fee);
          
      }
      const STR_FEE_DETAILS=JSON.stringify(arrTemp);
      var REMARK="";
      var AMOUNT=this.state.netPayableAmount;
  
     // alert(STR_FEE_DETAILS);
      
      const FUNCTION_NAME='submitFeeDetails.php'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      console.log("URL>>>>>"+URL)
      
      var params = new URLSearchParams(); 
      params.append('student_id', STUDENT_ID);
      params.append('class_id', CLASS_ID);
      params.append('section_id',SECTION_ID);
      params.append('session_id',SESSION_ID);
      params.append('staff_id',STAFF_ID);
      params.append('fee_details',STR_FEE_DETAILS);
      params.append('remark',REMARK);
      params.append('amount',AMOUNT);
      params.append('school_code',SCHOOL_CODE);
  
   axios.post(URL,params).then(res => res.data)
  .then((response) => {
          let api_status=response.api_status;
          //alert(api_status);       
          if(api_status=="OK")
          {
              alert("Submitted Successfully ");
              this.setState({
                isFeeSubmited:true,
                Loading:false,
              })
                   
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
            this.setState({ 
              Loading:false
             })
          
            
          } 
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        console.log("error>>>>>"+error);
        });
      }
   

      render() {
        if( this.state.loggedIn==false)
        {
         return <Redirect to='/Login'/>;
        }
        if(this.state.Loading)
        {
           return <MyLoader/>
        }
        else if(this.state.isFeeSubmited)
        {
        return <PaymentStatus student_id={this.state.ENROLLMENT_NO} session_id={this.state.SESSION_ID}></PaymentStatus>
       }
    else{

       return (
         
      <div className="content">
          <div className="content-header" style={{background:COLORS.primary_color_shade5}}>
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" style={{color:COLORS.white}} /><a href="#"> Fee Other Amount  </a></li>
              </ul>
            </div>
          </div> 
          <div className="d-150 animated fadeInUp"> 
          {    
          //before render                      
              (() => {
                
                this.state.adjustable_amt=0;
                this.state.netPayableAmount=0;
                this.state.OtherAmount=localStorage.getItem("OtherAmount");
                
              
            })()
          }               
         
          <div className="row" style={{padding:'3px 5px',background:'rgba(18,58,188,0.75)'}}>
                <div className="col-sm-6 col-md-6 text-center" style={{padding:'5px'}}>
                    <h3 className="text-white"> Other Amount Rs: </h3>
                </div>  
                <div className="col-sm-6 col-md-6 viv">
                   <p className="m-0"> <input type="text"  name="otherAmount" onChange={this.onOtherAmountChange} value={this.state.OtherAmount} style={{width:'100%',padding:'10px',border:'none'}}></input> </p> 
                </div>   
                </div> 
               
                
                <div className="row"  style={{margin:'5px 0px'}}>
                                                                   
                {this.state.mStudentDueFee.map((item, index) => {
                             
                             var mFeeName=[];
                             mFeeName=item.applicable_fee;
                             var mAdjustable_amt;
                             //alert(this.state.OtherAmount);

                            return( 

                           <div className="col-sm-12 bg_card_grey_outline"   style={{margin:'5px 0px',padding:'0px'}}>
                            {                          
                           (() => {
                             if(this.state.OtherAmount>0)
                             {
                              var subtotal=this.getSubtotal(index); 
                              //alert("subtotal"+subtotal) ;                                       
                              if(this.state.OtherAmount>=subtotal)
                              {
                                this.state.adjustable_amt=subtotal;

                              }
                              else {
                                this.state.adjustable_amt=this.state.OtherAmount;
                              }
                               mAdjustable_amt=this.state.adjustable_amt;                           
                              //alert("adjustable_amt"+this.state.adjustable_amt) ;
                              //alert("mAdjustable_amt"+mAdjustable_amt) ;
                              this.state.mStudentDueFee[index]['isChecked']="true";

                              this.state.OtherAmount=this.state.OtherAmount-this.state.adjustable_amt; 
                              return(                           
                                <div className="row bg_card_grey_tltr" > 
                                  <div className="col-sm-2"  style={{paddingTop:'8px'}}>
                                  <input type="checkbox" checked={this.state.mStudentDueFee[index]['isChecked']==='true'?true:false} style={{transform:'scale(2)'}}/>
                                  </div>
                                  <div className="col-sm-10"><p style={{fontSize:'17px'}}><strong> {item.month2} </strong></p></div>
                                    
                                </div>
                                
                               )
                             }
                             
                             else{
                               this.state.adjustable_amt=0;                              
                               this.state.mStudentDueFee[index]['isChecked']="false";
                               return(                           
                                <div className="row bg_card_grey_tltr"> 
                                <div className="col-sm-2"  style={{paddingTop:'8px'}}>
                                <input type="checkbox" checked={this.state.mStudentDueFee[index]['isChecked']==='true'?true:false} style={{transform:'scale(2)'}}/>
                                </div>
                                <div className="col-sm-10"><p style={{fontSize:'17px'}}><strong> {item.month2} </strong></p></div>
                                  
                                </div>
                             )
                             }
                            })()
                           }
                          
                          {
                            mFeeName.map((item2, index2) => {
                                   var outstandings=item2.outstanding;                             
                                return( 
                                  (() => {
                                      if(this.state.adjustable_amt>0)
                                      {
                                        if(this.state.adjustable_amt>=outstandings)
                                        {
                                          mFeeName[index2]['isChecked']="true";
                                          mFeeName[index2]['payable_amount']=outstandings;
                                          this.state.mStudentDueFee[index]['applicable_fee']=mFeeName;             
                                   
                                         
                                          this.state.adjustable_amt=this.state.adjustable_amt-outstandings;
                                          this.state.netPayableAmount=Number(this.state.netPayableAmount)+Number(outstandings);
                                          
                                          return(                                         
                                            <div className="row" style={{border:'1px solid #d7d2d2'}}>
                                              <div className="col-sm-2" style={{paddingTop:'8px'}}>
                                              <input type="checkbox" checked={mFeeName[index2]['isChecked']==='true'?true:false} style={{transform:'scale(2)'}}/>
                                              </div>
                                              <div className="col-sm-5"><p style={{fontSize:'16px'}}>{item2.fee_name}</p></div>
                                              <div className="col-sm-2"><p style={{fontSize:'16px'}}> Rs. <span>{item2.outstanding}</span></p> </div>
                                              <div className="col-sm-3"><p style={{fontSize:'16px'}}>{outstandings}</p></div>
                                            </div>                                                                                      
                                          )
                                          
                                        }
                                        else{
                                           
                                          mFeeName[index2]['isChecked']="true";
                                          mFeeName[index2]['payable_amount']=this.state.adjustable_amt;
                                          this.state.mStudentDueFee[index]['applicable_fee']=mFeeName;
                                         
                                          var adj_amt=this.state.adjustable_amt;
                                          this.state.adjustable_amt=this.state.adjustable_amt-this.state.adjustable_amt;
                                          this.state.netPayableAmount=Number(this.state.netPayableAmount)+Number(adj_amt);
                                          return(  
                                                                                  
                                            <div className="row" style={{border:'1px solid #d7d2d2'}}>
                                              <div className="col-sm-2" style={{paddingTop:'8px'}}>
                                              <input type="checkbox" checked={mFeeName[index2]['isChecked']==='true'?true:false} style={{transform:'scale(2)'}}/>
                                              </div>
                                              <div className="col-sm-5"><p style={{fontSize:'16px'}}>{item2.fee_name}</p></div>
                                              <div className="col-sm-2"><p style={{fontSize:'16px'}}> Rs. <span>{item2.outstanding}</span></p> </div>
                                              <div className="col-sm-3"><p style={{fontSize:'16px'}}>{adj_amt}</p></div>
                                              <div className="col-sm-12">
                                              <div className="col-sm-12 gravity_center">                                               
                                                <h4>Due Calculated In</h4>
                                              </div>
                                              <div className="col-sm-6 gravity_center">                                               
                                                <h5>{item2.fee_name}</h5>
                                              </div>
                                              <div className="col-sm-6 gravity_center">                                               
                                                <h5>{outstandings-adj_amt}</h5>
                                              </div>
                                              </div>
                                            </div>
                                                
                                            
                                          )
                                            
                                        }
                                        
                                      }
                                      else{
                                        mFeeName[index2]['isChecked']="false";
                                        this.state.mStudentDueFee[index]['applicable_fee']=mFeeName;
                                        return(                                         
                                          <div className="row" style={{border:'1px solid #d7d2d2'}}>
                                          <div className="col-sm-2" style={{paddingTop:'8px'}}>
                                          <input type="checkbox" checked={mFeeName[index2]['isChecked']==='true'?true:false} style={{transform:'scale(2)'}}/>
                                          </div>
                                          <div className="col-sm-5"><p style={{fontSize:'16px'}}>{item2.fee_name}</p></div>
                                          <div className="col-sm-2"><p style={{fontSize:'16px'}}> Rs. <span>{item2.outstanding}</span></p> </div>
                                          <div className="col-sm-3"><p style={{fontSize:'16px'}}>{item2.payment_status}</p></div>
                                          </div>
                                      )                                     
                                      }
                                    

                                })()
                                  
                                )                            

                            })
                          }
                          <div className="row bg_card_grey " > 
                                  
                              <div className="col-sm-12 gravity_center_right">
                                <p style={{fontSize:'17px'}}><strong>Subtotal: &nbsp; <span>Rs. {mAdjustable_amt}</span></strong></p>
                              </div>
                          </div>
                          
                          


                          </div>
                                  
                          );
                         })}
                          

                 </div>
                 
              
                      <div className="row">
                            <div className="col-sm-6 col-md-6 text-center" style={{padding:'5px',borderRight:'1px solid #8d8989'}}>
                                <h3> Net Payable Amount </h3>
                            </div>  
                            <div className="col-sm-6 col-md-6 viv" style={{background:'none'}}>
                              <p><strong>  Rs:<span>{this.state.netPayableAmount}</span> </strong> </p> 
                            </div>   
                        </div> 
                        <div className="row">
                            <div className="col-sm-12 col-md-12 btn btn-primary">
                            <button className="btn btn-primary" style={{width:'100%',fontSize:'24px', margin:'0px'}}onClick={this.HandleShowModalFeeConfirmation}> Collect Now </button>                   
                            </div>    
                        </div>  
                        {this.state.showFeeConfirmation===true? <ModalFeeConfirmation action={this.HandleCloseModalFeeConfirmation} action2={this.submitFeeDetails} netPayableAmount={this.state.netPayableAmount}></ModalFeeConfirmation>:''}
          
             
                
                </div>
                </div>
  )
} 
}
}
