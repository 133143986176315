import React, { Component } from 'react'
import '../css/Admin_css/admin-customize.css';
import '../css/Admin_css/vp_style.css';
import MyLoader from '../Spinner/MyLoader';
import Login from '../login';
import { BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import SessionManager from '../Utils/SessionManager';
import axios from 'axios'
import myToast from '../Utils/MyToast';
import ModuleList from './ModuleList';
import StaffPrivilege from './StaffPrivilege';
import Security from './Security';
import Settlement from './Settlement';
import ClassList from '../cms/ClassList';
import NoticeBoard from '../cms/NoticeBoard';
import FeeDashBoard from '../cms/FeeDashBoard';
import AdmissionDashboard from '../cms/AdmissionDashboard';
import AttendanceDashboard from '../cms/AttendanceDashboard';
import HelpDesk from '../cms/HelpDesk';
import Holiday from '../cms/Holiday';
import LeaveManagement from '../cms/LeaveManagement';
import Comment from '../cms/Comment';
import SchoolProfile from '../cms/SchoolProfile';
import AdminNotification from './AdminNotification';
import LeaveManagementAdmin from './LeaveManagementAdmin';
import ManageRecords from './ManageRecords';
import RevenueManagement from './RevenueManagement';
import Transport from '../cms/Transport';
import Academic from '../cms/Academic';
import Notification from '../cms/Notification';
import LearningManagement from '../cms/LearningManagement'; 
import HomeWork from '../cms/HomeWork';
import StaffList from '../cms/StaffList';
import Settings from '../cms/Settings';
import MyProfile from '../cms/MyProfile';
import NavMenuAdmin from './NavMenuAdmin';
import Error from './Error';
import StudentList from '../cms/StudentList';

import PaymentHistory from '../cms/PaymentHistory';
import PaymentDetails from '../cms/PaymentDetails';

import StudentDetails from '../cms/StudentDetails';
import FeeCard from '../cms/FeeCard';
import AdvancedPayment from '../cms/AdvancedPayment';
import PaymentStatus from '../cms/PaymentStatus';
import FeeReceipt from '../cms/FeeReceipt';
import FeeCollection from '../cms/FeeCollection';
import CurrentDue from '../cms/CurrentDue';
import StudentRegistration from '../cms/StudentRegistration';
import UpdateSchoolDetails from '../cms/UpdateSchoolDetails';
import ChangePassword from '../cms/ChangePassword';
import AddHoliday from '../cms/AddHoliday';
import BatchShifting from '../cms/BatchShifting';
import EditStaff from '../cms/EditStaff';
import StaffRegistration from '../cms/StaffRegistration';
import TotalRecieved from '../cms/TotalRecieved';
import UpdateStudentDetails from '../cms/UpdateStudentDetails';
import ManageNotice from '../cms/ManageNotice';
import SendNotification from '../cms/SendNotification';
import AddNotice from '../cms/AddNotice';
import Accessories from '../cms/Accessories';
import TotalDue from '../cms/TotalDue';
import TotalFee from '../cms/TotalFee';
import Acknowledgement from '../cms/Acknowledgement';
import StRegSuccessfull from '../cms/StRegSuccessfull';
import OldDueList from './OldDueList';
import OldDueCollection from './OldDueCollection';
import ExamList from '../cms/ExamList';
import FeeOtherAmount from '../cms/FeeOtherAmount';
import ClassSettings from '../cms/ClassSettings';
import FeeSettings from '../cms/FeeSettings';
import ClassFee from '../cms/ClassFee';
import AddClassFee from '../cms/AddClassFee';
import FeeNameList from '../cms/FeeNameList';
import SpecialCaseStudent from '../cms/SpecialCaseStudent';
import SpecialCaseCreation from '../cms/SpecialCaseCreation';
import AdmissionQuery from '../cms/AdmissionQuery';
import AdmissionQueryDetails from '../cms/AdmissionQueryDetails';
import AdmissionLink from '../cms/AdmissionLink';
import RegistrationFee from '../cms/RegistrationFee';
import VendorRegistration from '../cms/VendorRegistration';
import MyVendor from '../cms/MyVendor';
import ExamTopperList from '../cms/ExamTopperList';
import ExamPaperSettings from '../cms/ExamPaperSettings';
import ExamClassResultStatus from '../cms/ExamClassResultStatus';
import ExamDetails from '../cms/ExamDetails';
import ExamClassResult from '../cms/ExamClassResult';
import ExamStudentResult from '../cms/ExamStudentResult';
import ExamReportCardList from '../cms/ExamReportCardList';
import ExamStudentReportCard from '../cms/ExamStudentReportCard';
import Attendance from '../cms/Attendance';
import PrintMaster from '../cms/PrintMaster';
import StudentIdCard from '../cms/StudentIdCard';
import ExamAdmitCard from '../cms/ExamAdmitCard';
import ExamDateSheet from '../cms/ExamDateSheet';
import ExamGuideline from '../cms/ExamGuideline';
import ExamHallTicket from '../cms/ExamHallTicket';
import ReportManagement from './ReportManagement';
import ReportViewClass from './ReportViewClass';
import DataCenter from './DataCenter';
import StudentData from './StudentData';
import ExamMarkingSystem from '../cms/ExamMarkingSystem';


export default class AdminMain extends Component {
    constructor(props){
        super(props)
    
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
    
        this.state={
           
            loggedIn:true,
            Loading:false,
            login_type:'',
            adminModules:[],
            AllActiveModulesOfClerk:[],
            mCurrentSession:[]
                
        }
      
    }
    componentDidMount()
    {
      var mStaffData=SessionManager.getStaffDetails();
      this.state.login_type=mStaffData.login_type;
      
        if(this.state.login_type==process.env.REACT_APP_LOGIN_TYPE_ADMIN)
        {
          this.getAdminModules();
          this.getAllActiveModulesOfClerk();
          this.getCurrentSessionDetails();

        }
        else if(this.state.login_type==process.env.REACT_APP_LOGIN_TYPE_CLERK)
        {
             
          this.getClerkDashboard();
        }
        else{

        }          
      
    }
    getAdminModules(){
      
      this.setState({
        Loading:true
      })
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      const FUNCTION_NAME='getAllAdminModules.php?'
      const System_code =process.env.REACT_APP_SYSTEM_CODE;
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      console.log("URL>>>>>"+URL)
      axios.get(URL, {
        params: {
          school_code:SCHOOL_CODE,
          system_code:System_code
        
        }
    
      }).then(res => res.data)
      .then((response) => {
        this.setState({ 
           Loading:false
          })
          let api_status=response.api_status;
          
          if(api_status=="OK")
          {
          
             this.setState({
              
                adminModules:response.data
              })
             // console.log("response>>>>>"+this.state.adminModules)
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
    
        //console.log("response>>>>>"+api_status)
       })
    }
    getAllActiveModulesOfClerk(){
      this.setState({
        Loading:true
      })
      const FUNCTION_NAME='getAllActiveModulesOfClerk.php?'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      console.log("URL>>>>>"+URL)
      axios.get(URL, {
        params: {
        //  school_code:SCHOOL_CODE,
        
        }
    
      }).then(res => res.data)
      .then((response) => {
        this.setState({ 
           Loading:false
          })
          let api_status=response.api_status;
          if(api_status=="OK")
          {
          
            
             this.setState({
              
              AllActiveModulesOfClerk:response.data
              })
             // console.log("response_AllActiveModulesOfClerk>>>>>"+this.state.AllActiveModulesOfClerk)
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
       })
    }

    getCurrentSessionDetails(){
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      const FUNCTION_NAME='getCurrentSessionDetails.php?'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      console.log("URL>>>>>"+URL)
      axios.get(URL, {
        params: {
           school_code:SCHOOL_CODE,
        
        }
    
      }).then(res => res.data)
      .then((response) => {
    
          let api_status=response.api_status;
          if(api_status=="OK")
          {       
             this.setState({
              
              mCurrentSession:response.current_session
              
              })
              SessionManager.saveCurrentSession(this.state.mCurrentSession);
             //console.log("response_current session>>>>>"+this.state.mCurrentSession)
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
       })
    
    }
    getClerkDashboard(){
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      var mStaffData=SessionManager.getStaffDetails();
      var STAFF_ID=mStaffData.staff_id;
     
      const FUNCTION_NAME='getDashBoard.php?'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      console.log("URL>>>>>"+URL)
      axios.get(URL, {
        params: {
           school_code:SCHOOL_CODE,
           staff_id:STAFF_ID
        
        }
    
      }).then(res => res.data)
      .then((response) => {
    
          let api_status=response.api_status;
          if(api_status=="OK")
          {       
             this.setState({
              
              mCurrentSession:response.dashboard.current_session,
              AllActiveModulesOfClerk:response.dashboard.privilege             
              })
              
              SessionManager.saveCurrentSession(this.state.mCurrentSession);
             
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
       })
    
    }


      render() {
            if( this.state.loggedIn==false)
            {
             return <Redirect to='/Login'/>;
            }
            if(this.state.Loading)
            {
               return <MyLoader/>
            }
        else{
              
        return (
                <div>
                  <NavMenuAdmin/>
                  <Router>
                <switch>   
              <Route path='/AdminMain'  exact render={
            ()=>{
    
              return(
              
              <div className="content">
              <div className="content-header">
                <div className="leftside-content-header">
                  <ul className="breadcrumbs">
                    <li> <Link to='/AdminMain'> <i className="fa fa-home" aria-hidden="true" /> &nbsp; School Managent ERP System </Link> </li>
                  </ul>
                </div>
               </div>
              <div className="row animated fadeInUp ">
             
                  <div className="row">
    
                  {this.state.adminModules.map((item, index) => (
                                 
                                 <div className="col-sm-3 col-md-3">
                               
                                 <div className="adminf-f-head">
                                  
                                 <Link to={'/AdminMain/'+item.component_name}>
                                 <div className="inne-section">
                                  <h2> <img src={item.icon_img} alt={"icon"} className="text-white" /> </h2>
                                  <h4> <span>{item.module_name} </span></h4>
                                </div>
                                </Link>
                                 </div>
                                
                               </div>
                               
                         ))}
                    
                   
                    
                  </div>
                  </div>
                
              <div className="row animated fadeInUp row-30-min-bg">
              <div className="col-sm-12 col-lg-12 text-center">
                  <h3>Admin/Clerk Modules</h3>
                  </div>
                   </div>
                  <div className="row">
                  {this.state.AllActiveModulesOfClerk.map((item, index) => (
                    <div className="col-sm-3 col-md-3">
                      <div className="adminf-f-head">
                        <Link to={'/AdminMain/'+item.component_name}>
                         <div className="inne-section-1">
                          <h2> <img src={item.icon_img} alt={"icon"} className="text-white" /> </h2>
                           <h4> <span> {item.module_name} </span></h4>
                          </div>
                        </Link>
                      </div>
                    </div>
                    ))}
                   </div>

                 
               </div>
              
                       
              )
           }
          } />  
                               
          
            <Route path='/AdminMain/ModuleList'  component={ModuleList} />
            <Route path='/AdminMain/StaffPrivilege'  component={StaffPrivilege} />
            <Route path='/AdminMain/Security'  component={Security} />           
            <Route path='/AdminMain/Settlement'  component={Settlement} />            
            <Route path='/AdminMain/ClassList'  component={ClassList} />
            <Route path='/AdminMain/HelpDesk'  component={HelpDesk} />           
            <Route path='/AdminMain/Comment'  component={Comment} />            
            <Route path='/AdminMain/AdminNotification'  component={AdminNotification} />
            <Route path='/AdminMain/RevenueManagement'  component={RevenueManagement} />
            <Route path='/AdminMain/ManageRecords'  component={ManageRecords} />
            <Route path='/AdminMain/LeaveManagementAdmin'  component={LeaveManagementAdmin} />           
            <Route path='/AdminMain/StaffList'  component={StaffList} />
            <Route path='/AdminMain/Settings'  component={Settings} />
            <Route path='/AdminMain/ReportManagement'  component={ReportManagement} />
            
            <Route path='/AdminMain/UpdateSchoolDetails'  component={UpdateSchoolDetails} />
            <Route path='/AdminMain/SchoolProfile'  component={SchoolProfile} />
            <Route path='/AdminMain/FeeDashBoard'  component={FeeDashBoard} />
            <Route path='/AdminMain/NoticeBoard'  component={NoticeBoard} />
            <Route path='/AdminMain/AdmissionDashboard'  component={AdmissionDashboard} />
            <Route path='/AdminMain/AttendanceDashboard'  component={AttendanceDashboard} />
            <Route path='/AdminMain/Attendance/:class_id/:section_id'  component={Attendance} />   
            <Route path='/AdminMain/Holiday'  component={Holiday} />
            <Route path='/AdminMain/HomeWork'  component={HomeWork} />
            <Route path='/AdminMain/Transport'  component={Transport} />
            <Route path='/AdminMain/Academic'  component={Academic} />
            <Route path='/AdminMain/Notification'  component={Notification} />
            <Route path='/AdminMain/MyProfile'  component={MyProfile} />
            <Route path='/AdminMain/LeaveManagement'  component={LeaveManagement} />
            <Route path='/AdminMain/LearningManagement'  component={LearningManagement} />
            <Route path='/AdminMain/Accessories'  component={Accessories} />
            <Route path='/AdminMain/DataCenter'  component={DataCenter} />


            <Route path='/AdminMain/StudentList/:class_id/:section_id'  component={StudentList} />          
            <Route path='/AdminMain/StudentDetails/:student_id'  component={StudentDetails} />    

            
            <Route path='/AdminMain/PaymentStatus'  component={PaymentStatus} />       
            <Route path='/AdminMain/AdvancedPayment'  component={AdvancedPayment} />       
            <Route path='/AdminMain/PaymentHistory/:student_id/:session_id'  component={PaymentHistory} />       
            <Route path='/AdminMain/FeeCard'  component={FeeCard} /> 
            <Route path='/AdminMain/PaymentDetails/:transaction_id/:session_id/:student_id'  component={PaymentDetails} />            
            <Route path='/AdminMain/FeeReciept/:transaction_id/:student_id/:session_id'  component={FeeReceipt} />  
           
            <Route path='/AdminMain/FeeCollection'  component={FeeCollection} />
            <Route path='/AdminMain/CurrentDue'  component={CurrentDue} />
            <Route path='/AdminMain/TotalRecieved'  component={TotalRecieved} />
            <Route path='/AdminMain/TotalDue'  component={TotalDue} />
            <Route path='/AdminMain/TotalFee'  component={TotalFee} />
            <Route path='/AdminMain/OldDueList/:tag'  component={OldDueList} />
 
              
            <Route path='/AdminMain/StudentRegistration'  component={StudentRegistration} />
            <Route path='/AdminMain/ChangePassword'  component={ChangePassword} />
            <Route path='/AdminMain/AddHoliday'  component={AddHoliday} />
            <Route path='/AdminMain/BatchShifting'  component={BatchShifting} />
            <Route path='/AdminMain/EditStaff'  component={EditStaff} />
            <Route path='/AdminMain/StaffRegistration'  component={StaffRegistration} />
            <Route path='/AdminMain/UpdateStudentDetails'  component={UpdateStudentDetails} />
            
           <Route path='/AdminMain/ManageNotice'  component={ManageNotice} />          
           <Route path='/AdminMain/AddNotice'  component={AddNotice} />   
           <Route path='/AdminMain/SendNotification'  component={SendNotification} />
           <Route path='/AdminMain/Acknowledgement/:Enrollment_no/:Session_id'  component={Acknowledgement} /> 
           <Route path='/AdminMain/StudentRegSuccess/:Enrollment_no/:session_id'  component={StRegSuccessfull} />
           <Route path='/AdminMain/OldDueCollection'  component={OldDueCollection} />
           <Route path='/AdminMain/ExamList'  component={ExamList} />
           <Route path='/AdminMain/FeeOtherAmount'  component={FeeOtherAmount} />  
           
           <Route path='/AdminMain/FeeSettings'  component={FeeSettings} />  
           <Route path='/AdminMain/ClassSettings'  component={ClassSettings} />  
           <Route path='/AdminMain/ClassFee'  component={ClassFee} />
           <Route path='/AdminMain/AddClassFee'  component={AddClassFee} />          
           <Route path='/AdminMain/FeeNameList'  component={FeeNameList} />
           <Route path='/AdminMain/SpecialCaseStudent'  component={SpecialCaseStudent} />
           <Route path='/AdminMain/SpecialCaseCreation'  component={SpecialCaseCreation} /> 
           <Route path='/AdminMain/AdmissionQuery'  component={AdmissionQuery} />
           <Route path='/AdminMain/AdmissionQueryDetails'  component={AdmissionQueryDetails} />  
           <Route path='/AdminMain/AdmissionLink'  component={AdmissionLink} />  
          <Route path='/AdminMain/RegistrationFee'  component={RegistrationFee} />
          <Route path='/AdminMain/MyVendor'  component={MyVendor} />  
          <Route path='/AdminMain/VendorRegistration'  component={VendorRegistration} /> 
          <Route path='/AdminMain/ExamTopperList'  component={ExamTopperList} />
          <Route path='/AdminMain/ExamPaperSettings'  component={ExamPaperSettings} />
          <Route path='/AdminMain/ExamClassResultStatus/:exam_id/:session_id/:exam_name/:exam_date'  component={ExamClassResultStatus} />
          <Route path='/AdminMain/ExamDetails/:exam_id/:session_id/:exam_name/:exam_date/:exam_status'  component={ExamDetails} /> 
          <Route path='/AdminMain/ExamClassResult/:exam_id/:session_id/:selected_position_class/:selected_position_subject/:exam_name/:exam_date'  component={ExamClassResult} />
          <Route path='/AdminMain/ExamStudentResult/:exam_id/:session_id/:student_id/:class_id/:section_id/:exam_name/:exam_date'  component={ExamStudentResult} />
          <Route path='/AdminMain/ExamReportCardList'  component={ExamReportCardList} />  
          <Route path='/AdminMain/ExamStudentReportCard/:report_card_id/:session_id/:report_card_name'  component={ExamStudentReportCard} />
          <Route path='/AdminMain/PrintMaster'  component={PrintMaster} />  
          <Route path='/AdminMain/StudentIdCard'  component={StudentIdCard} />
          <Route path='/AdminMain/ExamAdmitCard/:exam_id/:session_id/:exam_name/:exam_date/:exam_status'  component={ExamAdmitCard} />
          <Route path='/AdminMain/ExamDateSheet/:exam_id/:session_id/:exam_name/:exam_date'  component={ExamDateSheet} />
          <Route path='/AdminMain/ExamGuideline/:exam_id/:session_id/:exam_name/:exam_date'  component={ExamGuideline} />
          <Route path='/AdminMain/ExamHallTicket/:exam_id/:session_id/:exam_name/:exam_date/:exam_status'  component={ExamHallTicket} />
          <Route path='/AdminMain/ReportViewClass/:file_directory/:file_name/'  component={ReportViewClass} />
          <Route path='/AdminMain/StudentData/:file_directory/:file_name/'  component={StudentData} />
          <Route path='/AdminMain/ExamMarkingSystem/:exam_id/:session_id/:exam_name/:exam_date/:exam_status'  component={ExamMarkingSystem} />
           
                 
          
            </switch>        
           </Router>
           </div>
          )
        }
          
      }
}
