import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import UpdateSchoolDetails from './UpdateSchoolDetails';
import myToast from '../Utils/MyToast';
export default class SchoolProfile extends Component {
    constructor(props){
        super(props)
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
      
        this.state={
      
            loggedIn:true,
            Loading:false, 
            mSchoolProfile:[]
        }
      
      }
      componentDidMount()
      {
      this.SchoolProfile();
      }
      SchoolProfile(){
      
      this.setState({
        Loading:true
      })
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      const FUNCTION_NAME='getSchoolProfile.php'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      console.log("URL>>>>>"+URL)
      // var params = new URLSearchParams();
        //  params.append('school_code', SCHOOL_CODE);
      
      axios.get(URL,{
        params:{school_code:SCHOOL_CODE}
      }).then(res => res.data)
      .then((response) => {
       
        this.setState({ 
          Loading:false
         })
          console.log("Response2>>>>>"+response);
          let api_status=response.api_status;
         // alert(api_status);
          
          if(api_status=="OK")
          {
          
             this.setState({
              
                mSchoolProfile:response.data
              })
              console.log("response>>>>>"+JSON.stringify(this.state.mSchoolProfile));
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
      
        //console.log("response>>>>>"+api_status)
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        console.log("error>>>>>"+error);
        });
      }
        render() {
          if( this.state.loggedIn==false)
          {
           return <Redirect to='/Login'/>;
          }
          if(this.state.Loading)
          {
             return <MyLoader/>
          }else{
            return (
              <Router>
              <switch>   
              <Route path='/AdminMain/SchoolProfile'  exact render={
                ()=>{
        
                  return(

                 <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> School Profile </a></li>
              </ul>
            </div>
          </div> 
          
          {this.state.mSchoolProfile.map((item, index) => (  
              <div className="row class-li">
              <div className="col-sm-6 col-md-6 card-class-w class-list-box" style={{margin:'0px',border:'double #fff'}}>
                <div className="row">
               <div className="col-sm-6 col-md-6">
               <div className="myprofile-img-div"><img src={item.school_logo_path} alt={item.school_name} title="" /></div>
               
               </div>
               <div className="col-sm-6 col-md-6">
               <h4> <input type="file" style={{width:'100%'}} /></h4>
               <hr/>
               <h4> <button className="btn btn-success" style={{width:'100%'}}> Image Upload </button> </h4>
               </div>
                </div>
                </div>
                <div className="col-sm-6 col-md-6 text-center class-list-box" style={{margin:'0px'}}>
               <h3> School Code: <span> {item.school_code}</span> </h3>
               <hr/>
               <h5> Estb Year: <span className="color-a"> {item.school_estb_year} </span> </h5>
               <h5> Reg No: <span className="color-a"> {item.school_reg_no} </span> </h5>
               
               </div>
                <div className="col-sm-12 col-md-12 text-center class-list-box" style={{margin:'0px', background:'#fff'}}>
                <h2> {item.school_name} </h2>   
                <h5 className="text-success"> {item.school_tag_line} </h5> 
                <hr/>
                <h5> {item.school_address} </h5>
                <p><strong> Official Contact1: +91 </strong> {item.officail_contact_no1}</p>
                <p><strong> Official Contact2: +91 </strong> {item.officail_contact_no2}</p>
                <p><strong className="color-a"> Official Email:</strong> {item.officail_email} </p>
                <p><strong className="color-a"> Website: </strong> {item.website}</p>
               
                <Link to={{pathname:'/AdminMain/UpdateSchoolDetails'}}>
                <button className="btn btn-info" style={{width:'100%',fontSize:'24px', margin:'30px 0px 15px 0px'}}> Edit School Profile </button>
                </Link>
                </div>
                </div>
                ))}


               </div>
  
                )
           }
         } />                       
         
           <Route path='/AdminMain/UpdateSchoolDetails'  component={UpdateSchoolDetails} />   
              
           </switch>
           
          </Router>
         )
    } 
  }
 
}