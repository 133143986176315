import React, { Component ,useRef, createRef} from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import ClassFee from './ClassFee';
import myToast from '../Utils/MyToast';
import MdlSelectSingleStudent from '../dialog/MdlSelectSingleStudent';

export default class SpecialCaseCreation extends Component {
  constructor(props) {
    super(props)        
   
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={  
        isOpen: false, 
        loggedIn:true,
        Loading:false,
        mSpecialFee:[],
        SESSION_ID:'',
        STUDENT_ID:'S01911'
        
    }
    this.HandleSelectedStudent = this.HandleSelectedStudent.bind(this);
    this.HandleClose = this.HandleClose.bind(this);
   
     
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    
    var mSession=SessionManager.getCurrentSession();
    this.state.SESSION_ID=mSession[0].session_id;
    this.HandleShow();
  }   
  
  HandleShow = () => {

    this.setState({
      isOpen: !this.state.isOpen
    });   
  }
  
  HandleClose = () => {

    this.setState({
      isOpen:false
    });  
     
  }
  

  HandleSelectedStudent = (student_id,selected_item) => {
    this.state.ENROLLMENT_NO=student_id;
    this.setState({
      
      isOpen:false
    });
    this.getStudentSpecialFee();
    
  }  
  getStudentSpecialFee(){
  
  this.state.mSpecialCaseStudent=[];
    
    this.setState({
      Loading:true
    })
    
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    const FUNCTION_NAME='getSpecialCaseStudents.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    
axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE,
    session_id:this.state.SESSION_ID
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    //console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
    //alert(api_status);  
    if(api_status=="OK")
    {
      this.setState({            
        mSpecialCaseStudent:response.data,
      })
           
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}

  render() {
    if(this.state.loggedIn==false)
    {
    return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
         return <MyLoader/>
    }
    else{       
     
        return (
          
        <Router>
        <switch>
          <Route path={'/AdminMain/SpecialCaseCreation'}  exact render={
            ()=>{
          return(  
              <div className="content" style={{background:COLORS.white}}>
          <div className="content-header" >
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home text-white" aria-hidden="true" /><a href="#">Create Special Case </a></li>
              </ul>
            </div>
          </div>
          <div className="bg_page_grey_rounded box_shadow " style={{'--mMargin':'100px'}}>
            <div className="row gravity_center" style={{padding:'5px 10px 5px 10px'}}> 
            {this.state.mSpecialCaseStudent && this.state.mSpecialCaseStudent.map((item, index) => { 
                var mFeeDetails=[];
                mFeeDetails=item.fee_details;
                                           
                return(       
                <div className="col-sm-6 col-md-6 "style={{padding:0}}>
                
                 <div className="bg_card_blue_outline" style={{margin:'5px 8px 10px 8px'}}>
                  <div className="row margin_0"> 
                 <div className="col-sm-3  bg_card_blue" style={{padding:'0px'}}>
                   <div className="circular_image">
                      <img  src={item.student_details[0].student_img_path}  alt="img"></img>
                      <p className="student_id gravity_center"> <small> {item.student_details[0].student_enrollment_no} </small></p>
                   </div>                 
                 </div>
                 <div className="col-sm-6" style={{padding:'10px 10px'}}>
                  <h5> {item.student_details[0].stu_name} </h5>
                  <p className="mb-0"> Father: <span>{item.student_details[0].father_name}</span> </p>
                  <p className="mb-0"> Board: <span>{item.student_details[0].class[0].board_name}</span> </p>
                  <p className="mb-0"> Medium: <span>{item.student_details[0].class[0].medium_name}</span> </p>
                 
                 </div>
                 <div className="col-sm-3 padding_0 gravity_center" >
                  <div className="text-center bg_card_blue_outline width_fill_parent" style={{margin:'2px'}}>
                    <p className="mb-0"> Roll No. </p>
                    <p> <div className="bg_circle_fill_primery_grey_outline gravity_center" style={{width:'40px', height:'40px',border:'2px',
                     margin:'5px auto',fontSize:'24px',color:COLORS.white}}>{item.student_details[0].class[0].student_roll_no}</div> </p>
                    <h6>Class:<span> {item.student_details[0].class[0].class_name} </span>  </h6>
                  </div>
                 </div>
                 </div>
                 <div className="row margin_0" style={{background:COLORS.green_dark}}>
                          <div className="col-sm-4 col-md-4 gravity_center txt_white">
                              <p>Applicable Fee</p>
                          </div>
                          <div className="col-sm-4 col-md-4 gravity_center txt_white">
                             <p>Class Fee</p>
                          </div>
                          <div className="col-sm-4 col-md-4 gravity_center txt_white">
                          <p>Special Fee</p>
                          </div>
                  </div>
                
                 {mFeeDetails && mFeeDetails.map((item2, index2) => { 
                                           
                     return( 
                      <div className="row margin_0">
                          <div className="col-sm-4 col-md-4 gravity_center">
                              <p>{item2.fee_name}</p>
                          </div>
                          <div className="col-sm-4 col-md-4 gravity_center">
                            <p>{item2.fee_value}</p>
                          </div>
                          <div className="col-sm-4 col-md-4 gravity_center">
                          <p>{item2.special_value}</p>
                          </div>
                       </div>
             
                        )
                    })}    
                  
                </div>
               
                </div>                   
                )
            })} 
            </div>  
   
            {this.state.isOpen===true? <MdlSelectSingleStudent action={this.HandleClose} action2={this.HandleSelectedStudent}></MdlSelectSingleStudent>:''}
                  
             </div>
           </div>
             )
            }
          } />
      
          <Route path='/AdminMain/ClassFee'  component={ClassFee} />                    
          </switch>       
         </Router>  
          )
      }
    } 
  }
