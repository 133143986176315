import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import StaffProfile from './StaffProfile';
import StaffRegistration from './StaffRegistration';
import myToast from '../Utils/MyToast';
export default class StaffList extends Component {
  constructor(props){
    super(props)
  const token=localStorage.getItem("token");
  let loggedIn=true;
  if(token==null){
    loggedIn=false;
  }

  this.state={

      loggedIn:true,
      Loading:false, 
      mStaffList:[]  
  }

}
componentDidMount()
{
this.StaffList();
}
StaffList(){
this.setState({
  Loading:true 
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
const FUNCTION_NAME='getStaff.php'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
console.log("URL>>>>>"+URL)
// var params = new URLSearchParams();
//  params.append('school_code', SCHOOL_CODE);

axios.get(URL,{
  params:{school_code:SCHOOL_CODE}
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
   // alert(api_status);
    
    if(api_status=="OK")
    {
     
       this.setState({
        
        mStaffList:response.staff

        })
        console.log("response>>>>>"+this.state.mStaffList);
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    }

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}
  render() {
    if( this.state.loggedIn==false)
    {
     return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }else{
    return (
      <Router>
      <switch>   
      <Route path='/AdminMain/StaffList'  exact  render={
        ()=>{

          return(
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Staff List</a></li>
              </ul>
            </div>
          </div> 
              <div className="row class-li">
              {this.state.mStaffList.map((item, index) => ( 
                <div className="col-sm-6 col-md-6">
                 <Link to={{pathname:'/AdminMain/StaffList/StaffProfile', data: {Staff_id:item.staff_id==='No'?'No':item.staff_id}}}>
                 <div className="row class-list-box bb-r-c height-130" style={{border:'1px solid #74a980'}}>
                 <div className="col-sm-3 cl-3-part p-0" style={{background:'#74a980'}}>
                   <div className="profil-im-img" style={{background:'rgb(219 238 246)',width:'110px',height:'110px',margin:'0 auto',padding:'0px'}}>
                   <div><img src={item.staff_image_path} alt="" style={{with:'100%',borderRadius:'50%'}}/></div>
                 </div>
                 </div>
                 <div className="col-sm-9 col-md-9">
                  <h5> {item.staff_name} </h5>
                  <hr/>
                  <p><strong> Joining Date: </strong> <span>{item.joining_date}</span> &nbsp; <strong> Department Name: </strong> <span>{item.department_name}</span> </p>
                  <p> <strong> Staff Role: </strong> <span>{item.staff_role}</span> </p>
                 
                 </div>
                </div>
                </Link>
                </div>
              ))}

                </div>
                <div style={{ float: 'left', width: '100%' }}>
                                  <div className=" row">
                                    <div className="col-md-12">
                                      <center>
                                      <Link to={{pathname:'/AdminMain/StaffRegistration'}}>
                                        <button className="btn btn-primary" onClick={this.regSubmit} style={{margin:'2% 0px',fontSize:'24px',width:'100%'}}> Add Staff </button>
                                       </Link>
                                      </center>
                                    </div>
                                  </div>
                              </div>

               </div>
                  )
                }
               } />  
                <Route path='/AdminMain/StaffRegistration'  component={StaffRegistration} />    
                 <Route path='/AdminMain/StaffList/StaffProfile'  component={StaffProfile} />        
                </switch>
                 
                </Router>
  
  )
} 
}

}
