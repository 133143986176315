import React, { Component } from 'react'
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
export default class PaymentStatus extends Component {
    render() {
        return (
            <div className="content">
            <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Payment Status </a></li>
              </ul>
            </div>
          </div> 
            <div className="row class-li">
              <div className="col-sm-9 success-m">
               <div className="row sp-10 ">
               <div className="col-sm-12 col-md-12">
                <div className="myprofile-img-div im"><img src="https://images.vexels.com/media/users/3/157931/isolated/preview/604a0cadf94914c7ee6c6e552e9b4487-curved-check-mark-circle-icon-by-vexels.png" alt="check image" /></div>
                <h3 className="text-center mb-100 text-success"> Collected Successfully </h3>
                </div>

               <div className="col-sm-3">
                <div className="th-section">
                <Link to={'/AdminMain/FeeCard'}>Fee Card</Link>
                </div>
               </div>
               <div className="col-sm-3">
                <div className="th-section">
                <Link to={'/AdminMain/PaymentHistory/'+this.props.student_id+"/"+this.props.session_id}>Payment History</Link>
                </div>
               </div>
               <div className="col-sm-3">
                <div className="th-section">
                <Link to={'/AdminMain/FeeDashBoard'}>Collect Another</Link>
                </div>
               </div>
               <div className="col-sm-3">
                <div className="th-section">
                <Link to={'/AdminMain'}>Back To Home </Link>
                </div>
              </div>
            
            <div className="col-sm-12 col-md-12">
             <hr/>
             
             </div>
               </div>
              </div>
                </div>
         </div>
            

        )
    }
}
