import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class AddHoliday extends Component {
  constructor(props){
    super(props)

  
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }

    this.state={
        loggedIn:false,
        Loading:false,
        strtDate:'',
        endDate:'',
        holioccas:'',
        holiremark:'',
        holitype:''

    }
    this.regSubmit= this.regSubmit.bind(this);
    this.regChange= this.regChange.bind(this);
}
regChange(e){

  this.setState({
    [e.target.id]:e.target.value
})
}
regSubmit(){
  // var hh= this.state.strtDate;
  // alert(hh);
  if(this.isValidInput())
  {
  
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='registerHolidays.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL, {
    params: {
      school_code:SCHOOL_CODE,
      holiday_start_date: this.state.strtDate,
      holiday_end_date: this.state.endDate,
      holiday_occasion: this.state.holioccas,
      holiday_remark: this.state.holiremark,
      holiday_type: this.state.holitype

    }

  }).then(res => res.data)
  .then((response) => {
    this.setState({ 
       Loading:false
      })
      let api_status=response.api_status;
      //console.log("responsel>>>>>"+response);
 // Add notification for update 
 alert("successfully Updated !" );
 window.location.reload();
window.location = "/AdminMain/Holiday";

    console.log("response>>>>>"+api_status)
   })
}
}  

isValidInput()
{

  if (this.state.strtDate==="")
    {
    alert('Please Enter Start Date');
    return false;
    }
  else if(this.state.endDate==="")
  {
    alert("Please Enter End Date");
    return false;
  }
  else if(this.state.holioccas==="")
  {
    alert("Please Enter Holiday Occasion!");
    return false;
  }
  else if(this.state.holiremark==="")
  {
    alert("Please Enter Holiday Remark!");
    return false;
  }
  else if(this.state.holitype==="")
  {
    alert("Please Enter Holiday Type !");
    return false;
  }
  else{
    return true;
  }

}
        render() {

 if(this.state.Loading)
{
   return <MyLoader/>
}
else{
            return (
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Add Holiday </a></li>
              </ul>
            </div>
          </div> 
          <div className="form-group row">
          <label className="col-md-2 col-form-label"> Start Date </label>
                                    <div className="col-md-4">
                                      <div className="input-group date">
                                        <input type="date" id="strtDate" name="strtDate" value={this.state.strtDate} onChange={this.regChange} className="form-control" />
                                        <div className="input-group-addon">
                                          <i className="fa fa-calendar" />
                                        </div>
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label"> End Date </label>
                                    <div className="col-md-4">
                                      <div className="input-group date">
                                        <input type="date" id="endDate" name="endDate" value={this.state.endDate} onChange={this.regChange} className="form-control" />
                                        <div className="input-group-addon">
                                          <i className="fa fa-calendar" />
                                        </div>
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> Holiday Occasion </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input type="text" id="holioccas" name="holioccas" value={this.state.holioccas} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label"> Holiday Remark </label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="holiremark" name="holiremark" value={this.state.holiremark} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label"> Holiday type </label>
                                    <div className="col-md-10">
                                      <div className="input-group">
                                        <input type="text" id="holitype" name="holitype" value={this.state.holitype} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    </div>
              <div className="row">
              <div className="col-sm-12 col-md-12">
              <button className="btn btn-info" style={{width:'100%',fontSize:'24px', margin:'30px 0px 15px 0px'}} onClick={this.regSubmit}> Submit Holiday </button>
             </div> 
             </div>
            </div>
  
  )
} 
}
}
