import React, { Component } from 'react'
import '../css/Admin_css/admin-customize.css';
import { Redirect, Route } from 'react-router-dom';
import axios from 'axios'

import SessionManager from '../Utils/SessionManager'
import MyLoader from '../Spinner/MyLoader';
import myToast from '../Utils/MyToast';
export default class StaffRegistration extends Component {
  constructor(props){
    super(props)

  
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }

    this.state={
        loggedIn:false,
        Loading:false,
        staffName:'',
        Department:'',
        dob:'',
        doj:'',
        inlineCssRadio1:'',
        inlineCssRadio2:'',
        phone:'',
        stRegion:'',
        AdharNo:'',
        nationality:'',
        Village:'',
        Post:'',
        District:'',
        State:'',
        Pincode:'',
        email:'',
        loginType:'',
        staffRole:'',
        Degree:'',
        University:'',
        year:'',
        Percentage:''

    }
    this.regSubmit= this.regSubmit.bind(this);
    this.regChange= this.regChange.bind(this);
}
regChange(e){

  this.setState({
    [e.target.id]:e.target.value
})
}

regSubmit(){
  if(this.isValidInput())
  {

  // var bb=this.state.Department + this.state.staffRole + this.state.email + this.state.loginType + this.state.phone + this.state.AdharNo + this.state.nationality;
  // alert(bb);
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='registerStaff.php?'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  axios.get(URL, {
    params: {
      school_code:SCHOOL_CODE,
        StaffName:this.state.staffName,
        department_id:this.state.Department,
        dob:this.state.dob,
        doj:this.state.doj,
        religion:this.state.stRegion,
        gender:this.state.inlineCssRadio1 + this.state.inlineCssRadio2,
        aadhar_no:this.state.AdharNo,
        nationality:this.state.nationality,
        role:this.state.staffRole,
        login_type:this.state.loginType,
        address:this.state.Village + this.state.Post + this.state.District + this.state.State + this.state.Pincode,
        phone:this.state.phone,
        email:this.state.email
    }

  }).then(res => res.data)
  .then((response) => {
    this.setState({ 
       Loading:false
      })
      let api_status=response.api_status;
      //console.log("responsel>>>>>"+response);
      alert("successfully Updated !" );
      if (typeof window !== 'undefined') {
           window.location.reload(false);
           window.location.href = "../AdminMain/StaffList";
      }

    console.log("response>>>>>"+api_status)
   })
} 
}
isValidInput()
{

  if (this.state.staffName==="")
    {
    alert('Your Staff name have accepted Only Alphabate!');
    return false;
    }
  else if(this.state.Department==="")
  {
    alert("Enter Department have accepted Only Alphabate!");
    return false;
  }
  else if(this.state.dob==="")
  {
    alert("DOB have accepted Only Numeric!");
    return false;
  }
  else if(this.state.doj==="")
  {
    alert("DOJ have accepted Only Numeric!");
    return false;
  }
  else
    var option=document.getElementsByName('radio_css_inline');

if (!(option[0].checked || option[1].checked)) {
    alert("Please Select Your Gender");
    return false;
}
  else if(this.state.AdharNo==="")
  {
    alert("Enter Adhar No. have accepted Only Numerice!");
    return false;
  }
  else if(this.state.nationality==="")
  {
    alert("Enter your nationality have accepted Only Alphabate!");
    return false;
  }
  else if(this.state.stRegion==="")
  {
    alert("Enter your religion have accepted Only Alphabate!");
    return false;
  }
  else if(this.state.Village==="")
  {
    alert("Enter your current village have accepted Only Alphabate!");
    return false;
  }
  else if(this.state.Post==="")
  {
    alert("Enter your current Post have accepted Only Alphabate!");
    return false;
  }
  else if(this.state.District==="")
  {
    alert("Enter your current District have accepted Only Alphabate!");
    return false;
  }
  else if(this.state.State==="")
  {
    alert("Enter your current state have accepted Only Alphabate!");
    return false;
  }
  else if(this.state.Pincode==="")
  {
    alert("Enter your current Pin code have accepted Only Numeric!");
    return false;
  }
  else if(this.state.phone==="")
  {
    alert("Enter your Phone no, have accepted Only Numeric!");
    return false;
  }
  else if(this.state.staffRole==="")
  {
    alert("Enter your Staff Role !");
    return false;
  }
  else if(this.state.loginType==="")
  {
    alert("Enter your Login Type !");
    return false;
  }
  else{
    return true;
  }

}

        render() {

 if(this.state.Loading)
{
   return <MyLoader/>
}
else{
            return (
            <div className="content">
                <div className="content-header">
                  <div className="leftside-content-header">
                    <ul className="breadcrumbs">
                      <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Staff Registration</a></li>
                    </ul>
                  </div>
                </div>
                <div className="row animated fadeInUp">
                  <div className="col-sm-12 col-lg-12">
                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <div className="panel">
                          <div className="panel-content text-center" style={{ minHeight: '400px' }}>
        
                            <div className="panel-body">
                         
                              <div className="pnlSubHedingBorder">
                                <div className="pnlSubHeding heading-with-bg-w">
                                Personal Details
                                </div>
                              </div>
                              <div className="row">
                              <div className="col-sm-3" style={{padding:'10px',borderRight:'1px solid #d7d0d0'}}>
                   <div>
                    <p className="text-center posi-rel"> <img src="https://clevelandpolicefoundation.org/wp-content/uploads/2012/09/female-placeholder.png" className="img-phto" alt="student list image"/>
                    </p>
                    <div className="photo-row-bottom posi-rel">
                      <span><input type="file" name="upload photo"/></span>
                      <span className="up-img">Upload Photo</span>
                    </div>
                    </div>
                 </div>
                              <div className="col-md-9">
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">
                                    <label className="col-md-2  col-form-label"> Staff Name</label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input type="text" id="staffName" name="staff name" value={this.state.staffName} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label"> Department </label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="Department" name="Department" value={this.state.Department} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label"> Staff Role </label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="staffRole" name="Department" value={this.state.staffRole} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label"> Login Type </label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="loginType" name="Department" value={this.state.loginType} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> Phone Number </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="phone" type="text" value={this.state.phone} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Email Address </label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input id="email" type="text" value={this.state.email} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label"> Date of Joining </label>
                                    <div className="col-md-4">
                                      <div className="input-group date" id="stAdmissionDate">
                                        <input type="date" id="doj" name="doj" value={this.state.doj} onChange={this.regChange} className="form-control" />
                                        <div className="input-group-addon">
                                          <i className="fa fa-calendar" />
                                        </div>
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Date of Birth</label>
                                    <div className="col-md-4">
                                      <div className="input-group date" id="stDateofBirth">
                                        <input type="date" id="dob" name="dob" value={this.state.dob} onChange={this.regChange} className="form-control" />
                                        <div className="input-group-addon">
                                          <i className="fa fa-calendar" />
                                        </div>
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Gender</label>
                                    <div className="col-md-4">
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline" id="inlineCssRadio1" value="Male" checked={this.state.inlineCssRadio1 === "Male"} onChange={this.regChange} />
                                        <label htmlFor="inlineCssRadio1">Male</label>
                                      </div>
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline" id="inlineCssRadio2" value="Female" checked={this.state.inlineCssRadio2 === "Female"} onChange={this.regChange} />
                                        <label htmlFor="inlineCssRadio2">Female</label>
                                      </div>
                                    </div>
                                    {/* <label className="col-md-2 col-form-label">Community</label>
                                    <div className="col-md-4">
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline1" id="SCST" value="SCST" checked={this.state.SCST === "SCST"} onChange={this.regChange} />
                                        <label htmlFor="inlineCssRadio3">SC/ST</label>
                                      </div>
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline1" id="OBC" value="OBC" checked={this.state.OBC === "OBC"} onChange={this.regChange} />
                                        <label htmlFor="inlineCssRadio4">OBC</label>
                                      </div>
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline1" id="General" value="General" checked={this.state.General === "General"} onChange={this.regChange} />
                                        <label htmlFor="inlineCssRadio5">General</label>
                                      </div>
                                      <div className="radio radio-css radio-inline">
                                        <input type="radio" name="radio_css_inline1" id="Others" value="Others" checked={this.state.Others === "Others"} onChange={this.regChange} />
                                        <label htmlFor="inlineCssRadio6">Others</label>
                                      </div>
                                    </div> */}
                                    <label className="col-md-2 col-form-label">Religion</label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" className="form-control" value={this.state.stRegion} onChange={this.regChange} id="stRegion"  />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Adhar Number</label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input id="AdharNo" type="text" value={this.state.AdharNo} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2 col-form-label">Nationality</label>
                                    <div className="col-md-4">
                                      <div className="input-group">
                                        <input type="text" id="nationality" name="nationality" value={this.state.nationality} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                              </div>
                              
                              {/* <div style={{ float: 'left', width: '100%' }}>
                              <div className="pnlSubHedingBorder headMarginTop">
                                <div className="pnlSubHeding heading-with-bg-w">
                                  Class Information
                                 </div>
                              </div>
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">
                                    <label className="col-md-2  col-form-label">  Board </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="board" type="text" value={this.state.board} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label">   Medium </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="medium" type="text" value={this.state.medium} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label">   Class </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="class" type="text" value={this.state.class} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              
                              <div style={{ float: 'left', width: '100%' }}>
                              <div className="pnlSubHedingBorder headMarginTop">
                                <div className="pnlSubHeding heading-with-bg-w">
                                 Address
                              </div>
                              </div>
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">
                                    <label className="col-md-2  col-form-label">  Village/Town </label>
                                    <div className="col-md-10 ">
                                      <div className="input-group">
                                        <input id="Village" value={this.state.Village} onChange={this.regChange} type="text" className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label">  Post </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="Post" type="text" value={this.state.Post} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> District </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="District" type="text" value={this.state.District} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> State </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="State" type="text" value={this.state.State} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> Pin Code </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="Pincode" type="text" value={this.state.Pincode} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="pnlSubHedingBorder headMarginTop">
                                <div className="pnlSubHeding heading-with-bg-w">
                                  Permanent Address
                                   </div>
                                   </div>
                                <div style={{ marginTop: '-8px', float: 'left', marginLeft: '80px', paddingTop: '4px' }}> <b>Same As Current Address </b></div>
                                <div style={{ marginTop: '-8px', float: 'left', marginLeft: '8px' }}>
                                  <div className="switcher">
                                    <input type="checkbox" name="switcher_checkbox_1" id="switcher_checkbox_1" onChange={this.regChange} defaultChecked={this.state.checked} onClick={this.copytopermAddr} />
                                    <label htmlFor="switcher_checkbox_1" />
                                  </div>
                                </div>
                              
                              <div style={{ float: 'left', width: '100%' }}>
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">
                                    <label className="col-md-2  col-form-label">  Village/Town </label>
                                    <div className="col-md-10 ">
                                      <div className="input-group">
                                        <input id="perVillage" type="text" value={this.state.perVillage} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label">  Post </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="perPost" type="text" value={this.state.perPost} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> District </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="perDistrict" type="text" value={this.state.perDistrict} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> State </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="perState" type="text" value={this.state.perState} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label"> Pin Code </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="perPincode" type="text" value={this.state.perPincode} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              
                              <div style={{ float: 'left', width: '100%' }}>
                              <div className="pnlSubHedingBorder headMarginTop">
                                <div className="pnlSubHeding heading-with-bg-w">
                                Education Details
                                <button style={{width:'20%',borderRadius:'10px', fontSize:'14px',padding:'4px',background:'#28a745', float:'right'}} data-toggle="modal" data-target="#exampleModal"> Add Next Qualification <img src="https://image.flaticon.com/icons/svg/61/61183.svg" alt="add" style={{width:'10%'}}/> </button>   
                                
                                </div>
                              </div>
                                <div className="form-horizontal form-bordered sect-bg-clean">
                                  <div className="form-group row">
                                    <label className="col-md-2  col-form-label"> Degree/Diploma: </label>
                                    <div className="col-md-4 ">
                                    <h6 className="text-success border-bott"> B.Tech </h6>
                                    </div>
                                    <label className="col-md-2  col-form-label">University/Institute: </label>
                                    <div className="col-md-4 ">
                                    <h6 className="text-success border-bott"> Lucknow University </h6>
                                    </div>
                                    <label className="col-md-2  col-form-label">Year: </label>
                                    <div className="col-md-4 ">
                                    <h6 className="text-success border-bott"> 2017 </h6>
                                    </div>
                                    <label className="col-md-2  col-form-label">Percentage: </label>
                                    <div className="col-md-4 ">
                                    <h6 className="text-success border-bott"> 82 </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* add modal degree and university section */}

  <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content" style={{width: '810px', height:'350px', padding:'40px'}}>
      <div className="modal-header" style={{background:'#516d8b',color:'#fff'}}>
        <h5 className="modal-title" id="exampleModalLabel">Add Qualification section</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" style={{color:'#fff'}}>&times;</span>
        </button>
      </div>
      <div className="modal-body">
      <div className="row">
      <label className="col-md-2  col-form-label"> Degree/Diploma </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="Degree" type="text" value={this.state.Degree} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label">University/Institute </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="University" type="text" value={this.state.University} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label">Year </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="year" type="text" value={this.state.year} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    <label className="col-md-2  col-form-label">Percentage </label>
                                    <div className="col-md-4 ">
                                      <div className="input-group">
                                        <input id="percentage" type="text" value={this.state.percentage} onChange={this.regChange} className="form-control" />
                                      </div>
                                    </div>
                                    </div>
      </div>
      <div class="modal-footer s-pad-30">
        <button type="button" onClick={this.saveChanges} class="btn btn-primary s-change"> Add Qualification </button>
      </div>
    </div>
  </div>
</div>
                              
                              <div style={{ float: 'left', width: '100%' }}>
                                  <div className=" row">
                                    <div className="col-md-12">
                                      <center>
                                        <button className="btn btn-success" onClick={this.regSubmit} style={{margin:'2% 0px',fontSize:'24px',width:'100%'}}>Submit</button>
                                       
                                      </center>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    );
    }
  }
}
