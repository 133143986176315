import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import AdmissionQueryDetails from './AdmissionQueryDetails';

export default class AdmissionQuery extends Component {
    constructor(props) {
        super(props)   
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }     
        this.state={  
        
            loggedIn:true,
            Loading:false,
            mAdmissionQuery:[]
            
        }
      }
    
      saveAdmissionQuery=(item)=>{
      
       localStorage.setItem("AdmissionQuery",JSON.stringify(item));
       window.location.reload();
       window.location="/AdminMain/AdmissionQueryDetails";
     }
      componentDidMount() {
        window.scrollTo(0, 0)
        this.getAdmissionQuery()
      }
      getAdmissionQuery(){
    
        this.setState({
          Loading:true
        })
        
        const SCHOOL_CODE=SessionManager.getSchoolCode();
        var mSession=SessionManager.getCurrentSession();
        var SESSION_ID=mSession[0].session_id;
        const FUNCTION_NAME='getAllAdmissionQuery.php'
        URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
        //console.log("URL>>>>>"+URL)
        axios.get(URL,{
          params:{
                  school_code:SCHOOL_CODE,
                  session_id:SESSION_ID,
                }
        }).then(res => res.data)
        .then((response) => {
         
          this.setState({ 
            Loading:false
           })
           // console.log("Response2>>>>>"+response);
            let api_status=response.api_status;
            //alert(api_status);
            
            if(api_status=="OK")
            {
            
               this.setState({               
                mAdmissionQuery:response.data,
              
              })
               
            }
            else{
              let api_remark=response.api_remark;
              myToast.notify_warning(api_remark);
            }
        
          //console.log("response>>>>>"+api_status)
         }).catch((error)=>{
          this.setState({ 
            Loading:false
           })
          console.log("error>>>>>"+error);
          });
        }
       
    render() {
      
        if(this.state.loggedIn==false)
        {
        return <Redirect to='/Login'/>;
        }
        else if(this.state.Loading)
        {
                return <MyLoader/>
        }
       else{       
         
            return (
              
            <Router>
            <switch>
              <Route path={'/AdminMain/AdmissionQuery'}  exact render={
                ()=>{
              return(
    
                <div className="content" style={{background:COLORS.white}}>
                        <div className="content-header">
                          <div className="leftside-content-header">
                            <ul className="breadcrumbs">
                              <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Admission query </a></li>
                            </ul>
                          </div>
                        </div>
                        <div className="bg_page_grey_rounded box_shadow" style={{'--mMargin':'100px'}}>
                      
                              <div className="row" style={{padding:'0px 20px 0px 20px'}}>
                                <h4>{"Admission Query("+this.state.mAdmissionQuery.length+")"}</h4>
                              </div>
                              
                              <div className="row">
                              {this.state.mAdmissionQuery && this.state.mAdmissionQuery.map((item,index)=>{
                                          return(                                            
                                            <div className="col-sm-6 col-md-6 " >
                                            
                                            <div className="box_shadow bg_card_grey_outline " style={{padding:2,margin:10}}>
                                               
                                               <h4 className="gravity_center">{item.student_name+"("+item.father_name+")"}</h4>
                                              
                                               <h5 className="gravity_center"> {"Address:"+item.address} </h5>
                                               <h5 className="gravity_center"> {"Seeking Admission in Class:"+item.query_class} </h5>
                                               <h5 className="gravity_center"> {"Previous Class:"+item.previous_class} </h5>
                                  
                                               <h5 className="gravity_center"> {"Previous School:"+item.previous_school_name} </h5>
                                             
                                               <div className="row">
                                                 
                                                 <div className="col-sm-12 col-md-12 gravity_center" onClick={()=>this.saveAdmissionQuery(item)}>
                                                
                                                    <button className="btn btn-success" style={{fontSize:'16px', margin:'3px 2px 3px 2px',width:'100%',background:COLORS.primary_color_shade5}}>View Details </button>
                                                         
                                                 </div>                                                 
                                                </div>
                                               
                                            </div>
                                            
              
                                        </div>
                                         
                                            )
                                        })}
                                  
                              </div>
                            
                         
                         
                            </div>
                            </div>
               )
              }
            } />
             <Route path='/AdminMain/AdmissionQueryDetails'  component={AdmissionQueryDetails} />   
                         
            </switch>       
           </Router>  
            )
        }
        }
}
