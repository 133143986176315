import React, { Component } from 'react';
import {BrowserRouter as Router, Link,Redirect,Route } from 'react-router-dom';
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import '../css/Admin_css/admin-customize.css';
import PaymentDetails from './PaymentDetails';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
export default class PaymentHistory extends Component {
    constructor(props){
        super(props)
    
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
    
        this.state={
    
            loggedIn:true,
            Loading:false, 
            PaymentHistory:[],
            StudentDetails:[],    
        }
      
    }
componentDidMount()
{
  this.getPaymentHistory();
}
getPaymentHistory(){
  
  this.setState({
    Loading:true
  })
 
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const Student_id=this.props.match.params.student_id;
  const Session_id=this.props.match.params.session_id;
  const FUNCTION_NAME='getPaymentHistory.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
  console.log("URL>>>>>"+URL);
axios.get(URL,{
  params:{
    school_code:SCHOOL_CODE,
    session_id:Session_id,
    student_id:Student_id
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
      //console.log("Response2>>>>>"+response);
      //alert("res"+JSON.stringify(response));
      let api_status=response.api_status;
      if(api_status=="OK")
      {
        
         this.setState({
          
            PaymentHistory:response.data[0].payment_history,
            StudentDetails:response.data[0].student_details
          })
         // console.log("response>>>>>"+this.state.PaymentHistory);
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }

    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
}
  render() {
    if( this.state.loggedIn==false)
    {
     return <Redirect to='/Login'/>;
    }
    if(this.state.Loading)
    {
       return <MyLoader/>
    }
    else{

    return ( 

      
      <Router>
        <switch>
          <Route path='/AdminMain/PaymentHistory/:student_id/:session_id'  exact render={
            ()=>{
          return(
              <div className="content">
      <div className="content-header">
     <div className="leftside-content-header">
       <ul className="breadcrumbs">
         <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Payment History</a></li>
       </ul>
     </div>
     
   </div>            
     <div className="animated fadeInUp bg_page_grey_rounded" style={{'--mMargin':'50px'}}>
        <div className="row">
            {this.state.StudentDetails.map((item, index) => (
            <div className="col-sm-12 col-md-12">
            <div className="row class-list-box">
            <div className="col-sm-3 card-class-w stu-info-main-bg">
              <div className="stu-info-pic"><img src={item.student_img_path} style={{width: '100%'}} alt="student list image"/></div>
            </div>
            <div className="col-sm-9 stu-info-detail text-justify">
              <div className="p-b-mar payment-hist-student-details">
              <p> <strong> Student ID : </strong> { item.student_enrollment_no} </p>     
              <p> <strong> Student Name : </strong>{ item.stu_name} </p>
              <p> <strong> Father Name : </strong> <span> {item.father_name}</span> </p>
              <p> <strong> Class : </strong> <span>{item.class[0].class_name}</span> </p>
              <p> <strong> Roll No. : </strong> <span>{item.class[0].student_roll_no}</span> </p>
              </div>
            </div>

            </div>
          </div>
         ))}
     </div>
      <div className="row" style={{padding:'0px 20px'}}>

              {this.state.PaymentHistory && this.state.PaymentHistory.map((item,index) => (
              <div className="col-sm-6">
                <Link to={"/AdminMain/PaymentDetails/"+item.transaction_id+"/"+item.session_id+"/"+this.state.StudentDetails[0].student_enrollment_no}>
                   
                <div className="row gravity_center" style={{background:COLORS.white,margin:'10px',padding:'10px'}}>
                  
                    <div className="col-sm-12 text-center" style={{background:COLORS.primary_color_shade7,padding:'10px'}} >
                    <div className="payment-trans-history"> &#10003; </div>   
                    <p className="h5-font-mt"> <strong> &#8377; {item.paid_amount} </strong> </p>
                    <p className="h5-font-mt"> Transaction Id :<span> {item.transaction_id} </span>  </p>
                    <p className="h5-font-mt"> Transaction Date :<span> {item.date_added} </span>  </p>
                  </div>
                  
              </div>
              
              </Link>
              </div>
              ))}
    </div>
</div>
  </div>  
             )
        }
       } />

        <Route path='/AdminMain/PaymentDetails/:transaction_id/:session_id/:student_id'  component={PaymentDetails} />       
        
    </switch>   
   </Router>

      )
      
    }    
  }
 
}

