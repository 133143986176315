import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import AddHoliday from './AddHoliday';
import myToast from '../Utils/MyToast';
export default class Holiday extends Component {
    constructor(props){
        super(props)
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
      
        this.state={
      
            loggedIn:true,
            Loading:false, 
            mHoliday:[]
        }
      
      }
      componentDidMount()
      {
      this.Holiday();
      }
      Holiday(){
      
      this.setState({
        Loading:true
      })
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      const Session_Id="SSN0001";
      const FUNCTION_NAME='getHolidays.php'
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
      console.log("URL>>>>>"+URL)
      // var params = new URLSearchParams();
        //  params.append('school_code', SCHOOL_CODE);
      
      axios.get(URL,{
        params:{school_code:SCHOOL_CODE,session_id:Session_Id}
      }).then(res => res.data)
      .then((response) => {
       
        this.setState({ 
          Loading:false
         })
          console.log("Response2>>>>>"+response);
          let api_status=response.api_status;
         // alert(api_status);
          
          if(api_status=="OK")
          {
          
             this.setState({
              
                mHoliday:response.holidays
              })
              console.log("response>>>>>"+JSON.stringify(this.state.mHoliday));
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
      
        //console.log("response>>>>>"+api_status)
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        console.log("error>>>>>"+error);
        });
      }
        render() {
          if( this.state.loggedIn==false)
          {
           return <Redirect to='/Login'/>;
          }
          if(this.state.Loading)
          {
             return <MyLoader/>
          }else{
          return (
            <Router>
            <switch>   
            <Route path='/AdminMain/Holiday'  exact render={
              ()=>{
                return(
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Holiday </a></li>
              </ul>
            </div>
          </div> 
              <div className="row class-li">
              {this.state.mHoliday.map((item, index) => (  
              <div className="col-sm-12 col-md-12 card-class-w class-list-box" style={{margin:'0px',border:'double #fff'}}>
              <h3 className="text-center">  Announced Date: {item.date_added} </h3>
             <hr/>
            <table border="1" width="100%" className="holiday-tb">
            
            <tr>
                <th>Occasion</th>
                <td>{item.holiday_Occasion}</td>
            </tr>
            <tr>
                <th>Remark</th>
                <td>{item.holiday_remark}</td>
            </tr>
            <tr>
                <th>From: &nbsp; <span>{item.start_holiday_date}</span></th>
                <th>To: &nbsp; <span>{item.end_holiday_date}</span></th>
            </tr>
            </table>
                </div>

                ))}

                <div className="col-sm-12 col-md-12 text-center">
                <Link to={{pathname:'/AdminMain/AddHoliday'}}>
                <button className="btn btn-info" style={{width:'100%',fontSize:'24px', margin:'30px 0px 15px 0px'}}> Add Holiday </button>
               </Link>
                </div>
                </div>
                
               </div>
           
)
          }
        } />  

          <Route path='/AdminMain/AddHoliday'  component={AddHoliday} /> 

          </switch>
          
         </Router>
  
  )
} 
}
}
