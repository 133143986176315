import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class StudentInformation extends Component {
  constructor(props) {
    super(props)        

    this.state={  
        isOpen: false, 
       
    }  
  }
  componentDidMount(){
    //alert("hello"+this.props.showStudentInfo);
  }
  render() {
    if(!this.props.showStudentInfo)
    {
      return null;
    }
    else{
    return (         
        <div className="animated fadeInUp" style={{margin:' 30px 0px'}}>
            <div className="row">

                <div className="col-sm-12 col-md-12">

                              <div className="row class-list-box">
                                  <div className="col-sm-3 card-class-w stu-info-main-bg">
                                    <div className="stu-info-pic"><img src="https://clevelandpolicefoundation.org/wp-content/uploads/2012/09/female-placeholder.png" style={{width: '100%',height:'112px'}} alt="student image"/></div>
                                  </div>
                                  <div className="col-sm-6 stu-info-detail">
                                    <div className="p-b-mar">
                                    <h3>{this.props.mStudentDetails[0].stu_name}</h3>
                                    <p> <strong> {this.props.mStudentDetails[0].gender==='Male'?'S/O':'D/O'} : </strong> <span>{this.props.mStudentDetails[0].father_name}</span> </p>
                                    <p> <strong> Board : </strong> <span>{this.props.mStudentDetails[0].class[0].board_name}</span> </p>
                                    <p> <strong> Medium : </strong> <span>{this.props.mStudentDetails[0].class[0].medium_name}</span> </p>
                                    </div>
                                  </div>
                                  <div className="col-sm-3 stu-info-main-bg-1 ">
                                  <div className="text-center stu-info-roll-circle">
                                    <p> Roll No. </p>
                                    <div className="st-list-roll-no">{this.props.mStudentDetails[0].class[0].student_roll_no}</div>
                                    <p className="h5-font-mt"><span> {this.props.mStudentDetails[0].class[0].section_name==='null'? this.props.mStudentDetails[0].class[0].class_name:this.props.mStudentDetails[0].class[0].class_name+':'+this.props.mStudentDetails[0].class[0].section_name}</span>  </p>
                                  </div>
                                  </div>
                                <div className="col-sm-12 pt-4">
                                <div className="row">
                                <div className="col-sm-2">
                <div className="btn btn-primary" style={{width:'100%',fontSize:'16px'}}>
                <Link to={{pathname:'/AdminMain/FeeCard'}} className="text-white">Fee Card</Link>
                </div>
               </div>
               <div className="col-sm-3">
               <div className="btn btn-primary" style={{width:'100%',fontSize:'16px'}}>
                <Link to={'/AdminMain/PaymentHistory/'+this.props.mStudentDetails[0].student_enrollment_no+"/"+this.props.mStudentDetails[0].class[0].session_id} className="text-white">Payment History</Link>
                </div>
               </div>
               <div className="col-sm-3">
               <div className="btn btn-primary" style={{width:'100%',fontSize:'16px'}}>
                <Link to={{pathname:'/AdminMain/AdvancedPayment'}} className="text-white">Advanced Payment</Link>
                </div>
               </div>
              
               <div className="col-sm-2">
               <div className="btn btn-primary" style={{width:'100%',fontSize:'16px'}}>
                <Link to={{pathname:'/AdminMain/FeeDashBoard'}} className="text-white">Collect Another</Link>
                </div>
               </div>
               <div className="col-sm-2">
               <div className="btn btn-primary" style={{width:'100%',fontSize:'16px'}}>
                <Link to={{pathname:'/AdminMain'}} className="text-white">Back To Home </Link>
                </div>
              </div>
                                </div>  
                                </div>  
              </div>
             </div>
           
            </div>
        </div>
      )
    }
      
  }
 
}

