import React, { Component,createRef } from 'react'

import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {Link, Redirect } from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';
import { Button } from 'react-bootstrap';


export default class MdlSelectSession extends Component {
    constructor(props){
        super(props)       
        this.slt_board=createRef();
        this.slt_medium=createRef();
        this.slt_class=createRef();
        this.slt_section=createRef();
        
       
        const token=localStorage.getItem("token");
        let loggedIn=true;
        if(token==null){
          loggedIn=false;
        }
      
        this.state={   
            loggedIn:true,
            Loading:false, 
            show:true,
            mSessionList:[],
            SESSION_SELECTED_POSITION:0
          
        }
      
}

HandleClose = () => {

  this.setState({
    show: !this.state.show
  });
this.props.action();
  
}
SelectSession = (index) => {
  this.setState({
    SESSION_SELECTED_POSITION:index,
  })  
}
HandleSelectedSession = () => {
  //alert("okk"+student_id);
  var session_id=this.state.mSessionList[this.state.SESSION_SELECTED_POSITION].session_id;
  var session_name=this.state.mSessionList[this.state.SESSION_SELECTED_POSITION].session_name;
  this.props.action2(session_id,session_name);
  
}

componentDidMount(){
  this.getSessionList();
}


getSessionList(){

this.setState({
  Loading:true
})
const SCHOOL_CODE=SessionManager.getSchoolCode();
const FUNCTION_NAME='getSessionList.php'
URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
console.log("URL>>>>>"+URL)
axios.get(URL,{
  params:{school_code:SCHOOL_CODE,
  }
}).then(res => res.data)
.then((response) => {
 
  this.setState({ 
    Loading:false
   })
    console.log("Response2>>>>>"+response);
    let api_status=response.api_status;
    //alert(api_status);
    
    if(api_status=="OK")
    {
    
       this.setState({       
        mSessionList:response.data,
       
        })
      
        //alert(JSON.stringify(this.state.mSessionList));
    }
    else{
      let api_remark=response.api_remark;
      myToast.notify_warning(api_remark);
    } 

  //console.log("response>>>>>"+api_status)
 }).catch((error)=>{
  this.setState({ 
    Loading:false
   })
  console.log("error>>>>>"+error);
  });
}  

    render() {
      
        if( this.state.loggedIn==false)
        {
        return <Redirect to='/Login'/>;
        }
       
        else{
            return (

              <div>
                
      <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{opacity:1} }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select Session
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {this.state.Loading ? <MyLoader/> : 
         <div className="row">
             <form style={{width:'100%'}}>
             <div className="row" style={{padding:'0px 15px 0px 15px'}}>
                    {this.state.mSessionList.map((item, index) => (
                    <div className="col-sm-2 col-md-2" style={{padding:'0px 2px 5px 2px'}}>
                        <div className={this.state.SESSION_SELECTED_POSITION===index?"bg_class_select gravity_center":"bg_white_outline_grey gravity_center"} onClick={e=>this.SelectSession(index)} style={{cursor:'pointer'}}> 
                           <p style={{fontSize:18}}> {item.session_name} </p>
                        </div>
                    </div>
                    ))}
          </div>
          </form>

       
          </div>
      }
                       
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
                <Button variant="primary" size="lg" block onClick={this.HandleSelectedSession}>
                        Done
                </Button>
        </div>
                
               </Modal.Footer>
    </Modal>
            </div>
      
            )
        }
    }
}

