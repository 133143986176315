import React, { Component } from 'react'
import {Modal} from 'react-bootstrap'
import '../css/Admin_css/admin-customize.css';
import { Button } from 'react-bootstrap';
import COLORS from '../resources/colors/COLORS';
import myToast from '../Utils/MyToast';


export default class ModalFeeConfirmation extends Component {
    constructor(props){
        super(props)       
        this.state={   
            show:true,
            netPayableAmount:0
            
        }
      
}
componentDidMount(){
  this.state.netPayableAmount=this.props.netPayableAmount;
  
}

HandleClose = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.action();
    
  }
  
submitFeeDetails = () => {

    this.setState({
      show: !this.state.show
    });
    this.props.action2();
    
  }
    
render() {
        return (
            <div>
              <Modal show={this.state.show} onHide={this.HandleClose} animation={false} style={{opacity:1} }
               
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                       Fee Collection Confirmation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
            
                <div className="col-sm-12 gravity_center">
                  <div className="bg_circle_fill_primery_grey_outline gravity_center" style={{height:120,width:120,color:COLORS.white,fontSize:20}}>
                        <p>Rs:{this.props.netPayableAmount}</p>
                  </div>
                </div>
                        
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" size="lg" block onClick={this.submitFeeDetails}>
                        Submit
                   </Button>
                    
                     <Button onClick={this.HandleClose}>Close</Button>
               </Modal.Footer>
                </Modal>

            </div>
        )
    }
}
